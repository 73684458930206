import React, {useEffect, useState} from "react";
import parse from 'html-react-parser'
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {CheckButton, Button, Icons, Progress} from "components";
import API from "api";
import styles from './styles.module.scss'

const AssigneeAgreement = ({district, showAssigneeAgreement, setShowAssigneeAgreement, onAgree}) => {
    const [agreement, setAgreement] = useState('');
    const [caution, setCaution] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [privacyAgreement, setPrivacyAgreement] = useState(false);
    const [shareAgreement, setShareAgreement] = useState(false);

    useEffect(() => {
        const getAgreement = async () => {
            if(typeof district !== "number")
                return;
            try {
                setIsLoading(true);
                const {message, code, elements: {content, caution}} = await API().agreeUrl(district);
                console.log(message, code);
                setAgreement(content);
                setCaution(caution)
            } catch (e) {

            } finally {
                setIsLoading(false);
            }
        };
        getAgreement();
    }, [district]);

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={showAssigneeAgreement}
            onClose={() => {
                setShowAssigneeAgreement(false);
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={showAssigneeAgreement}>
                <div className={styles.ModalContentContainer}>
                    <div className={styles.AssigneeAgreementContainer}>
                        <div className={styles.Header}>
                            <button onClick={() => setShowAssigneeAgreement(false)}><Icons size={25} name={'back'}/>
                            </button>
                            약관
                        </div>

                        <div className={styles.AgreementWrapper}>
                            {parse(agreement)}
                        </div>

                        <CheckButton onChange={() => setPrivacyAgreement(prev => !prev)} selected={privacyAgreement}>[필수]
                            위 개인정보수집.이용에
                            동의합니다.</CheckButton>
                        <CheckButton onChange={() => setShareAgreement(prev => !prev)} selected={shareAgreement}>[필수]
                            주차장 공유에 동의합니다.</CheckButton>
                        <Button style={{margin: '25px 0'}}
                                variant={'contained'} color={'primary'}
                                onClick={onAgree}
                                disabled={!privacyAgreement || !shareAgreement}>
                            동의함
                        </Button>

                        <div className={styles.description}>
                            {parse(caution)}
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
        <Progress isLoading={isLoading}/>
    </>
};

export default AssigneeAgreement;
