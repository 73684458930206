import React, {useState} from 'react'
import classnames from 'classnames/bind'
import {Link, Redirect} from 'react-router-dom'
import {InputAdornment, IconButton, makeStyles, Backdrop, Fade, Modal} from '@material-ui/core'
import styles from './style.module.scss'
import colors from "styles/_colors.scss";
import Icons from "components/Icons";
import TextField from "components/TextField";
import Button from "components/Button";

import SelectBank from "pages/Register/SelectBank";
import Resign from "components/Resign";
import {Toast} from "components";
import {Switch} from "components";
import API from 'api'
import {useMount} from "hooks";
import { Alert } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

const cx = classnames.bind(styles)

const CheckPasswordModal = ({isOpenModal, toggleModal, store, history, goBack}) => {

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [bank, setBank] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [message, setMessage] = useState('');

    const [showSelectBank, setShowSelectBank] = useState(false);
    const [showResignModal, setShowResignModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [marketing, setMarketing] = useState(store.marketing);

    const handleChangeMarketing = async (event) => {
        const checked = !store.marketing;

        setMarketing(checked);
        store.setMarketing(checked)
    };

    async function handleDone() {
        if (password !== passwordCheck) {
            setShowToast('비밀번호와 비밀번호 확인 값이 일치하지 않습니다.');
            return;
        }
        try {
            const {code, message} = await API().userinfoChange(store.user.token, password, name, bank, accountNumber, accountHolder, marketing);
            if (code === 200) {
               
            } else {
                // handle error
            }
            console.log("InfoChangeModal", "handleDone", message);
            setShowToast(message);
            toggleModal();
        } catch (e) {
            // setShowToast(e)
        } finally {
            localStorage.clear();
            store.clearUser();
            history.push('/login');
        }
    }

    async function checkPassword(){
        try {
            const {user, code, message} = await API().login(store.user.username, password);
            if (code === 200) {
                toggleModal();
            } else {
                setShowToast('비밀번호를 확인해 주세요!')
                //setMessage('비밀번호를 확인해 주세요!');
            }
        } catch (e) {
            console.error(e);
        }
    }

    useMount(() => {
        async function getParntnerInfo() {
            try {
                setIsLoading(true)
                const {
                    code, message,
                    elements: {elements: [{name, bank_name, account_number, account_holder, marketing}, ...rest]},
                } = await API().partnerInfo(store.user.token);
                if (code === 200) {
                    setName(name);
                    setBank(bank_name);
                    setAccountNumber(account_number);
                    setAccountHolder(account_holder);
                    setMarketing(marketing);
                }

                console.log("InfoChangeModal", "getParntnerInfo", message);
                //DEL - setShowToast(message);
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }

        getParntnerInfo();
    });

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenModal}>
                {/* <header className= {styles.flexLeft, styles.p-15}>
                    <button onClick={toggleModal}><Icons name={'back'}/></button>
                    <h1 className= {styles.ml-10}> 회원정보수정 </h1>
                </header>
                <section className="warning flex-left p-15">
                    <img src="../../_common/icons/warning-black-icon.svg" alt="경고"/>
                    <p className="h4 ml-5">개인정보 보호를 위해 현재 비밀번호를 입력해주세요.</p>
                </section>
                <section className="input p-15">
                    <p className="h5 gray">비밀번호</p>
                    <div className="input-container password">
                        <input type="password" placeholder="비밀번호를 입력하세요." className="input-password"/>
                        <img src="../../_common/icons/input-clear-icon.svg" className="input-clear"/>
                    </div>
                    <a href="#" clclassNameass="h5 gray-secondary">비밀번호 찾기</a>
                </section>
                <button id="confirm-button" className="bold m-20 gray-secondary">확인</button>
                <script src="../../_common/global/scripts/includeHTML.js"></script>
                <script src="edit-information-1.js"></script>  */}

                
                    
                    {/* <div className={styles.ModalContentContainer}>
                        <div className={styles.top}> 
                            <button onClick={goBack}><Icons name={'back'}/></button>
                            <div className={styles.texts}>
                                회원정보 수정
                            </div>
                        </div>

                        <section className = {styles.warning}>
                            <p className = {styles.message}>
                                    개인정보 보호를 위해 현재 비밀번호를 입력해주세요
                            </p>
                        </section>

                        <section className = {styles.pass}>
                            <div className = {styles.password}>
                                비밀번호
                            </div>
                            <div className = {styles.passInput}>
                                <input type="password" 
                                    placeholder="비밀번호를 입력하세요."
                                    variant="outlined"
                                    size={'small'}
                                    value={password} 
                                    onChange={e => {
                                            setPassword(e.target.value)
                                        }
                                    }
                                    style = {{padding: '0',
                                        width: '100%',
                                        outline: 'none',
                                        color: 'black',
                                        'font-size': '16px',
                                        border: 'none',
                                        'font-weight': '400'
                                    }}
                                />
                            </div>
                            <Link className = {styles.findPass} to={'/find/password'}>비밀번호 찾기</Link>|
                        </section>

                        <div className = {styles.m20}>
                                <Button className = {styles.confirmButton} 
                                        variant="contained"
                                        onClick={checkPassword}>    
                                        <div className = {styles.buttonText}>확인</div>
                                </Button>
                        </div>
                    </div> */}


                    <div className={styles.ModalContentContainer}>
                        <div className={`${styles['flex-left']} ${styles['p-15']} ${styles['text-black']}`}> 
                            <button onClick={goBack}><Icons name={'back'}/></button>
                            <h1 className={`${styles['ml-10']} ${styles.bold}`}>
                                회원정보 수정
                            </h1>
                        </div>

                        <section className = {`${styles['warning']} ${styles['flex-left']} ${styles['p-15']}`}>
                            <p className = {`${styles.h4} ${styles['ml-5']}`}>
                                    개인정보 보호를 위해 현재 비밀번호를 입력해주세요
                            </p>
                        </section>

                        <section className = {`${styles.input} ${styles['p-15']}`}>
                            <p className = {`${styles.h5} ${styles.gray}`}>
                                비밀번호
                            </p>
                            <div className = {`${styles['input-container']} ${styles.passowrd}`}>
                                <input type="password" 
                                    placeholder="비밀번호를 입력하세요."
                                    variant="outlined"
                                    size={'small'}
                                    value={password} 
                                    onChange={e => {
                                            setPassword(e.target.value)
                                        }
                                    }
                                    style = {{padding: '0',
                                        width: '100%',
                                        outline: 'none',
                                        color: 'black',
                                        'font-size': '16px',
                                        border: 'none',
                                        'font-weight': '400'
                                    }}
                                />
                            </div>
                            <Link className = {`${styles.h5} ${styles['gray-secondary']}`} to={'/find/password'}>비밀번호 찾기</Link>|
                        </section>

                        <div className = {styles.m20}>
                                <Button className = {styles.confirmButton} 
                                        variant="contained"
                                        onClick={checkPassword}>    
                                        <div className = {styles.buttonText}>확인</div>
                                </Button>
                        </div>

                    </div>




                    

                        {/* <div className={styles.InfoContainer}>
                            <h1 className = {styles.h1}>
                                <button onClick={goBack}><Icons name={'back'}/></button>
                                회원정보 수정
                            </h1>
                            <div className = {styles.warning}>
                                개인정보 보호를 위해 현재 비밀번호를 입력해주세요
                            </div>
                            <div className = {styles.p15}>
                                <div className = {styles.password}>
                                        비밀번호
                                </div>
                                <div className = {styles.inputContainer}>
                                        <TextField
                                            InputAdornment = {'none'}
                                            size={'small'}
                                            type={'password'}
                                            className={styles.fullWidthText}
                                            value={password}
                                            onChange={e => {
                                                setPassword(e.target.value)
                                            }}/>
                                </div>
                                <Link className = {styles.findPass} to={'/find/password'}>비밀번호 찾기</Link>|
                            </div>
                            
                            {/* <div> 회원탈퇴 버튼은 임시로 없앴다. 2021-03-10
                            <br/>
                                <button onClick={() => {
                                    setShowResignModal(true)
                                }}>
                                    <div className={styles.underBar}>회원 탈퇴</div>
                                </button>
                            </div>
                            </div> */}
                        
    
                    
                
            </Fade>
        </Modal>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={showSelectBank}
            onClose={() => setShowSelectBank(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            
        </Modal>
        <Resign isOpen={showResignModal} toggleModal={() => setShowResignModal(false)} store={store} history={history}/>
        <Toast open={showToast} onClose={()=>setShowToast(false)}>
            {showToast}
        </Toast>
    </>
};

export default CheckPasswordModal;
