import React from 'react';
import colors from "styles/_colors.scss";
import styles from './style.module.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { nonenumerable } from 'core-decorators';

const CheckButton = ({selected, onChange, children, className, style, ...rest}) => {
    return <div className={[styles.checkBoxContainer, className].join(' ')} {...rest}>
        <div style={{display: 'none'}}>
            <input type={'checkbox'} name={children} onChange={onChange} checked={selected}/>
        </div>
        {selected?
            <div style={ style? style : { width: '24px', height: '24px', alignItems: 'center'} }>
            <div className={styles.button} style={{backgroundColor:'#09d046'}} onClick={e=>onChange(e)} >
               <FontAwesomeIcon className={styles.icon} icon={faCheck} size={'sm'} />
            </div>
        </div>: <div style={ style? style : { width: '24px', height: '24px', alignItems: 'center'} }>
                <div className={styles.button}  onClick={e=>onChange(e)} >
                    <FontAwesomeIcon className={styles.icon} icon={faCheck} size={'sm'} />
                </div>
            </div>}
        <span className={styles.label} >{children}</span>
    </div>
};

CheckButton.propTypes = {
};

export default CheckButton;
