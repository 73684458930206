import React, {useState, useEffect} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, Progress, TextField} from "components";

import classnames from 'classnames/bind'
import {useMount} from "hooks";
import API from "api";

const cx = classnames.bind(styles)


const Search = ({isOpenModal, toggleModal, setSeqid, store}) => {
    const [selected, setSelected] = useState({});
    const [searchText, setSearchText] = useState('');
    const [groups, setGroups] = useState([]);
    const [searchedGroups, setSearchedGroups] = useState([]);
    const [groupsCount, setGroupsCount] = useState([{groupName: '강남구청', count: 1, groupSeqid: 76}]);

    const [isLoading, setIsLoading] = useState(false);

    function countJson(json) {
        setIsLoading(true)
        
        const groupNameCollection = json? json.map(group => group.groupName) : []
        let newGroup = {};
        const countedObj = [];

        groupNameCollection.forEach(function (groupName) {
            if (!newGroup[groupName]) {
                const tempGroupSeq = json.filter(group => {
                        if (group.groupName === groupName)
                            return group.groupSeqid
                    }
                )[0].groupSeqid;
                newGroup[groupName] = {
                    count: 1,
                    groupSeqid: tempGroupSeq
                }
            } else
                newGroup[groupName].count++;
        });

        const groupName = Object.keys(newGroup);
        const countList = Object.values(newGroup);

        for (let i = 0; i < Object.keys(newGroup).length; i++) {
            let tmp = {};
            tmp.groupName = groupName[i];
            tmp.count = countList[i].count;
            tmp.groupSeqid = countList[i].groupSeqid;
            countedObj.push(tmp);
        }

        setIsLoading(false);
        return countedObj;
    }

    function handleSeqid(obj) {
        setSeqid(obj);
        setSelected(obj);
        toggleModal();
    }

    useMount(() => {
        const getGroupInfo = async () => {
            try {
                setIsLoading(true);
                const {elements} = await API().groupList(store.user.token);
                setGroups(elements);
                setSearchedGroups(elements);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
        getGroupInfo();
    });
    useEffect(() => {
        if (searchText === '' || !searchText) {
            setSearchedGroups(groups);
            const tmp = countJson(groups);
            setGroupsCount(tmp);
        } else {
            const filteredGroups = groups.filter(group => group.groupName.includes(searchText) || group.parkinglotName.includes(searchText));
            setSearchedGroups(filteredGroups);
            setGroupsCount(countJson(searchedGroups));
            const filteredCountedGroupsList = countJson(searchedGroups).filter(group => (group.groupName).includes((searchText)))
            setGroupsCount(filteredCountedGroupsList);
        }
    }, [searchText, groups]);

    return <> <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modalContainer}
        open={isOpenModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={isOpenModal}>
            <div>
                <div className={styles.ModalContentContainer}>
                    <div className={styles.header}>
                        <button onClick={toggleModal}><Icons name={'back'}/></button>
                        <TextField variant={'standard'}
                                   placeholder={'주차장 검색'}
                                   onChange={e => setSearchText(e.target.value)}/>
                    </div>

                    <button onClick={() => {
                        handleSeqid(null);
                    }} className={cx(styles.item)}>
                        <ColorIcons name={'all'} size={27} style={{flex: '0 1 auto'}}/>
                        <span className={styles.name}>전체주차장</span>
                    </button>
                    {
                        // 그룹 클릭
                        groupsCount ?
                            groupsCount.map((group, index) =>
                                <button key={group.groupName + index} onClick={() => {
                                    handleSeqid({groupName: group.groupName, groupSeqid: group.groupSeqid});
                                }}
                                        className={cx(styles.item,
                                            {selected: selected && selected.groupSeqid && selected.groupSeqid === group.groupSeqid})}>
                                    <ColorIcons name={'group'} size={27} style={{flex: '0 1 auto'}}/>
                                    <span className={styles.name}>
                                    {group.groupName}
                                </span>
                                    {selected && selected.groupSeqid && selected.groupSeqid === group.groupSeqid ?
                                        <Icons color={colors.accent} name={'check-sh'}/> : ''}
                                </button>) : ''
                    }
                    {
                        // 주차장 클릭
                        searchedGroups? 
                        searchedGroups.map((parkinglot, index) =>
                            <button key={parkinglot.groupName + index} onClick={() => {
                                handleSeqid({
                                    parkinglotSeqid: parkinglot.parkinglotSeqid,
                                    parkinglotName: parkinglot.parkinglotName
                                });
                            }}
                                    className={cx(styles.item,
                                        {
                                            selected: selected && (selected.parkinglotSeqid === parkinglot.parkinglotSeqid)
                                        })}>
                                <ColorIcons name={'solo'} size={27} style={{flex: '0 1 auto'}}/>
                                <span className={styles.name}>
                                    {parkinglot.parkinglotName}</span>
                                {selected && (selected.parkinglotSeqid === parkinglot.parkinglotSeqid) ?
                                    <Icons color={colors.accent} name={'check-sh'}/> : ''}
                            </button>)
                    :''}
                </div>
            </div>
        </Fade>
    </Modal>
        <Progress isLoading={isLoading}/>
    </>
};

export default Search;

