import React from 'react'
import styles from './style.module.scss'
const ActionInfo = ({actionTime, parkinglotName, carNum}) => {

    return <div className={styles.ActionInfoContainer}>
        <div className={styles.time}>{actionTime}</div>
        <div className={styles.parkinglot}>{parkinglotName}</div>
        <div className={styles.carNumber}>{carNum}</div>
    </div>
};

export default ActionInfo;
