/*******************************************************************************************
 * 대상: 배정자 및 공단(Officer)
 * 메뉴: 이용현황
 * 페이지: 메인
 *******************************************************************************************/

import React, {useState, useEffect} from 'react'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, TrailButton, Accordian, Progress, CheckButton} from "components";
import API from 'api'
import {withRouter} from 'react-router-dom'
import SearchModal from "../components/SearchModal";
import ReportModal from "../components/ReportModal";
import SearchHeader from "../components/SearchHeader";
import UseDetailExpand from "./UseStatusExpand";

import {numberWithCommas} from "utils";
import {useMount} from "hooks";
import Checkbutton from "../../../components/Checkbutton";
import {Icon} from "@material-ui/core";

const today = `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`;

/*******************************************************************************************
 * 컴포넌트: 주무관/이용현황
 *******************************************************************************************/
const UseStatus = ({store, history}) => {
    const [isZeroButton, setIsZeroButton] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [elements, setElements] = useState([]);

    const [groupList, setGroupList] = useState();
    const [gElements, setgElements] = useState([]);
    const [pElements, setpElements] = useState([]);
    const [selected, setSelected] = useState({});

    const [calDate, setCalDate] = useState(`${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1 )).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`)

    const toggleModal = () => {
        setIsOpenModal(!isOpenModal);
    }
    async function groupHandle(groupSeqid) { //groupSeqid 넣으면 pElements 나옴
        try {
            const {code, elements, message} = await API().useNshareSummary(store.user.token, calDate, 'g', groupSeqid);
            if (code === 200 && elements) {
                if (isZeroButton === false) {
                    elements.pElements = elements.pElements.filter(item => (item.parkinglotAmount !== 0))
                    setgElements(prev => ({...prev, [groupSeqid]: elements}));
                }else
                setgElements(prev => ({...prev, [groupSeqid]: elements}));
            }
            return elements
        } catch (e) {
            console.error(e)
        }
    }

    const getAllInfo = async () => {
        try {
            setIsLoading(true)
            console.log("today : " + today + ", calDate : " + calDate);
            const {code, elements, message} = await API().useNshareSummary(
                store.user.token, calDate, 'a');
            if (code === 200 && elements) {
                setElements(elements);
                if (isZeroButton === false) {
                setGroupList(elements.gElements.filter(item =>(item.groupMonthAmount!==0)))
                elements.gElements.length > 0 ? '' :  history.push('/Register/NoLot')
                }else {
                    setGroupList(elements.gElements)
                }
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    };
    useEffect(() => {
        handleIsZeroButton();
    }, [isZeroButton, calDate]);
    const handleIsZeroButton =() =>{
        if (selected) {
            getAllInfo();
        }
        console.log(selected)
            onSelectSearch(selected)
    }
    const onSelectSearch = async selected => {
        if (!selected) {
            // getAllInfo();
            setSelected({});
        } else {
            setSelected(selected);
            if (selected.parkinglotSeqid) {
                try {
                    setIsLoading(true);
                    const {code, elements, message} = await API()
                        .useNshareSummary(store.user.token, calDate, 'p', null, selected.parkinglotSeqid)
                    if (code === 200 && elements) {
                        setElements({...selected, ...elements});
                        setGroupList(null);
                    }
                } catch (e) {
                    // setShowToastMessage(e)
                } finally {
                    setIsLoading(false);
                }
            } else if (selected.groupSeqid) {
                const {code, elements, message} = await API().useNshareSummary(store.user.token, calDate, 'g', selected.groupSeqid);
                setElements(elements);
                if (isZeroButton === false) {
                    elements.pElements = elements.pElements.filter(item => (item.parkinglotAmount !== 0))
                    setGroupList(elements.pElements);
                }else
                setGroupList(elements.pElements);
            }
        }
    };

    function handleDateButton(e, isPrev) {
        e.preventDefault()
        setCalDate(prev => {
            const targetDate = new Date(prev);
            if (isPrev)
                targetDate.setMonth(targetDate.getMonth() - 1);
            else {
                if (targetDate.getFullYear() + (('0' + (targetDate.getMonth() + 1)).slice(-2))
                    >= new Date().getFullYear() + (('0' + (new Date().getMonth() + 1)).slice(-2))) {
                    return prev
                }
                targetDate.setDate(1);
                targetDate.setMonth(targetDate.getMonth() + 1);
            }
            handleIsZeroButton();
            return `${targetDate.getFullYear()}-${('0' + (targetDate.getMonth() + 1)).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`
        });
    }

    return<>
    <div className={styles.background}></div>
    <div className={styles.widthFill}>

        {/* ROW-00: 주차장 선택 및 검색 바 & 알림 아이콘 */}
        <SearchHeader
            setShowSearchModal={setShowSearchModal}
            setShowReportModal={setShowReportModal}
            store={store}
            selected={selected}
        />
        <section className={styles.usageStatistics}>
            <div className={styles.information}>
                <ColorIcons name={'logo-default'} size={70}/>
            <article className={styles.textRight}>
                <h2 className={styles.bold}> {numberWithCommas(String(elements.dayCount || '0')) + ' 건'}</h2>
                <p>오늘 예약건수</p>
            </article>
            <article className={styles.marginRight}>
                <h2 className={styles.bold}>{numberWithCommas(String(elements.monthCount || '0')) + ' 건'}</h2>
                <p>이번달 총 이용건수</p>
            </article>
        </div>
            <div className={styles.badgeName}>
                <ColorIcons name={selected.groupName ? 'group' : selected.parkinglotName ? 'solo' : 'all'} size={32} style={{marginRight: '8px'}}/>
                <h2 className={styles.bold} style={{marginLeft:'10px'}}> {selected.parkinglotName || selected.groupName || '전체주차장'} </h2>
            </div>
        </section>
        <section className={styles.statusTitle}>
                {/* 달 선택 버튼 추가 시작*/}
                <div className={styles.MonthSelector}>
                    <button onClick={e => handleDateButton(e, true)}>
                        <Icons name={'link-left'} size={11}/>
                    </button>
                    {calDate.slice(0, 4)}년 {calDate.slice(5, 7)}월 이용현황
                    <button onClick={e => handleDateButton(e, false)}>
                        <Icons name={'link-right'} size={11}/>
                    </button>
                </div>
                {/* 달 선택 버튼 추가 끝*/}
                <div className={styles.grid}>
                <article>
                    <div className={styles.flex}  onClick={() => history.push({pathname: 'usestatus/usedetail', selected})}>
                        <h4 className={styles.bold}>매출 현황 <Icons name={'right-arrow'} size={13} /></h4>
                    </div>
                        <div className={styles.mt10}>
                            <h4 className={styles.bold}>오늘</h4>
                            <h3 className={styles.bold}>{numberWithCommas(String(elements.dayAmount || '0')) + ' 원'}</h3>
                        </div>
                        <div className={styles.mt5}>
                            <h4 className={styles.bold} >이번 달</h4>
                            <h3 className={styles.bold}>  {numberWithCommas(String(elements.monthAmount || '0')) + ' 원'}</h3>
                        </div>
                </article>
                <div className={styles.vertical}></div>
                <article>
                    <div className={styles.flex} onClick={() => history.push({pathname: 'usestatus/sharedetail', selected})}>
                        <h4 className={styles.bold}>공유시간 현황  <Icons name={'right-arrow'} size={13} /></h4>
                    </div>
                    <div className={styles.mt10}>
                        <h4 className={styles.bold}>어제</h4>
                        <h3 className={styles.bold}>{numberWithCommas(String(((elements.dayTime || 0) / 60).toFixed(1)|| '0')) + ' 시간'}</h3>
                    </div>
                    <div className={styles.mt5}>
                        <h4 className={styles.bold}>이번 달</h4>
                        <h3 className={styles.bold}>{numberWithCommas(String(((elements.monthTime || 0) / 60).toFixed(0)|| '0')) + ' 시간'}</h3>
                    </div>
                </article>
            </div>
            <div className={styles.warning}>
                <Icons name={'warning-icon'} size={20}/>
                <h4 className={styles.ml10} style={{fontSize: '0.75rem'}}>"앱 결제 위주의 금액이며,<br/>
                    실제 정산 시 사이트 별로 정산 금액이 다를수 있습니다.
                </h4>
            </div>
        </section>
        <section className={styles.filter}>

            <button onChange={handleIsZeroButton}
                    onClick={()=>setIsZeroButton(!isZeroButton)}
                    className={styles.button}>
                모두보기
            </button>
        </section>
        {/* CSS 적용전 */}
        {/*/!* ROW-10: 주자장(그룹) 요약 보기 *!/*/}
        {/*<div className={styles.overviewContainer}>*/}
        {/*    <div className={styles.wapen}>*/}
        {/*        <ColorIcons name={'logo-default'} size={50}/>*/}
        {/*        <div>{store.user.name}</div>*/}
        {/*    </div>*/}
        {/*    <div className={styles.description}>*/}
        {/*        <div className={styles.headingTotal}>*/}
        {/*            <div  className={styles.todayCounts}>*/}
        {/*                <div className={styles.label}>*/}
        {/*                    오늘 예약*/}
        {/*                </div>*/}
        {/*                <div className={styles.numbers}>*/}
        {/*                    {numberWithCommas(String(elements.dayCount || '0')) + ' 건'}*/}
        {/*                </div>*/}
        {/*            </div>*/}

        {/*            <div className={styles.monthlyCounts}>*/}
        {/*                <div className={styles.label}>*/}
        {/*                    이번달 이용건*/}
        {/*                </div>*/}
        {/*                <div className={styles.numbers}>*/}
        {/*                    {numberWithCommas(String(elements.monthCount || '0')) + ' 건'}*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/* ROW-20: 선택된 주차장 또는 그룹주차장 레이블 */}
        {/*<div className={styles.allParkingLot}>*/}
             {/*<div className={styles.parkingLot} >*/}
             {/*     <ColorIcons name={selected.groupName ? 'group' : selected.parkinglotName ? 'solo' : 'all'} size={27} style={{marginRight: '8px'}}/>*/}
             {/*    {selected.parkinglotName || selected.groupName || '전체주차장'}*/}
             {/*</div>*/}
            {/*{selected.parkinglotSeqid?null:*/}
            {/* <div className={styles.zeroButton}>*/}
            {/*    <Button selected={isZeroButton}*/}
            {/*                 onChange={handleIsZeroButton}*/}
            {/*                 onClick={()=>setIsZeroButton(!isZeroButton)}*/}
            {/*                 style={{width: '18px', height: '18px'}}>*/}
            {/*        모두보기*/}
            {/*    </Button>*/}
            {/* </div>*/}
            {/*    }*/}
        {/*</div>*/}

        {/*/!* ROW-30: 선택된 주차장 또는 그룹주차장의 요약 현황 카드 *!/*/}
        {/*<div className={styles.dashboard}>*/}
        {/*    <div className={styles.ParkDetailContainer}>*/}

        {/*        <div className={styles.status}>*/}
        {/*            <TrailButton*/}
        {/*                onClick={() => history.push({pathname: 'usestatus/usedetail', selected})}*/}
        {/*                style={{paddingLeft: 0, fontSize: '15px', color: colors['place-holder'], height: 'auto'}}*/}
        {/*                iconStyle={{marginLeft: '3px', height: '10px'}}*/}
        {/*                color={colors['place-holder']}>이용현황</TrailButton>*/}

        {/*            <div className={styles.value}>*/}
        {/*                <div className={styles.when}>*/}
        {/*                    오늘*/}
        {/*                </div>*/}
        {/*                {numberWithCommas(String(elements.dayAmount || '0')) + ' 원'}*/}
        {/*            </div>*/}
        {/*            <div className={styles.value}>*/}
        {/*                <div className={styles.when}>*/}
        {/*                    이번 달*/}
        {/*                </div>*/}
        {/*                {numberWithCommas(String(elements.monthAmount || '0')) + ' 원'}*/}
        {/*            </div>*/}
        {/*        </div>*/}

        {/*        <div className={styles.status}>*/}
        {/*            <TrailButton className={styles.title}*/}
        {/*                         onClick={() => history.push({pathname: 'usestatus/sharedetail', selected})}*/}
        {/*                         style={{*/}
        {/*                             paddingLeft: 0,*/}
        {/*                             fontSize: '15px',*/}
        {/*                             color: colors['place-holder'],*/}
        {/*                             height: 'auto'*/}
        {/*                         }}*/}
        {/*                         iconStyle={{marginLeft: '3px', height: '10px'}}*/}
        {/*                         color={colors['place-holder']}>공유현황</TrailButton>*/}
        {/*            <div className={styles.value}>*/}
        {/*                <div className={styles.when}>*/}
        {/*                    어제*/}
        {/*                </div>*/}
        {/*                {numberWithCommas(String(((elements.dayTime || 0) / 60).toFixed(1)|| '0')) + ' 시간'}*/}
        {/*            </div>*/}
        {/*            <div className={styles.value}>*/}
        {/*                <div className={styles.when}>*/}
        {/*                    이번 달*/}
        {/*                </div>*/}
        {/*                {numberWithCommas(String(((elements.monthTime || 0) / 60).toFixed(0)|| '0')) + ' 시간'}*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}


        {/*/!* ROW-30: 선택된 그룹주차장의 하위 주차장들의 아코디언 리스트 *!/*/}
        <div className={styles.p15}>
            {
                groupList && groupList.map((group) =>
                            <div key={group.parkinglotSeqid || group.groupSeqid} style={{marginBottom: '10px'}}>
                                <Accordian
                                    onClick={async () => {
                                        if (group.groupSeqid) {
                                            await groupHandle(group.groupSeqid)
                                        } else if (group.parkinglotSeqid) {
                                            try {
                                                const {code, elements, message} = await API().useNshareSummary(store.user.token, calDate, 'p', null, group.parkinglotSeqid)
                                                if (code === 200 && elements) {
                                                    setgElements(prev => ({
                                                        ...prev,
                                                        [group.parkinglotSeqid]: {...group, ...elements}
                                                    }));
                                                }
                                            } catch (e) {
                                                // alert(e)
                                            }
                                        }
                                    }}
                                    end={<span className={styles.suspicious }style={{fontSize: '0.75rem'}}><span
                                        className={styles.accent}><span className={styles.bold} style={{color: '#007BFF',fontSize: '0.75rem'}}>{numberWithCommas((group.groupMonthAmount || group.parkinglotAmount||'0'))} </span>원</span></span>}
                                    expandItem={<UseDetailExpand
                                        group={(gElements[group.groupSeqid] || gElements[group.parkinglotSeqid] || {})}
                                        store={store} today={calDate}/>}>
                                    <div className={styles.category}>
                                        <h4 className={styles.bold} style={{fontSize:'0.75rem'}}>
                                        <ColorIcons
                                            name={group.groupSeqid ? 'group' : group.parkinglotName ? 'solo' : 'all'}
                                            size={20}/>
                                            {group.groupName || group.parkinglotName}
                                        </h4>
                                    </div>
                                </Accordian>
                            </div>
                )
            }
        </div>

        {/* ROW-01: 히든 모달 - 주차장 선택 및 검색창 */}
        <SearchModal isOpenModal={showSearchModal}
                     store={store}
                     setSeqid={onSelectSearch}
                     toggleModal={() => setShowSearchModal(!showSearchModal)}/>

        {/* ROW-02: 히든 모달 - 알림 목록 내용 */}
        <ReportModal store={store}
                     isOpenModal={showReportModal}
                     toggleModal={() => setShowReportModal(!showReportModal)}/>

        {/* ROW-03: 히든 모달 - 프로그레스 에니메이션 */}
        <Progress isLoading={isLoading}/>
</div>
        </>
};

export default withRouter(UseStatus);
