import {useEffect, useState} from 'react'

const useUrlChange = (store) => {
    const [url, setUrl] = useState('');

    function handleMessage(e) {
        try {
            const data = JSON.parse(e.data);

            if (Object.keys(data).includes('init')) {
                store.setRequest(data.request);
                store.setPush(data.pushIn, data.pushOut, data.pushOrder, data.pushBad);
                store.setMarketing(data.marketing);
            } else if (Object.keys(data).includes('position')) {
                store.setGeolocation({longitude: data.position.coords.longitude, latitude: data.position.coords.latitude})
            } else if (Object.keys(data).includes('request')) {
                store.setRequest(data.request);
            } else if (Object.keys(data).includes('marketing')) {
                store.setMarketing(data.marketing);
            }

            if (Object.keys(data).includes('url')) {
                setUrl(data.url)
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        document.addEventListener('message', handleMessage);
        window.addEventListener('message', handleMessage);
        return () => {
            document.removeEventListener('message', handleMessage);
            window.removeEventListener('message', handleMessage);
        }
    }, []);

    return [url, setUrl];
};
export default useUrlChange;
