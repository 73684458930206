import React, {useState} from 'react'
import classnames from 'classnames/bind'
import styles from './style.module.scss'
import Icons from "components/Icons";
import colors from "styles/_colors.scss";
import {GridButtons, Progress} from "components";
import {useMount} from "hooks";
import API from "api";

const cx = classnames.bind(styles)

const SelectBank = ({onSelect, onClose}) => {
    const [bank, setBank] = useState(null);
    const [bankList, setBankList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useMount(() => {
        async function getBankList (){
            try {
                setIsLoading(true)
                await API().bankList().then(({elements}) => setBankList(elements))
            } catch (e) {
                // toast
            } finally {
                setIsLoading(false)
            }
        }
        getBankList()
    });

    return <>
        <div className={styles.BelongingContainer}>
            <h1>
                <div className={styles.backbutton}>
                    <button onClick={onClose}>
                        <Icons name={'icon-close'}
                               size={24}
                               color={colors.text}/>
                    </button>
                    은행 선택
                </div>
            </h1>
            <div className={styles.confirmed}>
                <GridButtons
                    items={bankList.map(bank => bank.bankName)}
                    onChange={index => {
                        setBank(index)
                        onSelect(bankList[index]);
                    }}
                    selectedIndex={bankList.map(bank => bank.bankName).indexOf(bank)}/>
            </div>
        </div>
        <Progress isLoading={isLoading}/>
    </>
};

export default SelectBank;
