import Illegal from "pages/Illegal";

import CheckCar from "pages/Illegal/CheckCar"
import Report from "pages/Illegal/components/ReportModal";
import Setting from "pages/Illegal/Setting";

import Footer from "pages/Illegal/Footer";

const BASE_URI = '/Illegal';

const subRoutes = [
    {
        path: BASE_URI,
        component: Illegal,
        exact: true,
    },
    {
        path: BASE_URI + '/carcheck',
        component: CheckCar,
        exact: true,
    },
    {
        path: BASE_URI + '/report',
        component: Report,
        exact: true,
    },
    {
        path: BASE_URI + '/setting',
        component: Setting,
        exact: true,
    },
]

const IllegalRoutes =
    [{
        path: BASE_URI,
        subRoutes,
        footer: Footer
    }];

export default IllegalRoutes;
