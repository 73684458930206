import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import colors from 'styles/_colors.scss'
import Button from 'components/Button'

import styles from './style.module.scss'
import {Backdrop, Fade, InputAdornment, Modal} from "@material-ui/core";
import CellAuth from "../../components/CellAuth";
import API from "api";
import TextField from "../../components/TextField";
import Icons from "../../components/Icons";

const FindIdentity = ({history, store}) => {
    const [isDone, setIsDone] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [cellNumber, setCellNumber] = useState('');

    function toggleModal() {
        setIsOpenModal(!isOpenModal)
    }

    function handleCellNumber(e) {
        setCellNumber(e.target.value)
    }

    async function handleDone(phoneNumber) {
        try {
            const {code, elements, message} = await API().findID(phoneNumber);

            if (code === 200) {
                setIsDone(elements[0]);
                setIsOpenModal(false);
            } else {
                alert(message);
            }
        } catch (e) {
            console.error(e);
            // alert(e);
        }
    }
    return <>
        <button onClick={() => history.goBack()} style={{margin: '22px 16px'}}>
            <Icons name={'back'} size={24} color={colors.text}/>
        </button>
        <div className={styles.FindIdentityContainer}>
            <div className={styles.innerWrapper}>
                <h1>{isDone ? <span style={{color: colors.accent}}>{isDone.userid}</span> : '아이디 찾기'}</h1>
                <h2>{isDone ? '아이디를 찾았습니다' : '휴대폰 본인인증이 필요합니다.'}</h2>
            </div>
            <div className={styles.innerWrapper}>
                <div className={styles.subtitle}>
                    {isDone ?
                        <>비밀번호 찾기를 하시면,<br/>지금 바로 새로운 비밀번호를<br/>문자로 발송해드리겠습니다.</>
                        : <>거주자우선주차구역 배정자의 경우<br/>휴대폰번호가
                            아이디입니다.</>}
                </div>
            </div>
            {isDone ?
                <Button variant="contained"
                        color="primary"
                        size={'large'}
                        onClick={() => {
                            history.push('/')
                        }}
                        style={{
                            width: '94.4%'
                        }}
                        className={styles.button}>로그인하기</Button>
                :
                <Button variant="contained"
                        color="primary"
                        size={'large'}
                        onClick={toggleModal}
                        style={{
                            width: '94.4%'
                        }}
                        className={styles.button}>본인인증</Button>}


            {isDone ? <Link to={'/find/password'}>
                <div className={styles.findPasswordLink}>
                    비밀번호 찾기
                </div>
            </Link> : ''}
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenModal}>
                <div className={styles.ModalContentContainer}>
                    <CellAuth onAuth={handleDone} store={store} history={history}/>
                </div>
            </Fade>
        </Modal>
    </>;
};

export default FindIdentity;
