import Assignee from "pages/Assignee";

import Home from 'pages/Assignee/Home'
import Use from 'pages/Assignee/Use'
import Share from "pages/Assignee/Share";
import Benefit from "pages/Assignee/Benefit";
import PushLog from "pages/Assignee/PushLog";
import Setting from "pages/Assignee/Setting";
import NoLot from "pages/Register/NoLot";


import Footer from "pages/Assignee/Footer";

const BASE_URI = '/Assignee';

const subRoutes = [
    {
        path: BASE_URI,
        component: Assignee,
        exact: true,
    },
    {
        path: BASE_URI + '/home',
        component: Home,
        exact: true,
    },

    {
        path: BASE_URI + '/use',
        component: Use,
        exact: true,
    },

    {
        path: BASE_URI + '/share',
        component: Share,
        exact: true,
    },
    {
        path: BASE_URI + '/benefit',
        component: Benefit,
        exact: true,
    },
    
    {
        path: BASE_URI + '/pushLog',
        component: PushLog,
        exact: true,
    },

    {
        path: BASE_URI + '/setting',
        component: Setting,
        exact: true,
    },

    {
        path: BASE_URI + '/noLot',
        component: NoLot,
        exact: true,
    }
];

const AssigneeRoutes = [
    {
        path: BASE_URI,
        subRoutes,
        footer: Footer
    }
];

export default AssigneeRoutes;
