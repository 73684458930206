import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {TextField} from '@material-ui/core'
import API from 'api'
import ReportModal from "./ReportModal";
import ReportCheckModal from "../components/ReportModal"
import styles from './style.module.scss'
import {useMount} from 'hooks';

import {Ticket, Button, ColorIcons, Icons, Progress} from "components";

const CheckCar = ({history, store}) => {
    const [date, setDate] = useState(`${new Date().getFullYear()}-${('0' + new Date().getMonth()).slice(-2)}-${('0' + new Date().getDate()).slice(-2)}`);
    const [carNum, setCarNum] = useState(['', '', '']);
    const [elements, setElements] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);

    const [selectedSeqid, setSelectedSeqid] = useState({});
    const [illegalMessage, setIllegalMessage] = useState('');
    const [groupList, setGroupList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [notification, setNotification] = useState(null);

    // 불법주차 단속요청
    async function handleIllegal(carNum, parkinglotSeqid, phoneNumber, slotNum) {
        try {
            setIsLoading(true);
            const {code, message} = await API().illegalRequest(store.user.token, carNum, selectedSeqid.parkinglotSeqid, '', '');
            setIllegalMessage(message);
            return illegalMessage;
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    useMount(() => {
        const getGroupInfo = async () => {
            try {
                setIsLoading(true);
                const {elements} = await API().groupList(store.user.token);
                setGroupList(elements);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };
        getGroupInfo();
    });

    async function handleAPI(date, carNum) {
        if (!date) {
            alert('날짜를 입력해주세요');
            return;
        }
        if (!carNum) {
            alert('차량 번호를 입력해주세요');
            return;
        }

        try {
            setIsLoading(true)
            const {code, elements, message} = await API().illegalCheck(store.user.token, String(date), String(carNum));
            if (code === 200 && elements) {
                const tmp = elements;
                setElements(() => tmp)
            } else {
                setElements(null)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    return <div className={styles.CheckCarContainer}>
        <div className={styles.CheckCarHeader}>
            <div className={styles.titles}>
                <ColorIcons name={'title-carcheck'} size={20}/>
                차량조회
            </div>
            <div/>
        </div>
        <form noValidate>
            <div className={styles.CardContainer}>
                <TextField label=""
                           type="date"
                           defaultValue={date}
                           style={{width: '100%'}}
                           onChange={e => setDate(e.target.value)}
                           InputLabelProps={{
                               shrink: true,
                           }}/>
            </div>
            <div className={styles.CardContainer}>
                {/* <TextField placeholder={'서울'} className={styles.CarField}
                           onChange={e => setCarNum([e.target.value, carNum[1], carNum[2]])}/>
                /
                <TextField placeholder={'123가'} className={styles.CarField}
                           onChange={e => setCarNum([carNum[0], e.target.value, carNum[2]])}/>
                / */}
                <TextField placeholder={'123가1234'} className={styles.CarField}
                           onChange={e => setCarNum([carNum[0], carNum[1], e.target.value])}/>
            </div>
            <Button variant={'contained'} color={'primary'} onClick={() => {
                handleAPI(date, carNum[1] + carNum[2])
            }}>검색</Button>
        </form>
        <div className={styles.result}>
            검색결과
        </div>
        {
        elements ?
                elements.map((element) =>
                    element.product !== '배정자' ?
                        <Ticket time={element.startDate + '~' + element.endDate}
                                lotName={element.parkinglotName} carNumber={element.carNum} ticketColor={'#d4e0f7'}>
                     <span className={styles.tag}>
                         시간권
                    </span>
                            {element.fee}
                        </Ticket>
                        : <div className={styles.CardContainer}
                               style={{flexDirection: 'column', padding: '25px 0'}}>
                            <div><ColorIcons name={'carcheck-signed'}/></div>
                            <div style = {{color : '#3CB371'}}>배정자 차량입니다.</div>
                            <div style={{color:colors.text}}>{element.carNum}({element.parkinglotName})</div>
                        </div>)
                : <div className={styles.CardContainer} style={{flexDirection: 'column', padding: '25px 0'}}>
                    <div><ColorIcons name={'notify-report'}/></div>
                    <div style = {{color : '#DC143C'}}>예약 또는 이용 내역이 없습니다.</div>
                    <Button variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setIsOpenModal(true)
                            }}
                            style={{
                                width: '150px',
                                height: '38px',
                                borderRadius: '19px',
                                fontWeight: 500,
                                fontSize: '16px',
                            }}>
                        단속요청
                    </Button>
                </div>
        }
        <ReportModal store={store} groups={groupList} setSeqid={setSelectedSeqid} isOpenModal={isOpenModal}
                     handleIllegal={handleIllegal} carNum={carNum} illegalMessage={illegalMessage}
                     toggleModal={() => setIsOpenModal(!isOpenModal)}/>
        <Progress isLoading={isLoading}/>
    </div>;
};

export default withRouter(CheckCar);
