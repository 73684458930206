import React, {useEffect, useState} from "react";
import {ColorIcons, Icons, Progress, Toast} from "components";
import styles from "./style.module.scss";
import API from "api";
import ReportModal from "../ReportModal";

const SearchHeader = ({setShowSearchModal, setShowReportModal, store, selected}) => {
    const [notification, setNotification] = useState(null);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
    }, []);

    return  <>
        <div className={styles.searchHeader}>
            <div className={styles.search} onClick={() => setShowSearchModal(true)}>
                <input type="text" style={{width:'100%',fontSize:'12px'}} placeholder="그룹명·주차장명으로 검색해주세요"/>
                {/*<ColorIcons name={(selected && selected.groupName) ? 'group' : (selected &&selected.parkinglotName) ? 'solo' : 'all'}*/}
                {/*            size={27}*/}
                {/*            style={{marginRight: '8px'}}/> {(selected || {}).groupName || (selected || {}).parkinglotName || '전체주차장'}*/}

                <Icons name={'search-normal'}/>
            </div>
            <button className={styles.alertNotification}
                    onClick={() => {
                        setShowReportModal(true);
                        setIsOpenReportModal(true);
                    }}>
                {(notification || []).length > 0 ? <div className={styles.indicator}>{notification.length}</div> : ''}
                <Icons name={'notification-icon'} size={20}/>
            </button>
        </div>

        <ReportModal store={store} isOpenReportModal={isOpenReportModal} toggleModal={() => setIsOpenReportModal(!isOpenReportModal)} />

        <Toast open={showToast} onClose={() => setShowToast(false)} actionICon={'confirm'}>
            {showToast}
        </Toast>
        <Progress isLoading={isLoading}/>
    </>
};
export default SearchHeader;
