import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles';

import {ColorIcons, Icons, Button, Switch, Toast} from "components";
import colors from 'styles/_colors.scss'

import styles from './style.module.scss'
import InfoChangeModal from "components/InfoChangeModal";
import API from "../../../api";

const Setting = ({store, history}) => {

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [notificationIn, setNotificationIn] = useState(store.push.i);
    const [notificationOut, setNotificationOut] = useState(store.push.o);
    const [notificationReserve, setNotificationReserve] = useState(store.push.r);


    const handleChangeIn = async (event) => {
        const checked = !store.push.i;

        const {
            code, message,
            elements: {elements: [{phone_number}, ...rest]},
        } = await API().partnerInfo(store.user.token);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            push: checked,
            kind: `p${phone_number}i`
        }))
        setNotificationIn(checked);
        store.setPush(checked, store.push.o, store.push.r, store.push.b)
    };

    const handleChangeOut = async (event) => {
        const checked = !store.push.o;
        const {
            code, message,
            elements: {elements: [{phone_number}, ...rest]},
        } = await API().partnerInfo(store.user.token);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            push: checked,
            kind: `p${phone_number}o`
        }))
        setNotificationOut(checked);
        store.setPush(store.push.i, checked, store.push.r, store.push.b)
    };
    const handleChangeReserve = async (event) => {
        const checked = !store.push.r;
        const {
            code, message,
            elements: {elements: [{phone_number}, ...rest]},
        } = await API().partnerInfo(store.user.token);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            push: checked,
            kind: `p${phone_number}r`
        }))
        setNotificationReserve(checked);
        store.setPush(store.push.i, store.push.o, checked, store.push.b)
    };

    return <>
        <div className={styles.SettingContainer}>
            <div className={styles.header}>
                <ColorIcons name={'title-setting'} size={25} style={{marginRight: '9px'}}/>
                설정
            </div>
            <div className={styles.Wrapper}>
                <div className={styles.belong}>
                    <ColorIcons name={'logo-default'} className={styles.wapen}/>
                    {store.user.name}
                </div>

                <button onClick={() => setIsOpenModal(true)} style={{width: '100%', padding: '0px', margin: '0px'}}>
                    <div className={styles.item}>
                        회원정보 수정
                        <Icons size={10} color={colors.text} name={'link-right'}/>
                    </div>
                </button>

                <div className={styles.item}>
                    차량 주차 알림 (입차 시)
                    <Switch checked={notificationIn} onChange={handleChangeIn}  onClick={handleChangeIn} name="isNotified"/>
                </div>
                <div className={styles.item}>
                    차량 주차 알림 (출차 시)
                    <Switch checked={notificationOut} onChange={handleChangeOut}  onClick={handleChangeOut} name="isNotified"/>
                </div>

                <div className={styles.item}>
                    예약 알림
                    <Switch checked={notificationReserve} onChange={handleChangeReserve}  onClick={handleChangeReserve} name="isNotified"/>
                </div>

                <a href={'tel:1661-5806'} className={styles.telephone}>
                    <div className={styles.item}>
                        전화문의
                        <Icons size={10} color={colors.text} name={'link-right'}/>
                    </div>
                </a>
            </div>
            <Button variant="contained"
                    color="primary"
                    className={styles.button}
                    onClick={() => {
                        localStorage.clear();
                        store.clearUser();
                        history.push('/login');
                    }}>
                로그아웃
            </Button>
        </div>
        <InfoChangeModal isOpenModal={isOpenModal} toggleModal={() => setIsOpenModal(!isOpenModal)} store={store} history={history}/>
        <Toast open={showToast} actionICon={'confirm'} onClose={()=>setShowToast(false)}>
            {showToast}
        </Toast>
    </>
};

export default withRouter(Setting)
