import React, {useEffect, useState} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import {withRouter} from 'react-router-dom'
import colors from 'styles/_colors.scss'
import API from 'api'
import styles from './style.module.scss'
import {ColorIcons, Icons, Accordian, Button, Toast, Progress} from "components";
import {numberWithCommas} from "utils";
import AdjustExpand from "./AdjustExpand";
import SearchAdjustExpand from "./SearchAdjustExpand";
import SearchHeader from "../components/SearchHeader";
import SearchModal from "../components/SearchModal";
import ReportModal from "../components/ReportModal";
import {withStore} from "hoc";
import {element} from "prop-types";

const BenefitAdjust = ({match, history, store}) => {

        const [isOpenModal, setIsOpenModal] = useState(false);
        const [showSearchModal, setShowSearchModal] = useState(false);
        const [showReportModal, setShowReportModal] = useState(false);
        const [showToastMessage, setShowToastMessage] = useState(false);
        const [isLoading, setIsLoading] = useState(false);

        const [totalAdjust, setTotalAdjust] = useState({});
        const [calDate, setCalDate] = useState(`${new Date().getFullYear()}-${('0' + (new Date().getMonth() )).slice(-2)}`)

        const [groupList, setGroupList] = useState([]);
        const [parkingList, setParkingList] = useState([]);
        const [elementList, setElementList] = useState({});

        const [contractInfo, setContractInfo] = useState({tElements: [{}], elements: [{}], fElements: [{}]});

        const [selected, setSelected] = useState({});

        async function toggleModal() {
            let openModal = false;
            if (!isOpenModal && (totalAdjust.parkinglotSeqid)) {
                setIsLoading(true);
                try {
                    const {code, message, elements} = await API().contract(store.user.token, totalAdjust.parkinglotSeqid);
                    if (code === 200) {
                        openModal = true;
                        setContractInfo(elements)
                    } else if (code === 401) {
                        setShowToastMessage('권한이 없습니다')
                    } else {
                        setShowToastMessage(message)
                    }
                } catch (e) {
                    openModal = false;
                } finally {
                    setIsLoading(false)
                }
            } else {
                setShowToastMessage('단독 주차장을 먼저 선택해주세요');
            }
            setIsOpenModal(openModal)
        }

        function handleDateButton(e, isPrev) {
            e.preventDefault()
            setCalDate(prev => {
                const targetDate = new Date(prev);
                if (isPrev)
                    targetDate.setMonth(targetDate.getMonth() - 1);
                else {
                    if (targetDate.getFullYear() + (('0' + (targetDate.getMonth() + 1)).slice(-2))
                        >= new Date().getFullYear() + (('0' + (new Date().getMonth() + 1)).slice(-2))) {
                        return prev
                    }
                    targetDate.setDate(1);
                    targetDate.setMonth(targetDate.getMonth() + 1);
                }
                return `${targetDate.getFullYear()}-${('0' + (targetDate.getMonth() + 1)).slice(-2)}`
            });
        }

        const getParkInfo = async (parkID) => {
            try {
                setIsLoading(true);
                const {elements} = await API().adjust(
                    store.user.token,
                    calDate,
                    'p', null, parkID);
                let tempElem = elementList
                tempElem[parkID] = elements
                setElementList(tempElem);
                return elements
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false);
            }
        };

        const getGroupInfo = async group => {
            if (group && !group.pElements) {
                {
                    try {
                        setIsLoading(true);
                        const {elements: {pElements, ...rest}} = await API().adjust(
                            store.user.token,
                            calDate,
                            'g', group.groupSeqid);

                        setGroupList(groupList.map(target => {
                            if (target.groupSeqid === group.groupSeqid) {
                                return {...group, ...rest, pElements}
                            } else return target
                        }));
                        return pElements;
                    } catch (e) {
                        console.error(e)
                    } finally {
                        setIsLoading(false);
                    }
                }
            } else {
                const {pElements} = group;
                return pElements
            }
        };

        const getAllInfo = async () => {
            try {
                setIsLoading(true);
                const {elements} = await API().adjust(
                    store.user.token,
                    calDate,
                    'a');
                elements.gElements && setGroupList(elements.gElements);
                setTotalAdjust(elements);
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false);
                setSelected({});
            }
        };

        const onSelectSearch = async selected => {
            setSelected(selected);
            if (!selected || Object.keys(selected).length === 0) {
                getAllInfo();
            } else {
                if (selected.parkinglotSeqid) {

                    setIsLoading(true);
                    try {
                        const parkInfo = await getParkInfo(selected.parkinglotSeqid);
                        setTotalAdjust({
                            parkinglotSeqid: selected.parkinglotSeqid, ...parkInfo,
                            parkinglotName: selected.parkinglotName
                        });
                        setParkingList([]);
                        setGroupList([]);
                    } catch (e) {
                        // setShowToastMessage(e)
                    } finally {
                        setIsLoading(false);
                    }
                } else if (selected.groupSeqid) {
                    const {elements: {pElements, ...rest}} = await API().adjust(
                        store.user.token,
                        calDate,
                        'g', selected.groupSeqid);
                    setTotalAdjust({...pElements, ...selected});
                    // const parkingInfos = await Promise.all(pElements.map(async park => {
                    //     const elements = await getParkInfo(park.parkinglotSeqid)
                    //     return {...elements, ...park}
                    // }))
                    setGroupList(pElements)
// setGroupList();
                }
            }
        };

        useEffect(() => {
            onSelectSearch(selected);
        }, [calDate]);

        useEffect(() => {
            if (match.params.selected && groupList[match.params.selected]) {
                getGroupInfo(groupList[match.params.selected]);
            }
        }, [totalAdjust]);

        return <>
            <div className={styles.background}></div>
            <div className={styles.body}>
                <SearchHeader
                    store={store}
                    selected={selected}
                    setShowSearchModal={setShowSearchModal}
                    setShowReportModal={setShowReportModal}
                />
                <div className={styles.grid}>
                    <h1 className={styles.bold}>정산내역</h1>
                </div>
                <section className={styles.profileWrapper}>
                    <div className={styles.information}>
                        <ColorIcons name={'logo-default'} size={70}/>
                        <article className={styles.mr20}>
                            <h2 className={styles.bold}>{numberWithCommas(totalAdjust.partner_income||'0')}원</h2>
                            <p>총 정산액</p>
                        </article>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.badgeName}>
                            <ColorIcons name={selected && selected.groupName ? 'group' : selected && selected.parkinglotName ? 'solo' : 'all'}
                                        size={27}/>
                            <h2 className={styles.bold}>{totalAdjust.parkinglotName || totalAdjust.groupName || '전체주차장'}</h2>
                        </div>
                        <div className={styles.description}>
                            {selected && selected.parkinglotSeqid?
                                <button onClick={toggleModal}><ColorIcons name={'confirm-contract-information-icon'}/></button>
                                :''}
                        </div>
                    </div>
                    <div className={styles.MonthSelector}>
                        <button onClick={e => handleDateButton(e, true)}>
                            <Icons name={'link-left'} size={11}/>
                        </button>
                        {calDate.slice(0, 4)}년 {calDate.slice(-2)}월 정산내역
                        <button onClick={e => handleDateButton(e, false)}>
                            <Icons name={'link-right'} size={11}/>
                        </button>
                    </div>
                    <section className={styles.settlement}>
                    <AdjustExpand {...totalAdjust}/>
                    </section>
                    <div className={styles.warning}>
                        <Icons name={'warning-icon'} size={20}/>
                        <h4 className={styles.ml10} style={{fontSize: '0.75rem'}}>"앱 결제 위주의 금액이며,<br/>
                            실제 정산 시 사이트 별로 정산 금액이 다를수 있습니다.
                        </h4>
                    </div>
                </section>
                <section className={styles.p5}>
                {
                    (groupList || []).map((group, index) => {
                        return <div key={index} style={{marginBottom: '10px'}}>
                            <Accordian
                                onClick={async () => {
                                    // history.push(`/publiccompany/benefitadjust/${index}`);
                                    if (group.groupSeqid) {
                                        await getGroupInfo(group);
                                        //  setParkingList(await Promise.all(parkInfos.map(park => getParkInfo(park.parkinglotSeqid))));
                                    }else if (group.parkinglotSeqid){
                                        await getParkInfo(group.parkinglotSeqid);
                                    }
                                }}

                                expandItem={ group.parkinglotSeqid? <SearchAdjustExpand {...elementList[group.parkinglotSeqid]}style={{marginTop: '40px'}}/>:
                                    <section className={styles.tlement}>
                                    <AdjustExpand {...group} style={{marginTop: '40px'}}
                                                  parkList={group.pElements} parkInfo={parkingList || groupList} calDate={calDate} store={store}/>
                                    </section>
                                }
                                key={index}
                                selected={index === Number(match.params.selected)}
                                end={<span
                                    className={styles.accent}>{numberWithCommas(group.groupRemittance || group.parkinglotRemittance || group.remittance || 0)}원</span>}>
                                <div className={styles.category}>
                                    <h4 className={styles.bold} style={{fontSize:'0.75rem'}}>
                                        <ColorIcons name={group.parkinglotName ? 'solo' : 'group'} size={27}/>
                                        {group.groupName || group.parkinglotName}
                                    </h4>
                                </div>
                            </Accordian>
                        </div>
                    })
                }
                </section>
            </div>
            {/*<div className={styles.ComponentMainContainer}>*/}
            {/*    <SearchHeader*/}
            {/*        store={store}*/}
            {/*        selected={selected}*/}
            {/*        setShowSearchModal={setShowSearchModal}*/}
            {/*        setShowReportModal={setShowReportModal}*/}
            {/*    />*/}

            {/*    <div className={styles.overviewContainer}>*/}
            {/*        <div className={styles.wapen}>*/}
            {/*            <ColorIcons name={'logo-default'} size={50}/>*/}
            {/*            <div>{store.user.name}</div>*/}
            {/*        </div>*/}
            {/*<div className={styles.description}>*/}
            {/*    {selected && selected.parkinglotSeqid?*/}
            {/*        <Button variant={'contained'} onClick={toggleModal}>*/}
            {/*            계약정보 확인*/}
            {/*            <Icons color={colors.white}*/}
            {/*                   name={'link-right'}*/}
            {/*                   size={11}*/}
            {/*                   style={{marginLeft: '9px'}}/>*/}
            {/*        </Button>: <Button  disabled style={{background: '#ccc'}} variant={'contained'}>*/}
            {/*            계약정보 확인*/}
            {/*            <Icons color={colors.white}*/}
            {/*                   name={'link-right'}*/}
            {/*                   size={11}*/}
            {/*                   style={{marginLeft: '9px'}}/>*/}
            {/*        </Button>}*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<span style={{alignItems: 'center', display: 'flex', marginTop: '29px'}}>*/}
            {/*        <ColorIcons*/}
            {/*            name={selected && selected.groupName ? 'group' : selected && selected.parkinglotName ? 'solo' : 'all'}*/}
            {/*            size={27}*/}
            {/*            style={{marginRight: '8px'}}/> {totalAdjust.parkinglotName || totalAdjust.groupName || '전체주차장'}*/}
            {/*    </span>*/}
            {/*<div className={styles.MonthSelector}>*/}
            {/*    <button onClick={e => handleDateButton(e, true)}>*/}
            {/*        <Icons name={'link-left'} size={11}/>*/}
            {/*    </button>*/}
            {/*    {calDate.slice(0, 4)}년 {calDate.slice(-2)}월*/}
            {/*    <div className={styles.MonthSelector}>*/}
            {/*        <button onClick={e => handleDateButton(e, false)}>*/}
            {/*            <Icons name={'link-right'} size={11}/>*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<AdjustExpand {...totalAdjust}/>*/}
            {/*{*/}
            {/*    (groupList || []).map((group, index) => {*/}
            {/*        return <div key={index} style={{marginBottom: '10px'}}>*/}
            {/*            <Accordian*/}
            {/*                onClick={async () => {*/}
            {/*                    // history.push(`/publiccompany/benefitadjust/${index}`);*/}
            {/*                    if (group.groupSeqid) {*/}
            {/*                        await getGroupInfo(group);*/}
            {/*                      //  setParkingList(await Promise.all(parkInfos.map(park => getParkInfo(park.parkinglotSeqid))));*/}
            {/*                    }else if (group.parkinglotSeqid){*/}
            {/*                        await getParkInfo(group.parkinglotSeqid);*/}
            {/*                    }*/}
            {/*                }}*/}

            {/*                expandItem={ group.parkinglotSeqid? <SearchAdjustExpand {...elementList[group.parkinglotSeqid]}style={{marginTop: '40px'}}/>:*/}
            {/*                    <AdjustExpand {...group} style={{marginTop: '40px'}}*/}
            {/*                 parkList={group.pElements} parkInfo={parkingList || groupList} calDate={calDate} store={store}/>*/}
            {/*                }*/}
            {/*                key={index}*/}
            {/*                selected={index === Number(match.params.selected)}*/}
            {/*                end={<span*/}
            {/*                    className={styles.accent}>{numberWithCommas(group.groupRemittance || group.parkinglotRemittance || group.remittance || 0)}원</span>}>*/}
            {/*                <div className={styles.category}>*/}
            {/*                    <ColorIcons name={group.parkinglotName ? 'solo' : 'group'} size={27}/>*/}
            {/*                    <div className={styles.name}>{group.groupName || group.parkinglotName}</div>*/}
            {/*                </div>*/}
            {/*            </Accordian>*/}
            {/*        </div>*/}
            {/*    })*/}
            {/*}*/}
            {/*</div>*/}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modalContainer}
                open={isOpenModal}
                onClose={toggleModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={
                    {
                        timeout: 500,
                    }
                }>
                <Fade in={isOpenModal}>
                    <div>
                        <div
                            className={styles.ModalContentContainer}>
                            <div
                                className={styles.header}>
                                계약 정보
                                <button
                                    onClick={toggleModal}>
                                    <Icons
                                        name={'icon-close'}
                                    />
                                </button>
                            </div>

                            <div className={styles.item}>
                                <div>주차장명</div>
                                <div className={styles.bold}>
                                    {contractInfo.name}
                                </div>
                            </div>

                            <div className={styles.item}>
                                <div>계약상태</div>
                                <div className={styles.bold}>
                                    {contractInfo.status}
                                </div>
                            </div>

                            <div className={styles.item}>
                                <div>주소</div>
                                <div className={styles.bold}>
                                    {contractInfo.address}
                                </div>
                            </div>

                            <div className={styles.item}>
                                <div>주차면수</div>
                                <div className={styles.bold}>
                                    {contractInfo.lotcount}
                                </div>
                            </div>

                            <div className={styles.item}>
                                <div>계약기간</div>
                                <div className={styles.bold}>
                                    {contractInfo.period}
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div>시설장비</div>
                                <div className={styles.bold}>
                                    {/* {Object.keys(contractInfo.elements[0] || {}).includes('cctv') ? 'cctv, ' : ''}
                                    {Object.keys(contractInfo.elements[0] || {}).includes('sensor') ? '센서' : ''} */}
                                    {Number(contractInfo.cctv) > 0 ? ( Number(contractInfo.cctv) > 0 ? 'CCTV, ' : 'CCTV') : (Number(contractInfo.sensor) > 0 ? '' : '없음') }
                                    {Number(contractInfo.sensor) > 0 ? 'sensor' : ''}
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div>수령계좌</div>
                                <div className={styles.bold}>
                                    예금주 : {contractInfo.accountholder}
                                </div>
                            </div>

                            <div className={styles.item}>
                                <div>계좌번호</div>
                                <div className={styles.bold}>
                                    {contractInfo.account}
                                </div>
                            </div>

                            <div className={styles.item}>
                                <div>운영시간</div>
                                <div className={styles.bold}>
                                    <div>평일 {(contractInfo || {}).tElements[0].baseOperation || <span>설정 없음</span>}</div>
                                    <div>토요일 {(contractInfo || {}).tElements[0].saturdayOperation || <span>설정 없음</span>}</div>
                                    <div>일요일 {(contractInfo || {}).tElements[0].sundayOperation || <span>설정 없음</span>}</div>
                                    <div>공휴일 {(contractInfo || {}).tElements[0].holidayOperation || <span>설정 없음</span>}</div>
                                </div>
                            </div>


                        </div>
                    </div>
                </Fade>
            </Modal>
            <SearchModal isOpenModal={showSearchModal}
                         store={store}
                         setSeqid={onSelectSearch}
                         toggleModal={() => setShowSearchModal(!showSearchModal)}/>
            <ReportModal store={store} isOpenModal={showReportModal}
                         toggleModal={() => setShowReportModal(!showReportModal)}/>

            <Toast open={showToastMessage} actionICon={'confirm'} onClose={() => setShowToastMessage(false)}>
                {showToastMessage}
            </Toast>
            <Progress isLoading={isLoading}/>
        </>
    }
;

export default withRouter(BenefitAdjust);
