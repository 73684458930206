import React, {useState, useEffect} from 'react';
import {Modal, Backdrop, Fade} from '@material-ui/core';
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import classnames from 'classnames/bind'
import {Ticket, ColorIcons, Icons, Progress} from "components";
import API from 'api'
import {useMount} from "hooks";

const sensorShowFlag = false; //true 로 변경시 sensor 이력도 나옴

const cx = classnames.bind(styles);

const color = {
    color : 'white'
}

const hide = {
    display: 'none'
}

function statusCode(element) {
    if (element.type === "sensor") {
        if(sensorShowFlag){
            if(element.status === "OUT"){
                return <Ticket time={element.created_at} lotName={element.lotName}
                            carNumber={'주차시간 ' + element.value + '분'} ticketColor={'#46d6be'}>
                    <div className={styles.circle}>
                        <ColorIcons name={'carcheck-signed'} size={24}/>
                    </div> 
                    <div style = {color}> 출차 알림 </div>
                </Ticket> 
            } else {
                return <Ticket time={element.created_at} lotName={element.lotName}
                            carNumber={element.value} ticketColor={'#53ed79'}>
                    <div className={styles.circle}>
                        <ColorIcons name={'carcheck-signed'} size={24}/>
                    </div> 
                    <div style = {color}> 입차 알림 </div>
                </Ticket> 
            }
        }
    } else if(element.type === "illegal") {
        return <Ticket time={element.created_at} lotName={element.lotName}
                       carNumber={'차량번호 ' + element.value.replaceAll(',', ' ')} partnerType = {element.carNum} ticketColor={'#ee5c5c'}>
            <div className={styles.circle}>
                <ColorIcons name={'notify-report'} size={20}/>
                {console.log(element.partnerType)}
            </div> 
            <div style = {color}> 부정 신고 </div>
            {
                element.status == 0 ? <div className={styles.tag}>요청됨</div> 
                : element.status == 1 ? <div className={styles.tag}>상황종료</div>
                : element.status == 2 ? <div className={styles.tag}>권고 처리</div>
                : element.status == 3 ? <div className={styles.tag}>범칙금</div>
                : element.status == 4 ? <div className={styles.tag}>권고</div>
                : element.status == 5 ? <div className={styles.tag}>결제요청됨</div>
                : element.status == 6 ? <div className={styles.tag}>기타</div>
                : element.status == 7 ? <div className={styles.tag}>삭제처리</div>
                : ''
            } 

        </Ticket>
    } else if(element.type === "order") {
        if(element.status === "REFUNDED") {
            return <Ticket time={element.created_at} lotName={element.lotName}
                        carNumber={element.value + '원'} ticketColor={'#ed9d53'}>
                <div className={styles.circle}>
                    <ColorIcons name={'title-reportlist'} size={24}/>
                </div> 
                <div style = {color}>환불 알림</div>
            </Ticket>
        } else {
            return <Ticket time={element.created_at} lotName={element.lotName}
                carNumber={element.value + '원'} ticketColor={'#4d7adb'}>
            <div className={styles.circle}>
                <ColorIcons name={'title-reportlist'} size={24}/>
            </div> 
            <div style = {color} > 결제 알림 </div>
            </Ticket>
        }
    }
}

const status = sensorShowFlag ? ['원하시는 알림을 선택해주세요', '전체보기', '입/출차 알림', '예약 알림', '부정 주차 알림'] : ['원하시는 알림을 선택해주세요', '전체보기', '예약 알림', '부정 주차 알림']

const ReportMdoal = ({store, isOpenReportModal, toggleModal}) => {
    const [filter, setFilter] = useState(store? store.filter : 1);
    const [data, setData] = useState([]);
    const [filteredElements, setFilteredElements] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showFilterSelect, setShowFilterSelect] = useState(false);

    const onGetNotifications = async () => {
        try {
            setIsLoading(true);
            const {code, elements} = await API().pushLog(store.user.token, store.filter);
            if (code === 200 && elements) {
                setData(elements.elements);
            } else if (code === 400) {
                setShowToast('조회되지 않았습니다')
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false);
        }

        return data;
    };
    
    const onShow = async () => {
        if (!data || data.length == 0) {
            await onGetNotifications();
        }
    };

    useMount(() => {
    });

    useEffect(() => {
        dataFilter(filter);
    }, [filter]);

    useEffect(() => {
        dataFilter(filter);
    }, [data]);

    const dataFilter = sensorShowFlag ? (filter) => {
        if (filter === 0) {
            setFilteredElements(data);
        } else if (filter === 1) {
            setFilteredElements(data);
        } else if (filter === 2) {
            setFilteredElements(data? data.filter(element => element.type === 'sensor') : null);
        } else if (filter === 3) {
            setFilteredElements(data? data.filter(element => element.type === 'order') : null);
        } else if (filter === 4) {
            setFilteredElements(data? data.filter(element => element.type === 'illegal') : null);
        }
    } : (filter) => {
        if (filter === 0) {
            setFilteredElements(data);
        } else if (filter === 1) {
            setFilteredElements(data);
        } else if (filter === 2) {
            setFilteredElements(data? data.filter(element => element.type === 'order') : null);
        } else if (filter === 3) {
            setFilteredElements(data? data.filter(element => element.type === 'illegal') : null);
        }
    }

    let date;

    return <>
        <Modal
            id="report-modal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenReportModal? isOpenReportModal : false}
            onClose={toggleModal}
            onRendered={onShow}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenReportModal? isOpenReportModal : false}>
                <div key="report-modal-fade-01" className={styles.ModalContentContainer}>
                    <div className={styles.header}>
                        <button onClick={toggleModal}><Icons name={'back'}/></button>
                        알림 로그
                    </div>
                    <button style={{width: '100%', padding: 0}} onClick={() => setShowFilterSelect(true)}>
                        <div className={styles.CardContainer}>
                            {status[filter]}
                            <Icons name={'link-right'} size={10}/>
                        </div>
                    </button>
                    {
                        (filteredElements) ?
                            (filteredElements).map((element, index) =>
                                date !== element.created_at.split(' ')[0]?
                                    <div key={index}>
                                        <div className={styles.date}>
                                             <div style = {hide}> {date = element.created_at.split(' ')[0]} </div>
                                            {element.created_at.split(' ')[0]}
                                        </div>
                                        {statusCode(element)}
                                    </div>
                                     : '' /*element.created_at.split(' ')[0] !== elements[index - 1].requestDate.split(' ')[0]*/ ?
                                    <div key={index}>
                                        <div className={styles.date}>
                                            {element.requestDate}
                                        </div>
                                        {statusCode(element)}
                                    </div> : <div key={index}>
                                        {statusCode(element)}
                                    </div>
                            )
                            : '알림이 없습니다.'
                    }
                </div>
            </Fade>
        </Modal>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.FilterModalContainer}
            open={showFilterSelect}
            onClose={() => setShowFilterSelect(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showFilterSelect}>
                <div className={styles.FilterModalContentContainer}>
                    <div className={styles.header}>
                        알림 선택
                        <button onClick={()=>setShowFilterSelect(false)}>
                            <Icons color={colors.text} size={20} name={'cancel-sh'}/>
                        </button>
                    </div>
                    {status.map((status, index) => 
                        index !== 0 ? <div><ul><li><button key={index}
                                onClick={() => {
                                    setFilter(index);
                                    store.setFilter(index);
                                    setShowFilterSelect(false);
                                }}
                                className={cx(styles.filter, {selected: index === filter})}>
                            <div className={styles.label}>
                                {status}
                                {index === filter ?
                                    <Icons style={{marginLeft: '9px'}} name={'check-sh'} size={25}
                                           color={colors.accent}/> : ''}
                            </div>
                        </button></li></ul></div> : '' )}
                </div>
            </Fade>
        </Modal>
        <Progress isLoading={isLoading}/>
    </>
};

export default ReportMdoal;
