import React, {useEffect, useState} from 'react'
import {Link, Redirect} from 'react-router-dom'
import {Modal, Backdrop, Fade, FormControl, colors} from '@material-ui/core'
import {Toast, CheckButton, ColorIcons, TextFieldLogin, Button} from "components";
import Checkbutton from "components/Checkbutton"
import clsx from 'clsx';
import InputAdornment from '@material-ui/core/InputAdornment';


import API from "api";

import styles from './style.module.scss'
import {useMount} from "hooks";
import background from "./img/login_intro.jpg";
import mainapplogo from "./img/main_app_logo.png";
import mainbrandlogo from "./img/main_brand_logo.png";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Login = ({store, history}) => {
    const [keepLogin, setKeepLogin] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(true);
    const [openToast, setOpenToast] = useState(false);

    const [identity, setIdentity] = useState(store.user? store.user.id : '');
    const [password, setPassword] = useState('');

    // const redirectionChange = useUrlChange();


    function handleKeepLogin() {
        setKeepLogin(!keepLogin)
    }

    function toggleModal() {
        setIsOpenModal(!isOpenModal)
    }

    async function handleLogin() {
        try {
            const user = await API(store).login(identity, password);
            if (user.code === 200) {
                if (keepLogin) {
                    localStorage.setItem('token', user.token);
                    localStorage.setItem('type', user.type);
                    localStorage.setItem('id', user.id);
                    localStorage.setItem('username', user.username);
                    localStorage.setItem('name', user.name);
                }
                history.replace(user.type);
            // } else if (user.code === 401) {
                // used to progress but changed
                // ;
            } else {
                setOpenToast(user.message);
                return null;
            }
        } catch (e) {
            console.error(e);
        }
    }

    useMount(() => {
        const type = localStorage.getItem('type');
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('id');
        const userName = localStorage.getItem('username')
        const name = localStorage.getItem('name');

        if (type && token && id && userName && name) {
            store.setUser({type, token, id, userName, name});
            history.replace(type);
        }

        setTimeout(()=>setIsOpenModal(window.ReactNativeWebView && !store.request), 1000)
    });

    return <>
        <div className={styles.LoginContainer} >
                <div className={styles.Login}>
                    로그인
                </div>
                <div className={styles.loginSection}>
                <form noValidate>
                        <TextFieldLogin
                            id="username"
                            name="username"
                            inputMode="tel"
                            tabIndex={0}
                            placeholder="아이디"
                            value={identity}
                            type="tel"
                            autoComplete="username"
                            autoFocus={true}
                            onChange={(e) => setIdentity(e.target.value)}
                        />

                        <TextFieldLogin className={styles.LoginInputText}
                            id="password"
                            type="password"
                            placeholder="비밀번호"
                            value={password}
                            tabIndex={1}
                            inputMode="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                </form>
                <div className={styles.KeepLogInContainer}>
                    <Checkbutton
                        selected={keepLogin}
                        onChange={handleKeepLogin}
                        style={{width: '18px', height: '18px', float:'right'}}
                    >
                        <span style={{color: 'black', fontSize: '10pt'}}>로그인 상태 유지</span>
                    </Checkbutton>
                </div>
                <div className={styles.LogInButtonContainer}>
                    <div className={styles.LoginButton}>
                        {identity !==''&& password!==''?
                            <Button
                                size={'large'}
                                style={{backgroundColor: '#09d046', color:'#ffffff',fontWeight: 'bold'}}
                                onClick={handleLogin}
                            >
                                로그인
                            </Button>:
                            <Button
                                size={'large'}
                                style={{backgroundColor: '#eff2f5', color:'#72808e',fontWeight: 'bold'}}
                                onClick={handleLogin}
                            >
                                로그인
                            </Button>
                            }

                    </div>

                </div>



            <div className={styles.MenuContainer}>
                <div className={styles.MenuLinks}>
                    <Link className={styles.MenuLinkItem} to={'/register'}>회원가입</Link>|
                    <Link className={styles.MenuLinkItem} to={'/find'}>아이디찾기</Link>|
                    <Link className={styles.MenuLinkItem} to={'/find/password'}>비밀번호찾기</Link>

                </div>
            </div>
          </div>
        </div>
        <Toast open={openToast} actionICon={'confirm'} onClose={() => setOpenToast(false)}>
            {openToast}
        </Toast>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal||false}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenModal}>
                <div>
                    <div className={styles.ModalContentContainer}>
                        <h1>앱 접근 권한 안내</h1>
                        <div className={styles.description}>
                            파킹프렌즈 파트너 앱을 이용하기 위해 <br/>
                            필요할 수 있는 권한입니다.<br/>
                            다음의 앱 권한을 허용해주세요.
                        </div>

                        <div className={styles.accessDescription}>
                            <div className={styles.detail}>
                                <ColorIcons name={'gps-icon'}/>
                                <div>
                                    <h3>현재 위치를 알 수 있어요.</h3>
                                    <div className={styles.subtitle}>
                                        주변 주차장 거리 표시
                                    </div>
                                </div>
                            </div>

                            <div className={styles.detail}>
                                <ColorIcons name={'push-notification-icon'}/>
                                <div>
                                    <h3>푸시를 받을 수 있어요.</h3>
                                    <div className={styles.subtitle}>
                                        부정주차 신고, 예약 알림 등
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Button variant="contained"
                                color="primary"
                                size={'large'}
                                onClick={() => {
                                    window.ReactNativeWebView.postMessage(JSON.stringify({request: true}));
                                    setIsOpenModal(false);
                                }}
                                className={styles.button}>
                            확인했습니다
                        </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    </>;
};

export default Login;
