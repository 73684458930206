import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import {InputAdornment, IconButton} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles';
import Icons from "../Icons";

// We can inject some CSS into the DOM.
const styles = {
    root: {
        width: '100%',
        height: '54px',
        '& > *': {
            width: '100%', borderRadius: '12px',
            height: '54px',
        },
    },
};

function TextField(props) {
    const {children, className, inin, onChange, value, ...other} = props;

    return (
        <MUITextField className={className} style={styles.root} {...other}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={onChange}
                      value={value}
                      InputProps={{
                          endAdornment:
                              value !== '' ?
                                  <InputAdornment position="end">
                                      <IconButton onClick={() => onChange({target: {value: ''}})}>
                                          <div style={{
                                              borderRadius: '100%',
                                              backgroundColor: '#bac1cf',
                                              width: '18px',
                                              height: '18px',
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                          }}>
                                              <Icons color={'#fff'} name={'icon-close'} size={10}/>
                                          </div>
                                      </IconButton>
                                  </InputAdornment> : null,
                          ...inin,
                      }}>
            {children}
        </MUITextField>
    );
}

TextField.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default withStyles(styles)(TextField);
