import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import styles from './style.module.scss'
import ColorIcons from "components/ColorIcons";
import Button from "components/Button";
import InfoChangeModal from "components/InfoChangeModal";


const Progress = ({history, store}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);

    return <>
        <div className={styles.ProgressContainer}>
            <h1>죄송합니다.</h1>
            <div className={styles.description}>
                회원가입 승인이 지연되고 있습니다.<br/>
                조금만 기다려주시면<br/>
                빠르게 승인해드리겠습니다.
            </div>
            <div className={styles.contactWrapper}>
                <ColorIcons name={'cs'}/>
                <div>
                    <a href={'tel:1661-5806'} className={styles.telephone}>1661-5806</a>
                    <div className={styles.contactDescription}>고객센터 문의하기</div>
                </div>
            </div>
            <div className={styles.buttonContainer}>
                <Button variant="contained"
                        color="primary"
                        onClick={()=>{
                            setIsOpenModal(true)
                        }}>
                    회원정보수정
                </Button>
                <Button variant="contained"
                        color="secondary"
                        onClick={() => {
                            localStorage.clear();
                            store.clearUser();
                            history.push('/login');
                        }}>
                    로그아웃
                </Button>
            </div>
        </div>
        <InfoChangeModal isOpenModal={isOpenModal} toggleModal={() => setIsOpenModal(!isOpenModal)} store={store}/>
    </>;
};

export default withRouter(Progress);

