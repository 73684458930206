import React, {useState, useEffect} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import styles from './style.module.scss'
import {Button, ColorIcons, GridButtons, Icons, Progress, Switch, TextField, Toast} from "components";

import classnames from 'classnames/bind'
import {useMount} from "hooks";
import API from "api";
import colors from "../../../styles/_colors.scss";
import {element} from "prop-types";
import {numberWithCommas} from "../../../utils";

const cx = classnames.bind(styles)

const timeList = [
    'AM 0:00~0:59',
    '1:00~1:59',
    '2:00~2:59',
    '3:00~3:59',
    '4:00~4:59',
    '5:00~5:59',
    '6:00~6:59',
    '7:00~7:59',
    '8:00~8:59',
    '9:00~9:59',
    '10:00~10:59',
    '11:00~11:59',
    'PM 12:00~12:59',
    '13:00~13:59',
    '14:00~14:59',
    '15:00~15:59',
    '16:00~16:59',
    '17:00~17:59',
    '18:00~18:59',
    '19:00~19:59',
    '20:00~20:59',
    '21:00~21:59',
    '22:00~22:59',
    '23:00~23:59',
];

function mapDay(day) {
    switch (day) {
        case 0:
            return 'monday';

        case 1:
            return 'tuesday';

        case 2:
            return 'wednesday';

        case 3:
            return 'thursday';

        case 4:
            return 'friday';

        case 5:
            return 'saturday';

        case 6:
            return 'sunday';
        default:
            return 'holiday'
    }
}

function mapDaytoIndex(day) {

    switch (day) {
        case 'mon':
            return 0;
        case 'tue':
            return 1;
        case 'wed':
            return 2;
        case 'thu':
            return 3;
        case 'fri':
            return 4;
        case 'sat':
            return 5;
        case 'sun':
            return 6;
        default:
            return 7;
    }
}

const ShareModal = ({isOpenModal, toggleModal, store}) => {
    const [lotOperTime, setLotOperTime] = useState({operationTime:""});
    const [selectedDay, setSelectedDay] = useState([]);
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [pauseOnWeekend, setPauseOnWeekend] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const handleSelectDay = (index) => {
        if (selectedDay.includes(index))
            return setSelectedDay(selectedDay.filter(day => day !== index))
        else
            return setSelectedDay([...selectedDay, index])
    };

    const handleAllClear = () => {
        return setSelectedTimes([])
    };
    const handleAllSelect = () => {
        return setSelectedTimes([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23])
    };
    useMount(() => {
        const getShareTime = async () => {
            try {
                const {elements: {elements}, code, message} = await API().shareTimeList(store.user.token);
                const selected = elements.filter(element => element.startTime && element.endTime)
                if (selected.length > 0) {
                    const tempArray = (new Array( Number((elements[k].endTime === null ? "00:00:00" :  elements[k].endTime).slice(3, 5)) > 49 ? (Number((elements[k].endTime === null ? "00:00:00" : elements[k].endTime).slice(0, 2)) + 1) - Number((elements[k].startTime === null ? "00:00:00" : elements[k].startTime).slice(0, 2)) : (Number((elements[k].endTime === null ? "00:00:00" : elements[k].endTime).slice(0, 2)))  - Number((elements[k].startTime === null ? "00:00:00" : elements[k].startTime).slice(0, 2))))
                    .fill(-1).map((empty, index) => {
                        return (index + Number((elements[k].startTime === null ? "00:00:00" : elements[k].startTime).slice(0, 2))) % 24
                    });
                    setSelectedTimes(tempArray);
                    setSelectedDay(selected.map(target => mapDaytoIndex(target.WEEK)).filter(day => day !== 7))
                }
                //DEL- setShowToast(message)
            } catch (e) {
                console.error(e)
            }
        };
        getShareTime()
        getOperAPI()
    });
    const getOperAPI = async () => {
        try{
            const {elements, code, message} = await API().lotOperTime(store.user.token);
            if(code === 200) {
                console.log("TEST:", elements);
                setLotOperTime(elements);
            }
        }catch(e){
            console.error(e)
        }
    }

    const handleDone = async () => {
        console.log("TEST",lotOperTime.operationTime.baseFrom);
        try {
            const body = selectedDay.map(day => ({
                weekday: mapDay(day),
                shareFrom: ('0' + (selectedTimes[0])).slice(-2),
                shareTo: ('0' + (selectedTimes[selectedTimes.length - 1]) % 24).slice(-2)
            }));
            if (body.length===0){
                setShowToast("변경 선택한 요일이 없습니다.");
            }
            else {
                const {code, message} = await API().shareTimeChange(store.user.token, body);
                if (code === 200) {
                    toggleModal();
                } else {
                    setShowToast(message);
                }
            }
        } catch (e) {

        } finally {
        }
    };

    useEffect(() => {
        const pauseOnWeekendAPI = async () => {
                try {
                    const elements = [{weekday: 'holiday',
                        shareFrom: pauseOnWeekend,
                        shareTo: 23}];
                    const {code, message} = await API().shareTimeChange(store.user.token, elements);
                    if (code === 200) {
                        //DEL - setShowToast(message);
                    }
                } catch (e) {
                    // setShowToast(e);
                }
        }
        pauseOnWeekendAPI();
    }, [pauseOnWeekend]);
    
    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenModal}>
                <div>
                    <div className={styles.ModalContentContainer}>
                        <div className={styles.header}>
                            <button onClick={toggleModal}><Icons name={'back'}/></button>
                            공유시간 변경
                        </div>

                        <div className={styles.status}>
                            {/*<ColorIcons name={`emoticon-${selectedDay.length * selectedTimes.length < 10? 'low':selectedDay.length * selectedTimes.length>20? 'high':'normalhigh'}`} size={52} classNames={styles.icon}/>*/}
                            <div className={styles.description}>
                                {/*<div className={styles.bold}>{selectedDay.length * selectedTimes.length}시간</div>*/}
                                <div className={styles.bold}>공유시간을 변경할 요일과 공유시간을 선택해주세요.</div>
                                요일은 복수 선택 가능하며, 시간을 일괄 변경합니다.
                            </div>
                        </div>
                        <div className={styles.operationTimeContainer}>
                            <div className={styles.operationTimeTitle}>
                                <div> 주차장 운영 시간</div>
                            </div>
                            <div  className={styles.Card}>
                             <div  className={styles.Card}>
                                <div className={styles.LMa}>
                                    <div className={styles.m}></div>
                                    <div className={styles.Item}>
                                        <div className={styles.operationTimeText}> 평일 운영시간</div>
                                        <div className={styles.r0}>
                                            {lotOperTime.operationTime.baseFrom}~{lotOperTime.operationTime.baseTo}
                                        </div>
                                    </div>
                                </div>
                                </div>
                                  <div  className={styles.Card}>
                                    <div className={styles.LMa}>
                                        <div className={styles.m}></div>
                                        <div className={styles.Item}>
                                                <div className={styles.operationTimeText}> 토요일 운영시간</div>
                                            <div className={styles.r0}>
                                                {lotOperTime.operationTime.satFrom}~{lotOperTime.operationTime.satTo}
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                    <div  className={styles.Card}>
                                     <div className={styles.LMa}>
                                        <div className={styles.m}></div>
                                            <div className={styles.Item}>
                                                <div className={styles.operationTimeText}> 일요일 운영시간</div>
                                                <div className={styles.r0}>
                                                    {lotOperTime.operationTime.sunFrom}~{lotOperTime.operationTime.sunTo}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className={styles.ShareModalContainer}>

                            {/*<div style={{textAlign: 'right'}}>*/}
                            {/*    공휴일 공유 중지*/}
                            {/*    <Switch*/}
                            {/*        onChange={async e => {*/}
                            {/*            setPauseOnWeekend(e.target.checked)*/}
                            {/*            // 토, 일요일*/}
                            {/*            // if (selectedDay.includes(6) || selectedDay.includes(5))*/}
                            {/*            //     setSelectedDay(selectedDay.filter(day => (day !== 6) && (day !== 5)))*/}
                            {/*        }}*/}
                            {/*        checked={pauseOnWeekend}/>*/}
                            {/*</div>*/}

                            <table className={styles.weekPicker}>
                                <thead>
                                <tr>
                                    <td>
                                        <button
                                            className={cx({selectable: true, selected: selectedDay.includes(0)})}
                                            onClick={() => handleSelectDay(0)}>월
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className={cx({selectable: true, selected: selectedDay.includes(1)})}
                                            onClick={() => handleSelectDay(1)}>화
                                        </button>
                                    </td>

                                    <td>
                                        <button
                                            className={cx({selectable: true, selected: selectedDay.includes(2)})}
                                            onClick={() => handleSelectDay(2)}>수
                                        </button>
                                    </td>

                                    <td>
                                        <button
                                            className={cx({selectable: true, selected: selectedDay.includes(3)})}
                                            onClick={() => handleSelectDay(3)}>목
                                        </button>
                                    </td>

                                    <td>
                                        <button
                                            className={cx({selectable: true, selected: selectedDay.includes(4)})}
                                            onClick={() => handleSelectDay(4)}>금
                                        </button>
                                    </td>

                                    <td>
                                        <button
                                            className={cx({selectable: true,
                                                selected: selectedDay.includes(5)
                                            })}
                                            onClick={() => {
                                                handleSelectDay(5)
                                            }}
                                            style={{color: '#5384ed'}}>
                                            토
                                        </button>
                                    </td>

                                    <td>
                                        <button
                                            className={cx({selectable: true,
                                                selected: selectedDay.includes(6)
                                            })}
                                            onClick={() => {
                                                handleSelectDay(6)
                                            }}
                                            style={{color: '#fb4462'}}>
                                            일
                                        </button>
                                    </td>
                                </tr>
                                </thead>
                            </table>
                            <div style={{display: 'flex', marginTop: '15px', paddingBottom: '15px'}}>
                                <Button variant={"outlined"} color={'primary'}
                                        onClick={() => handleAllClear()}
                                        style={{
                                            fontSize: '0.7em',
                                            height: '43px',
                                            lineHeight: '1.1',
                                            borderRadius: '21.5px'
                                        }}>모두 해제</Button>
                                <Button variant={"outlined"} color={'primary'}
                                        onClick={() => handleAllSelect()}
                                        style={{
                                            fontSize: '0.7em',
                                            height: '43px',
                                            lineHeight: '1.1',
                                            borderRadius: '21.5px'
                                }}>모두 선택</Button>
                            </div>
                            <GridButtons
                                items={timeList}
                                onChange={(timeIndex) => {
                                    if (selectedTimes.length === 0 || selectedTimes.length > 1) {
                                        setSelectedTimes([timeIndex])
                                    } else if (selectedTimes.length === 1) {
                                        setSelectedTimes(timeList.map((time, index) => {
                                            if (selectedTimes[0] <= index && index <= timeIndex)
                                                return index;
                                            else return -1;
                                        }).filter(time => time >= 0))
                                    }
                                }}
                                selectedIndex={selectedTimes}/>

                        </div>

                        <div style={{display: 'flex', marginTop: '15px', paddingBottom: '15px'}}>
                            <Button variant={"outlined"} color={'primary'} onClick={toggleModal} style={{marginRight: '5px'}}>취소</Button>
                            <Button variant={"contained"} color={'primary'} onClick={handleDone} style={{marginleft: '5px'}}>적용하기</Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
        <Toast open={showToast} onClose={()=>setShowToast(false)}>
            {showToast}
        </Toast>
        <Progress isLoading={isLoading}/>
    </>
};

export default ShareModal;
