import React, {useState, useEffect} from 'react'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, TrailButton, Accordian} from "components";
import API from 'api'
import MonthPicker from "components/MonthPicker";
import useMonthSelector from "components/MonthPicker/hooks";

const ShareStatus = ({store, history}) => {
    const [elements, setElements] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [pElements, setPElements] = useState([]);
    const [parkList, setParkList] = useState({});

    const [calDate, handleDateButton] = useMonthSelector()

    const selected = history.location.selected;

    const getGroupInfo = async group => {
        if (!group.pElements) {
            {
                try {
                    const {elements: {pElements, ...rest}} = await API().shareDetail(
                        store.user.token,
                        calDate,
                        'g', group.groupSeqid);

                    setGroupList(groupList.map(target => {
                        if (target.groupSeqid === group.groupSeqid) {
                            return {...group, ...rest, pElements}
                        } else return target
                    }));
                    return pElements;
                } catch (e) {
                    console.error(e)
                }
            }
        } else {
            const {pElements} = group;
            return pElements
        }
    };


    useEffect(() => {
        const getAllInfo = async () => {
            try {
                const {code, elements, message} = await API().shareDetail(
                    store.user.token,
                    calDate, 'a');
                if (code === 200 && elements) {
                    setElements(elements);
                    setGroupList(elements.gElements);
                } else {
                    console.log(message);
                }
            } catch (e) {
                console.error(e)
            }
        };

        const getGroup = async () => {
            try {
                const {elements, code, message} = await API().shareDetail(
                    store.user.token,
                    calDate, 'g', selected.groupSeqid)
                if (code === 200 && elements) {
                    setElements(elements);
                    setGroupList(elements.pElements);
                }
            } catch (e) {

            }
        };

        const getPark = async () => {
            try {
                const {elements, code, message} = await API().shareDetail(
                    store.user.token,
                    calDate, 'p', null, selected.parkinglotSeqid)
                if (code === 200 && elements) {
                    setElements(elements);
                }
            } catch (e) {

            }
        };

        if (!selected || Object.keys(selected).length === 0) {
            getAllInfo();
        } else if (selected.groupSeqid) {
            getGroup();
        } else if (selected.parkinglotSeqid) {
            getPark();
        }
    }, [calDate]);


    return <div className={styles.ComponentMainContainer}>
        <div className={styles.Header}>
            <button onClick={() => {
                history.goBack()
            }}>
                <Icons name={'back'} size={24}/>
            </button>
            공유 현황
        </div>

        <MonthPicker handleDateButton={handleDateButton} calDate={calDate}/>

        <span style={{alignItems: 'center', display: 'flex', marginTop: '29px'}}>
                {
                    (!selected || Object.keys(selected).length === 0) ? <><ColorIcons name={'all'} size={27}
                                                                                      style={{marginRight: '8px'}}/>전체주차장</>
                        : selected.groupSeqid ?
                        <><ColorIcons name={'group'} size={27} style={{marginRight: '8px'}}/>{selected.groupName}</>
                        : <><ColorIcons name={'solo'} size={27}
                                        style={{marginRight: '8px'}}/>{selected.parkinglotName}</>
                }
        </span>

        <div className={styles.dashboard}>
            <div className={styles.ParkDetailContainer}>

                    <div
                        style={{paddingLeft: 0, fontSize: '15px', color: colors['place-holder'], height: 'auto'}}
                        iconStyle={{marginLeft: '3px', height: '10px'}}
                        color={colors['place-holder']}>{calDate}</div>
                <div className={styles.status}>
                    <div className={styles.value}>
                       전체 공유 시간
                    </div>
                    <div className={styles.value2}>
                        {((elements.shareTime) / 60).toFixed(1) + ' 시간'}
                    </div>
                    {elements.sElements && elements.sElements.map((share, index) => {
                        return <div className={styles.shareTime}
                                    key={index}>
                            <div>{share.shareDate}</div>
                            {(share.shareDateshareTime / 60).toFixed(1)}시간
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div style={{marginTop: '40px'}}>
            {
                groupList ?
                    groupList.map((group, index) =>
                        <div>
                            <Accordian
                                key={index}
                                onClick={async () => {
                                    if (group.groupSeqid) {
                                        const parkList = await getGroupInfo(group);
                                        // const result = await Promise.all(parkList.map(async park => {
                                        //     const parkInfo = await getParkInfo(park.parkinglotSeqid)
                                        //     return {...parkInfo, ...park};
                                        // }));
                                        setPElements(parkList);
                                    } else if (group.parkinglotSeqid) {
                                        try {
                                            const {elements} = await API().shareDetail(store.user.token,
                                                calDate,
                                                'p', null, group.parkinglotSeqid)
                                            setGroupList(prev => {
                                                return prev.map(target => {
                                                    if (target.parkinglotSeqid === group.parkinglotSeqid) {
                                                        return {...target, ...elements}
                                                    } else return target
                                                });
                                            });
                                        } catch (e) {

                                        } finally {

                                        }
                                    }
                                }}
                                end={<span className={styles.suspicious}>
                                    <span className={styles.accent}>
                                    {((group.groupshareTime || group.parkinglotshareTime || 0) / 60).toFixed(1)}시간
                                    </span>
                                    </span>}
                                expandItem={
                                    <div className={styles.ParkDetailContainer}>
                                        <div className={styles.status}>
                                            {calDate} 전체 공유 시간
                                            <div className={styles.value}>
                                                {((group.shareTime || group.parkinglotShareTime || 0) / 60).toFixed(1)}시간
                                            </div>
                                            {group.sElements && group.sElements.map((share, index) => {
                                                return <div className={styles.shareTime}
                                                            key={index}>
                                                    <div>{share.shareDate}</div>
                                                    {(share.shareDateshareTime / 60).toFixed(1)}시간
                                                </div>
                                            })}
                                        </div>
                                        {
                                            pElements && pElements.map(park => {
                                                return <div key={park.parkinglotSeqid}>
                                                    <Accordian
                                                        onClick={async () => {
                                                            try {
                                                                const {elements} = await API().shareDetail(
                                                                    store.user.token,
                                                                    calDate,
                                                                    'p',
                                                                    null,
                                                                    park.parkinglotSeqid);

                                                                setParkList(prev => ({
                                                                    ...prev,
                                                                    [park.parkinglotSeqid]: elements
                                                                }))
                                                            } catch (e) {
                                                                console.error(e)
                                                            }
                                                        }}
                                                        end={<span className={styles.suspicious}>
                                                            <span
                                                                className={styles.accent}>{((park.parkinglotshareTime) / 60).toFixed(1)}시간</span>
                                                        </span>}
                                                        expandItem={
                                                            <>
                                                                <div className={styles.status}>
                                                                    {calDate} 전체 공유 시간
                                                                    <div className={styles.value}>
                                                                        {((parkList[park.parkinglotSeqid] || {}).shareTime / 60).toFixed(1)}시간
                                                                    </div>
                                                                </div>
                                                                {parkList[park.parkinglotSeqid] && parkList[park.parkinglotSeqid].sElements.map((share, index) => {
                                                                    return <div className={styles.shareTime}
                                                                                key={index}>
                                                                        <div>{share.shareDate}</div>
                                                                        {(share.shareDateshareTime / 60).toFixed(1)}시간
                                                                    </div>
                                                                })}
                                                            </>
                                                        }
                                                    >
                                                        <div className={styles.category}>
                                                            <ColorIcons name={'solo'} size={27}/>
                                                            <div className={styles.name}>{park.parkinglotName}</div>
                                                        </div>
                                                    </Accordian>
                                                </div>
                                            })
                                        }
                                    </div>
                                }

                            >
                                <div className={styles.category}>
                                    <ColorIcons name={group.parkinglotName ? 'solo' : 'group'} size={27}/>
                                    <div className={styles.name}>
                                        {group.parkinglotName ? group.parkinglotName : group.groupName}
                                    </div>
                                </div>
                            </Accordian><br/></div>
                    ) : ''
            }
        </div>
    </div>
};

export default ShareStatus;
