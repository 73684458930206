import React, {useState} from "react";
import colors from 'styles/_colors.scss'
import styles from "./style.module.scss";
import {ColorIcons, Accordian} from "components";

import {numberWithCommas} from "utils";
import API from "../../../api";
import {element} from "prop-types";

const Receipt = ({income, outcome, s_value, vat, calRate, partner_income, pg, remittance}) => (
    <>
        <section className={styles.detailSettlement}>
        <div className={styles.containerTitle}>
            <h4 className={`${styles.bold} ${styles.gray}`}> 월 정산액 </h4>
            {/* <div className={styles.accent}>정산지급일 2020.07.15</div>
            <div>{numberWithCommas(income||'0')}원</div> */}
            <h3 className={styles.bold}>{numberWithCommas(remittance||'0')}원</h3>
        </div>
        <hr/>
        <ul className={styles.containerList}>
            <li className={styles.contentItem}>
                <p className={styles.h4}>월매출</p>
                <p className={styles.h4}>{numberWithCommas(income||'0')}원</p>
            </li>
            <li className={styles.contentItem}>
                <p className={`${styles.h4} ${styles.gray}`}>부가세</p>
                <p className={`${styles.h4} ${styles.gray}`}>{numberWithCommas(vat||'0')}원</p>
            </li>
            <li className={styles.contentItem}>
                <p className={`${styles.h4} ${styles.gray}`}>유지관리비</p>
                <p className={`${styles.h4} ${styles.gray}`}>{numberWithCommas(outcome||'0')}원</p>
            </li>
            <li className={styles.contentItem}>
                <p className={`${styles.h4} ${styles.gray}`}>결제수수료</p>
                <p className={`${styles.h4} ${styles.gray}`}>{numberWithCommas(pg||'0')}원</p>
            </li>
        </ul>
        <hr/>
        <div className={styles.resultContainer}>
            <h4 className={styles.bold}>수익</h4>
            <h4 className={styles.bold}>{numberWithCommas(partner_income||'0')}원</h4>
        </div>
            <div className={styles.resultContainer}>
                <h4 className={styles.bold}>정산액</h4>
                <h4 className={styles.bold}>{numberWithCommas(partner_income||'0')}원</h4>
            </div>
        </section>
        {/*<div className={styles.help}>*/}
        {/*    내 수익 계산법 = [(&nbsp;*/}
        {/*    <div className={styles.circlarNumber}>1</div>*/}
        {/*    &nbsp;*/}
        {/*    - &nbsp;*/}
        {/*    <div className={styles.circlarNumber}>2</div>*/}
        {/*    &nbsp; x {calRate || 0} ) -&nbsp;*/}
        {/*    <div className={styles.circlarNumber}>3</div>*/}
        {/*</div>*/}
        {/*<div className={styles.item}>*/}
        {/*    <div>월매출</div>*/}
        {/*    <div>{numberWithCommas(income||'0')}원</div>*/}
        {/*</div>*/}
        {/*<div className={styles.item}>*/}
        {/*    <div>*/}
        {/*        /!*<div className={styles.circlarNumber}>1</div>*!/*/}
        {/*        /!*&nbsp;*!/*/}
        {/*        공급가액*/}
        {/*    </div>*/}
        {/*    <div>{numberWithCommas(s_value||'0')}원</div>*/}
        {/*</div>*/}
        {/*<div className={styles.item}>*/}
        {/*    <div>부가세</div>*/}
        {/*    <div>{numberWithCommas(vat||'0')}원</div>*/}
        {/*</div>*/}
        {/*{<div className={styles.item}>*/}
        {/*    <div>*/}
        {/*        /!*<div className={styles.circlarNumber}>2</div>*!/*/}
        {/*        /!*&nbsp;*!/*/}
        {/*        유지관리비*/}
        {/*    </div>*/}
        {/*    <div>{numberWithCommas(outcome||'0')}원</div>*/}
        {/*</div>}*/}

        {/*<div className={styles.item}>*/}
        {/*    <div>*/}
        {/*        /!*<div className={styles.circlarNumber}>3</div>*!/*/}
        {/*        /!*&nbsp;*!/*/}
        {/*        PG수수료 /!*<span>* 센서, CCTV, 보험료</span> *!/*/}
        {/*    </div>*/}
        {/*    <div>{numberWithCommas(pg||'0')}원</div>*/}
        {/*</div>*/}
        {/*{<div className={styles.item}>*/}
        {/*    <div>*/}
        {/*        수입 <span>{false ? '*'.concat(calRate * 100 || 0, '%가 원천징수된 금액') : ''}</span> /!*calRate 몰라서 숨김 처리 해둠 *!/*/}
        {/*    </div>*/}
        {/*    <div>{numberWithCommas(income||'0')}원</div>*/}
        {/*</div>}*/}

        {/*{partner_income? <div className={styles.item}>*/}
        {/*    <div>내 수익</div>*/}
        {/*    <div>{numberWithCommas(partner_income||'0')}원</div>*/}
        {/*</div>:null}*/}
        {/*<div className={styles.item}>*/}
        {/*    <div>입금 금액</div>*/}
        {/*    <div>{numberWithCommas(remittance||'0')}원</div>*/}
        {/*</div>*/}
        {/*<hr/>*/}
    </>
);
let parkinglotID;
const AdjustExpand = ({parkList, parkInfo,calDate,store, ...rest}) => {
    const [elementList, setElementList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const getParkInfo = async (parkID) => {
        try {
            setIsLoading(true);
            const {elements} = await API().adjust(
                store.user.token,
                calDate,
                'p', null, parkID);
            let tempElem = elementList
            tempElem[parkID] = elements
            setElementList(tempElem);
            return elements
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false);
        }
    };
    return <>
        <Receipt {...rest}/>
        {parkList && parkList.map((park, index) =>
            <Accordian key={index}
                       onClick={async () => {
                           parkinglotID = park.parkinglotSeqid;
                           getParkInfo(park.parkinglotSeqid);
                       }}
                       style={{marginTop: '15px', backgroundColor: colors.bg}}
                       end={<span
                           className={styles.accent}>{numberWithCommas(park.parkinglotRemittance || '0')}원</span>}
                       expandItem={<Receipt {...elementList[park.parkinglotSeqid]} />}>
                <div className={styles.category}>
                    <h4 className={styles.bold} style={{fontSize:'0.75rem'}}>
                        <ColorIcons name={'solo'} size={27}/>
                        {park.parkinglotName}
                    </h4>
                </div>
            </Accordian>
        )}
    </>
};
export default AdjustExpand;
