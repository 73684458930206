import Request from './base/Request';
import * as base64 from 'base-64';

class BenefitAdjustRequest extends Request {
    constructor(APIURL, header, searchMonth, devide, groupSeqid, parkinglotSeqid) {
        super(APIURL);

        if(!(['a','g', 'p'].includes(devide)))
            throw Error('Wrong devide');
        else if( devide==='p' && !parkinglotSeqid)
            throw Error('parking lot id missing');
        else if ( devide === 'g' && !groupSeqid)
            throw Error('group id missing');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...header
            },
            body: JSON.stringify({
                searchMonth, devide, groupSeqid, parkinglotSeqid
            })
        };

        this.request = this.request.bind(this);
    }
}

export default BenefitAdjustRequest;
