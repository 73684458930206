import Login from 'pages/Login'
import RegisterRoutes from "./RegisterRoutes";
import FindRoutes from "./FindRoutes"
import OfficerRoutes from "./OfficerRoutes";
import AssigneeRoutes from "./AssigneeRoutes";
import PartnerRoutes from './PartnerRoutes'
import AlbaRoutes from "./AlbaRoutes";
import IllegalRoutes from "./IllegalRoutes";
import CompanyRoutes from "./CompanyRoutes";
import PManagerRoute from "./PManagerRoutes";
import Progress from "../pages/Register/Progress";

const LoginRoutes = [{
    path: '/login',
    title: '로그인',
    component: Login,
    exact: true
},
    {
        path: '/progress',
        component: Progress,
        exact: true
    }]

const Routes = [
    ...PManagerRoute,
    ...CompanyRoutes,
    ...IllegalRoutes,
    ...AlbaRoutes,
    ...PartnerRoutes,
    ...AssigneeRoutes,
    ...OfficerRoutes,
    ...RegisterRoutes,
    ...FindRoutes,
    ...LoginRoutes,
];

export default Routes;
