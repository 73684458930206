import Request from './base/Request';
import * as base64 from 'base-64';

class IdCheckRequest extends Request {
    constructor(APIURL, userid) {
        super(APIURL);

        if (!userid)
            throw Error('No Id input');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({userid})
        };

        this.request = this.request.bind(this);
    }
}

export default IdCheckRequest;
