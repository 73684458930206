import React, {useState, useEffect} from 'react'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, TrailButton, Accordian, Toast, CheckButton} from "components";
import API from 'api'
import {numberWithCommas} from "utils";
import MonthPicker from "components/MonthPicker";
import useMonthSelector from "components/MonthPicker/hooks"
import DetailExpand from "./DetailExpand";
import SearchHeader from "../../components/SearchHeader";
import SearchModal from "../../components/SearchModal";
import ReportModal from "../../components/ReportModal";
import {useMount} from "../../../../hooks";
import { element } from 'prop-types';

const UseDetail = ({store, history}) => {
    const [calDate, handleDateButton] = useMonthSelector();
    const [elements, setElements] = useState({dailyCount:'',useCount: '', useAmount: '',lotNum:{contractLot:'',availLot:''}});
    const [groupDetails, setGroupDetails] = useState({});
    const [dailyInfoList, setDailyInfoList] = useState({});
    const [isLot, setIsLot] = useState(true);

    const [showToast, setShowToast] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);

    const [selected, setSelected] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isZeroButton, setIsZeroButton] = useState(false);
    async function getDailyInfo(date, id) {
        if (!dailyInfoList.date) {
            const {elements, code, message} = await API().useDetail(store.user.token, date, 'p', null, id)
            if (code === 200)
                setDailyInfoList({...dailyInfoList, [date]: elements.rElements})
        }
    }

    const getParkingInfo = async () => {
        try {
            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'p', null, selected.parkinglotSeqid);
            if (code === 200){
                setElements(elements);
            }else
                setShowToast(message);

        } catch (e) {

        } finally {

        }
    };

    const getGroupInfo = async () => {
        try {
            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'g', selected.groupSeqid);
            elements.gElements = elements.pElements;
            if (isZeroButton === false) {
                elements.gElements = elements.pElements.filter(item => (item.parkinglotAmount !== 0))
                setElements(elements);
            }else {
            if (code === 200){
                setElements(elements);
            } else
                setShowToast(message);
            }
        } catch (e) {

        } finally {

        }
    };

    const getAllInfo = async () => {
        try {
            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'a');
            if (isZeroButton === false) {
                elements.gElements = elements.gElements.filter(item => item.groupAmount !== 0);
                setElements(elements);
            }else {
                if (code === 200) {
                    setElements(elements);
                    elements.gElements.length > 0 ? '' : history.push('/Register/NoLot')
                } else {
                    setShowToast(message);
                }
            }
        }catch (e) {

        } finally {

        }
    };

    function handIsZeroButton() {
        if (selected) {
            onSelectSearch(selected)
        }else {
            getAllInfo()
        }
    }
    useEffect(() => {
        if ((selected || {}).parkinglotSeqid) {
            console.log("useEffect3");
            getParkingInfo();
        } else if ((selected || {}).groupSeqid) {
            console.log("useEffect2");
            getGroupInfo();
        } else {
            getAllInfo();
        }
    }, [calDate,isZeroButton]);


    const onSelectSearch = async searchSelected => {
        if (!searchSelected) {
            getAllInfo();
            setSelected({})
        } else {
            setSelected(searchSelected)
            if (searchSelected.parkinglotSeqid) {
                try {
                    const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'p', null, searchSelected.parkinglotSeqid);
                    if (code === 200){
                        setElements(elements);
                    }
                    else
                        setShowToast(message);

                } catch (e) {

                } finally {

                }
            } else if (searchSelected.groupSeqid) {
                try {
                    const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'g', searchSelected.groupSeqid);
                    elements.gElements = elements.pElements;
                    if (isZeroButton === false) {
                        elements.gElements = elements.pElements.filter(item => (item.parkinglotAmount !== 0))
                        setElements(elements);

                    }else {
                        if (code === 200)
                            setElements(elements);
                        else
                            setShowToast(message);
                    }
                } catch (e) {

                } finally {

                }
            }
        }
    };

    return <>
    <div className={styles.background}></div>
    <div className={styles.widthFill}>

        {/* ROW-00: 주차장 선택 및 검색 바 & 알림 아이콘 */}
        <SearchHeader
            setShowSearchModal={setShowSearchModal}
            setShowReportModal={setShowReportModal}
            store={store}
            selected={selected}
        />
        <section className={styles.usageStatistics}>
            <div className={styles.information}>
                <ColorIcons name={'logo-default'} size={70}/>
                <article className={styles.textRight}>
                    <h2 className={styles.bold}> {numberWithCommas(String(elements.dailyCount)) +'건'}</h2>
                    <p>오늘 예약건수</p>
                </article>
                <article className={styles.marginRight}>
                    <h2 className={styles.bold}>{numberWithCommas(String(elements.useCount)) +' 건'}</h2>
                    <p>이번달 총 이용건수</p>
                </article>
            </div>
            <div className={styles.badgeName}>
                <ColorIcons name={selected.groupName ? 'group' : selected.parkinglotName ? 'solo' : 'all'} size={32} style={{marginRight: '8px'}}/>
                <h2 className={styles.bold} style={{marginLeft:'10px'}}> {selected.parkinglotName || selected.groupName || '전체주차장'} </h2>
            </div>
        </section>
        <section className={styles[`status-header`]}>
        <div className={styles.MonthSelector}>
            <button onClick={e => handleDateButton(e, true)}>
                <Icons name={'link-left'} size={11}/>
            </button>
            <h3 className={styles.bold}> {calDate.slice(0, 4)}년 {calDate.slice(-2)}월 이용현황</h3>
            <button onClick={e => handleDateButton(e, false)}>
                <Icons name={'link-right'} size={11}/>
            </button>
        </div>
        <div className={styles['monthly-using-status-wrapper']}>
            <h4 className={styles.bold}> {calDate.slice(0, 4)}년 {calDate.slice(-2)}월 이용현황 </h4>
            <div className={styles[`mt-10`]}>
            <article>
                <h3 className={`${styles.bold} ${styles.gray}`}>총 개소수</h3>
                <h2 className={styles.bold}>{numberWithCommas(String(elements.lotNum.contractLot)) + ' 면'}</h2>
            </article>
            </div>
            <div className={`${styles.grid} ${styles[`mt-10`]}`}>
            <article>
                <h3 className={`${styles.bold} ${styles.gray}`}>총 이용건수</h3>
                <h2 className={styles.bold}> {numberWithCommas(String(elements.useCount)) + ' 건'}</h2>
            </article>
            <article>
                <h3 className={`${styles.bold} ${styles.gray}`}>총 매출</h3>
                <h2 className={styles.bold}>  {numberWithCommas(String(elements.useAmount)) + ' 원'}</h2>
            </article>
            </div>
        </div>
            <div className={styles.warning}>
                <Icons name={'warning-icon'} size={20}/>
                <h4 className={styles.ml10} style={{fontSize: '0.75rem'}}>"앱 결제 위주의 금액이며,<br/>
                    실제 정산 시 사이트 별로 정산 금액이 다를수 있습니다.
                </h4>
            </div>
        </section>
                {/*<div className={styles.parkingLot} >*/}
                {/*    {*/}
                {/*        (!selected || Object.keys(selected).length === 0)? <><ColorIcons name={'all'} size={27} style={{marginRight: '8px'}}/>전체주차장</>*/}
                {/*            : selected.groupSeqid ?*/}
                {/*            <><ColorIcons name={'group'} size={27} style={{marginRight: '8px'}}/>{selected.groupName}</>*/}
                {/*            : <><ColorIcons name={'solo'} size={27} style={{marginRight: '8px'}}/>{selected.parkinglotName}</>*/}
                {/*    }*/}
                {/*</div>*/}
                <section>

                {selected.parkinglotSeqid?null:
                        <button onClick={()=>setIsZeroButton(!isZeroButton)}
                                     className={`filter-button`}
                                     style={{display: 'flex',
                                         alignItems: 'center',
                                         justifyContent: 'center',
                                         backgroundColor: 'transparent',
                                         marginLeft:'auto',
                                         marginRight: '15px'
                                        }}>
                            {isZeroButton ? <Icons name={'dropdown-open'} size={12}/> : <Icons name={'dropdown-close'} size={12}/>}
                           <span className={styles.p}>모든 주차장 보기</span>
                        </button>

                }
                </section>

            {/*<MonthPicker calDate={calDate} handleDateButton={handleDateButton}/>*/}

                {/*<div className={styles.ParkDetailContainer}*/}
                {/*     style={{backgroundColor: colors.white, borderRadius: '12px', marginBottom: '15px'}}>*/}
                {/*    <div style={{*/}
                {/*        paddingLeft: 0,*/}
                {/*        marginLeft: "8px",*/}
                {/*        fontSize: '15px',*/}
                {/*        color: colors['place-holder'],*/}
                {/*        height: 'auto'*/}
                {/*    }}*/}
                {/*         color={colors['place-holder']}>*/}
                {/*        {calDate} 이용현황*/}
                {/*    </div>*/}
                {/*    <div className={styles.status}>*/}
                {/*        <div className={styles.value}>*/}
                {/*            {numberWithCommas(String(elements.lotNum.contractLot)) + ' 면'}*/}
                {/*            <div className={styles.when}>*/}
                {/*                계약면수*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.value}>*/}
                {/*            {numberWithCommas(String(elements.lotNum.availLot)) + ' 면'}*/}
                {/*            <div className={styles.when}>*/}
                {/*                운영면수*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className={styles.status}>*/}
                {/*        <div className={styles.value}>*/}
                {/*            {numberWithCommas(String(elements.useCount)) + ' 건'}*/}
                {/*            <div className={styles.when}>*/}
                {/*                이용건수*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className={styles.value}>*/}
                {/*            {numberWithCommas(String(elements.useAmount)) + ' 원'}*/}
                {/*            <div className={styles.when}>*/}
                {/*                매출*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    {elements.dElements && elements.dElements.map(park => {*/}
                {/*        return <Accordian*/}
                {/*            style={{backgroundColor: colors.bg, borderRadius: 0}}*/}
                {/*            key={park.parkinglotSeqid}*/}
                {/*            end={<div className={styles.outcome}>매출<span*/}
                {/*                className={styles.price}>{numberWithCommas(park.parkingDateAmount)}</span></div>}*/}
                {/*            onClick={() => {*/}
                {/*                getDailyInfo(park.parkingDate, selected.parkinglotSeqid);*/}
                {/*            }}*/}
                {/*            expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{*/}
                {/*                dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket) => {*/}
                {/*                    return <div className={styles.ticket}>*/}
                {/*                        <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>*/}
                {/*                        <div>*/}
                {/*                            <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                })*/}
                {/*            }</div>}>*/}
                {/*            {park.parkingDate}*/}
                {/*        </Accordian>*/}
                {/*    })}*/}
                {/*</div>*/}

                {elements.gElements && elements.gElements.map((group, index) => {
                    return <div style={{margin:'15px'}}>
                    <Accordian key={index} style={{padding:'10px'}}
                            end={
                                <h3 className={styles.bold} style={{color: '#007BFF',fontSize: '1rem'}}>  {numberWithCommas(group.parkinglotAmount || group.groupAmount || 0)}원</h3>
                            }
                            expandItem={<DetailExpand store={store} date={calDate}
                              group={groupDetails[group.groupSeqid] || {...groupDetails[group.parkinglotSeqid], ...group} || {
                                  useCount: '',
                                  useAmount: ''
                              }}/>}
                            onClick={async () => {
                                try {
                                    if (group.groupSeqid) {
                                        const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'g', group.groupSeqid)
                                       elements.pElements.map(item=> {
                                            item.parkinglotName = item.parkinglotName.replace(/\[[^\]]*\]/g, "")
                                            item.parkinglotName = item.parkinglotName.replace(/\([^)]+\)/, "")
                                            }
                                        )
                                        if (isZeroButton === true){
                                            setGroupDetails({...groupDetails, [group.groupSeqid]: elements});
                                        }else {
                                            elements.pElements=elements.pElements.filter(item => item.parkinglotAmount!==0);
                                            setGroupDetails({...groupDetails, [group.groupSeqid]: elements});
                                        }
                                    }else if (group.parkinglotSeqid) {
                                        console.log("Test",group);
                                        const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'p', null, group.parkinglotSeqid)
                                        setGroupDetails({...groupDetails, [group.parkinglotSeqid]: elements})
                                    }

                                } catch (e) {

                                } finally {

                                }
                            }}>
                            <div style={{display: 'flex', alignItems: 'center',fontWeight:'600'}}>
                                <h4 style={{marginLeft: '5px'}}>
                                {group.groupName ?
                                    <><ColorIcons name={'group'} size={15}/>&nbsp;{group.groupName}</>
                                    : <><ColorIcons name={'solo'} size={15}/>&nbsp;{group.parkinglotName}</>}
                                </h4>
                            </div>
                        </Accordian>
                    </div>
                })}


        </div>

        <SearchModal isOpenModal={showSearchModal}
                     store={store}
                     setSeqid={onSelectSearch}
                     toggleModal={() => setShowSearchModal(!showSearchModal)}/>
        <ReportModal store={store}
                     isOpenModal={showReportModal}
                     toggleModal={() => setShowReportModal(!showReportModal)}/>
        <Toast onClose={() => setShowToast(false)} open={showToast}>
            {showToast}
        </Toast>

        </>
};

export default UseDetail;
