import React, {useEffect, useState} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import Button from 'components/Button'
import Checkbutton from "components/Checkbutton"
import API from 'api';
import parse from 'html-react-parser'

import styles from './style.module.scss'
import Icons from "components/Icons";
import colors from "styles/_colors.scss";
import query from "query-string";

const Agreement = ({history, location}) => {
    const [agreeArray, setAgreeArray] = useState([false,]);
    const [agreeTitleArray, setAgreeTitleArray] = useState([]);
    const [agreeNecessary, setAgreeNecessary] = useState([]);
    const [agreeDescription, setAgreeDescription] = useState({description: '', title: ''});

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    function toggleModal() {
        setIsOpenModal(!isOpenModal)
    }

    function handleAgree(index) {
        const tempArray = [...agreeArray];
        tempArray[index] = !agreeArray[index];
        setAgreeArray(tempArray);
    }

    async function setDescription(termSeqid) {
        try {
            setIsLoading(true)
            const {code, description} = await API().termList("2", String(termSeqid + 1));
            if (code === 200) {
                let tmp = {description: description, title: agreeTitleArray[termSeqid]};
                setAgreeDescription(() => tmp);
            } else {
                console.error('fail to get agreement')
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const getAllTitle = async () => {
            try {
                setIsLoading(true)
                const {code, title, message, elements} = await API().termList("1");
                // console.log(elements);
                if (code === 200) {
                    elements.map(element => {
                        setAgreeTitleArray((agreeTitleArray) => [...agreeTitleArray, element.termTitle]);
                        setAgreeNecessary((agreeNecessary) => [...agreeNecessary, element.necessary]);
                        setAgreeArray((agreeArray) => [false, ...agreeArray]);
                    })
                } else {
                    console.log(code, title, message)
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };
        getAllTitle();
    }, []);


    return <div className={styles.AgreementContainer}>
        <div className={styles.titleHeading}>
            <div>
                <button className={styles.backbutton} onClick={() => history.goBack()}>
                    <Icons name={'back'} size={24} color={colors.text}/>
                </button>
            </div>

            <h1>
                약관에 동의해주세요.
            </h1>
            <div>
                <Checkbutton
                    selected={
                        agreeArray.reduce((previousValue, currentValue) => previousValue && currentValue, true)}
                    onChange={() => {
                        agreeArray.reduce((previousValue, currentValue) => previousValue && currentValue, true)
                            ? setAgreeArray(agreeArray.map(() => false))
                            : setAgreeArray(agreeArray.map(() => true))
                    }}
                    style={{width: '18px', height: '18px'}}
                    className={styles.AgreementItem}
                    >
                    <h2>모두 동의합니다.</h2>
                </Checkbutton>
            </div>
            <hr/>
            {
                agreeTitleArray.map((agree, index) =>
                    <Checkbutton
                        key={index}
                        selected={agreeArray[index] || false}
                        onChange={() => {
                        }}
                        onClick={async () => {
                            await setDescription(index);
                            if (agreeArray[index] === false) toggleModal(index);
                            handleAgree(index);
                        }}
                        style={{width: '18px', height: '18px'}}
                        className={styles.AgreementItem}
                        >

                        {agreeNecessary[index] === 'Y' ?
                            '[필수] ' : '[선택] '
                        }
                        {agree}
                    </Checkbutton>)
            }
        </div>
        <Button variant="contained"
                color="primary"
                size={'large'}
                onClick={() => {
                    const {districtLocation, districtSeqIdLocation} = query.parse(location.search);

                    let i = agreeTitleArray.indexOf("개인정보 마케팅 활용 동의서");
                    var m = false;
                    if ( i > -1) {
                        m = agreeArray[i];
                    }

                    if (districtLocation && districtSeqIdLocation) {
                        history.push({pathname: '/register/info/ASSIGNEE?districtLocation=' + districtLocation + '&districtSeqIdLocation=' + districtSeqIdLocation, state: {
                            marketingAgreement: m
                        }});
                        // history.push({pathname: '/register/info/ASSIGNEE', state: {
                        //     marketingAgreement: m
                        // }});
                    }
                    else { 
                        history.push({pathname: '/register/userKind', state: {
                            marketingAgreement: m
                        }})
                    }
                }}
                style={{
                    width: '93.2%'
                }}
                className={styles.button}
                disabled={
                    !(agreeNecessary.map((isMandatory, index) => {
                        if (isMandatory === 'Y') {
                            return agreeArray[index]
                        } else return true;
                    }).reduce((prev, cur) => cur && prev, true))
                }>
            다음
        </Button>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={isOpenModal}>
                <div className={styles.ModalContentContainer}>
                    <h1>
                        <button onClick={toggleModal}><Icons size={17} name={'icon-close'}/></button>
                        {agreeDescription.title}
                    </h1>
                    <div className={styles.detail}>
                        {parse(agreeDescription.description)}
                    </div>
                </div>
            </Fade>
        </Modal>
    </div>;
};

export default Agreement;
