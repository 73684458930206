import React from 'react';
import PropTypes from 'prop-types';
import MUITextField from '@material-ui/core/TextField';
import {InputAdornment, IconButton} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles';
import Icons from "../Icons";
import TextField from "../TextField";

// We can inject some CSS into the DOM.
const styles = {
    root: {
        display: 'flex',
        width: '100%',
        margin: '5px ,0',
        flexdirection: 'row',
        alignitems: 'center',
        justifycontent: 'center',
        padding: '10px',
        borderBottom: '1px solid #999999',
    },
};

function TextFieldLogin(props) {
    const {children, className, type, inputMode, autoComplete, tabIndex, inin, onChange, value, ...other} = props;

    return (

        <MUITextField  className={className} style={styles.root} {...other}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      onChange={onChange}
                      value={value}
                      InputProps={{
                        autoComplete:autoComplete,
                        type:type,
                        inputMode:inputMode,
                        tabIndex:tabIndex,
                        endAdornment:
                            value !== '' ?
                                <InputAdornment position="end">
                                    <IconButton onClick={() => onChange({target: {value: ''}})}>
                                        <div style={{
                                            borderRadius: '100%',
                                            backgroundColor: '#bac1cf',
                                            width: '18px',
                                            height: '18px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <Icons color={'#fff'} name={'icon-close'} size={10}/>
                                        </div>
                                    </IconButton>
                                </InputAdornment> : null,
                          ...inin,
                      }}>
            {children}
        </MUITextField >
    );
}

TextFieldLogin.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default withStyles(styles)(TextFieldLogin);
