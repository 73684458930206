import {CircularProgress, Modal} from "@material-ui/core";
import React from "react";

const Progress = ({isLoading}) => {
    return <Modal
        open={isLoading}
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        <CircularProgress color="secondary"/>
    </Modal>
}

export default Progress;
