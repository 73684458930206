import React, {useState} from 'react'
import {InputAdornment, makeStyles, Modal, Backdrop, Fade} from '@material-ui/core'

import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import styles from './style.module.scss'
import Icons from "components/Icons";
import colors from "styles/_colors.scss";

import TextField from "components/TextField";
import Button from "components/Button";
import SelectBank from "../SelectBank";
import SelectManager from "./SelectManagerModal";
import API from "api";
import {Progress, Toast} from "../../../components";
import {useMount} from "../../../hooks";


function renderTimes(assigneeType) {
    if ('a' === assigneeType)
        return '0시부터 24시';
    else if ('n' === assigneeType)
        return '21시부터 06시';
    else if ('d' === assigneeType)
        return '06시부터 18시'
}

const AssigneeFields = ({onChange, carNumber}) => {

    const [carNum, setCarNum] = useState(carNumber);
    const [assigneeType, setAssigneeType] = useState('');

    return <div>
        <div className={styles.CardContainer}>
            <TextField placeholder={'예시: 서울123가5678'} 
                className={styles.CarField}
                value={carNum}
                onChange={e => {
                    setCarNum(e.target.value)
                }}
                readOnly/>
        </div>

        <Alert variant="success" className={styles.noticeWarning}>
            <ul className="fa-ul">
                <li className={styles.keyword}>
                <i className="fa fa-info-circle fa-lg fa-li" aria-hidden="true"></i>
               <span className={styles.underline}>주의</span> 차량번호를 다시 한번 확인 해 주세요. 잘못 입력 하신 경우, 거주자 우선주차 배정자로 등록되지 않을 수 있습니다.</li>
            </ul>
        </Alert>
    </div>

}

const RegisterAdditionalInfo = ({history, match}) => {
    const props = history.location;

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showSelectBank, setShowSelectBank] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [showToast, setShowToast] = useState(false);

    const [showArea, setShowArea] = useState(false);

    const [areaNum, setAreaNum] = useState('');
    const [slotNum, setSlotNum] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [fee, setFee] = useState('');

    const [bank, setBank] = useState('');
    const [manager, setManager] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [birthday, setBirthday] = useState('');

    const [carNumber, setCarNumber] = useState(props.carNumber);
    const [assigneeInfo, setAssigneeInfo] = useState({
        carNum: carNumber
    });

    const [userType, setUserType] = useState('');

    useMount(() => {
        const strUserType = match.params.userType.split('?')[0];
        setUserType(strUserType);
    });

    async function handleDone() {
        try {
            if (userType === "ASSIGNEE") {
                setIsLoading(true)
                const {code, message} = await API().registerAssignee(
                    props.phoneNumber,
                    props.userId,
                    props.password,
                    props.name,
                    props.carNumber,
                    props.district,
                    assigneeInfo.assigneeType ? assigneeInfo.assigneeType : 'o',
                    assigneeInfo.areaNum,
                    assigneeInfo.slotNum,
                    assigneeInfo.startDate,
                    assigneeInfo.endDate,
                    assigneeInfo.fee ? assigneeInfo.fee : '0',
                    bank,
                    accountNumber,
                    accountHolder,
                    birthday,
                    manager ? manager : '모름',
                    props.marketingAgreement ? 'Y' : 'N'
                );

                if (code === 200) {
                    history.replace({
                        pathname: '/register/done',
                        userId: props.userId,
                        name: props.name,
                        carNumber: props.carNumber,
                        phoneNumber: props.phoneNumber,
                        district: props.district,
                        bank,
                        accountNumber,
                        accountHolder,
                        birthday: birthday.replace('-', ''),
                        managerName: manager,
                        userType: userType,
                        assigneeType : assigneeInfo.assigneeType,
                        areaNum : assigneeInfo.areaNum,
                        slotNum : assigneeInfo.slotNum,
                        startDate :assigneeInfo.startDate,
                        endDate : assigneeInfo.endDate,
                        fee : assigneeInfo.fee,
                        ...assigneeInfo
                    });
                } else {
                    setShowToast(message);
                }
            } else {
                const {code, message} = await API().register(
                    props.phoneNumber,
                    props.userId,
                    props.password,
                    props.name,
                    props.district,
                    bank,
                    accountNumber, accountHolder,
                    birthday.replace(/-/g, ''),
                    manager ? manager : '모름',
                    userType);

                if (code === 200) {
                    history.replace({
                        pathname: '/register/done',
                        userId: props.userId,
                        name: props.name,
                        phoneNumber: props.phoneNumber,
                        district: props.district || '',
                        bank,
                        accountHolder,
                        accountNumber,
                        birthday,
                        manager,
                        userType: userType
                    });
                } else {
                    setShowToast(message);
                }
            }
        } catch (e) {
            console.error(e);
            // setShowToast(e);
        } finally {
            setIsLoading(false)
        }
    };

    console.log("showArea", showArea)

    return <>
        <div className={styles.InfoContainer}>
            <h1>
                <button onClick={()=>history.goBack()}>
                    <Icons name={'back'} size={24} color={colors.text}/>
                </button>
                추가 정보 입력
            </h1>

            {userType === 'ASSIGNEE' ? <AssigneeFields carNumber={props.carNumber} onChange={(assignee) => {
                console.log(assignee);
                setAssigneeInfo(assignee)
            }}/> : ''}

                <div className={styles.card}>
                    <TextField
                        variant="outlined"
                        label="구획번호 (선택)"
                        size={'small'}
                        value={areaNum}
                        onChange={e => {
                            setAreaNum(e.target.value)
                        }}/>
                </div>


                <div className={styles.card}>
                    <TextField variant="outlined" label="배정시작일 (선택)" size={'small'} type={'date'}
                            inin={null}
                            value={startDate}
                            onChange={e => setStartDate(e.target.value)}/>
                </div>
                <div className={styles.card}>
                    <TextField variant="outlined" label="배정종료일 (선택)" size={'small'} type={'date'}
                            inin={null}
                            value={endDate}
                            onChange={e => setEndDate(e.target.value)}/>
                </div>


                <div className={styles.card}>
                    <TextField
                        variant="outlined"
                        label="배정료 (선택)"
                        size={'small'}
                        value={fee}
                        onChange={e => {
                            setFee(e.target.value)
                        }}/>
                </div>

                <div className={styles.card}>
                {userType === 'ASSIGNEE' ?
                    <TextField
                            variant="outlined" label={bank ? '' : "수익금 입금 은행"} size={'small'}
                            onClick={() => setShowSelectBank(true)}
                            value={bank}
                            inin={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Icons color={colors.text} name={'link-right'} size={15}/>
                                    </InputAdornment>
                            }}
                            readOnly={true}
                        />
                :
                    <TextField
                            variant="outlined" label={bank ? '' : "수익금 입금 은행"} size={'small'}
                            value={bank}
                            inin={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Icons color={colors.text} name={'link-right'} size={15}/>
                                    </InputAdornment>
                            }}
                            readOnly={true}
                    />}

                </div>


                <div className={styles.card}>
                {userType === 'ASSIGNEE' ?
                    <TextField variant="outlined"
                            label="수익금 입금 계좌번호"
                            size={'small'}
                            value={accountNumber}
                            onChange={e => setAccountNumber(e.target.value)}/>
                :
                    <TextField variant="outlined"
                            label="수익금 입금 계좌번호"
                            size={'small'}
                            value={accountNumber}
                            readOnly={true}/>

                }
                </div>
                <div className={styles.card}>
                {userType === 'ASSIGNEE' ?
                    <TextField variant="outlined"
                            label="수익금 입금 계좌 예금주"
                            size={'small'}
                            value={accountHolder}
                            onChange={e => setAccountHolder(e.target.value)}/>
                :
                    <TextField variant="outlined"
                                label="수익금 입금 계좌 예금주"
                                size={'small'}
                                value={accountHolder}
                                readOnly={true}/>
                }
                </div>
            <button className={styles.card} onClick={()=> { setShowArea(!showArea);}}>
                <Icons name={'link-right'} size={11}/> 추가 정보
            </button>
            <div style={{display: showArea ? 'block' : 'none' }}>
                
                <div className={styles.card}>
                    <TextField
                        variant="outlined"
                        label="구간번호 (선택)"
                        size={'small'}
                        value={slotNum}
                        onChange={e => {
                            setSlotNum(e.target.value)
                        }}/>
                </div>

                <div className={styles.card}>
                    <TextField variant="outlined" label={manager ? '' : "사업 담당자 선택자"} size={'small'}
                            onClick={() => setIsOpenModal(true)} readOnly
                            value={manager ? manager : '모름'}
                            inin={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <Icons color={colors.text} name={'link-right'} size={15}/>
                                    </InputAdornment>
                            }}/>
                </div>
                <div className={styles.card}>
                    <TextField variant="outlined" label="생년월일" size={'small'} type={'date'}
                            inin={null}
                            onChange={e => setBirthday(e.target.value)}
                            value={birthday}/>
                </div>
            </div>


            <Button variant="contained"
                    color="primary"
                    onClick={async () => {
                        await handleDone();
                    }}
                    disabled={
                        userType === 'ASSIGNEE' && !assigneeInfo.carNum
                    }
                    size={'large'}>
                다음
            </Button>

        </div>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={showSelectBank}
            onClose={() => setShowSelectBank(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showSelectBank}>
                <div className={styles.ModalContentContainer}>
                    <SelectBank
                        onSelect={(val) => {
                            setBank(val.bankName);
                            setShowSelectBank(false);
                        }}
                        onClose={() => setShowSelectBank(false)}
                    />
                </div>
            </Fade>
        </Modal>

        <SelectManager
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            selected={manager}
            onSelect={manager => {
                setManager(manager);
                setIsOpenModal(false);
            }}/>
        <Progress isLoading={isLoading}/>
        <Toast open={showToast} onClose={() => setShowToast}>
            {showToast}
        </Toast>
    </>
};

export default RegisterAdditionalInfo;
