import React from 'react';
import styles from './style.module.scss';

const Expand = ({carNum, startTime, endTime, fee, product})=> {

    return <div className={styles.ExpandContainer}>
        <div className={styles.info}>
            <div>{startTime||''} ~ {endTime||''}</div>
            <div>{carNum||''}</div>
        </div>
        <div className={styles.category}>
            {product || ''}
        </div>
    </div>
}

export default Expand;
