import React, {useState, useEffect} from "react";
import {Backdrop, Fade, Modal} from "@material-ui/core";

import API from 'api'
import styles from "./style.module.scss";
import {Icons} from "components";
import colors from "styles/_colors.scss";

import classnames from 'classnames/bind'

const cx = classnames.bind(styles);

const SelectManager = ({isOpenModal, setIsOpenModal, onSelect, selected}) => {
    const [managerList, setManagerList] = useState([]);

    useEffect(() => {
        API().managerList().then(({elements}) => {
            setManagerList(elements)
        })
    }, []);

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modalContainer}
        open={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}>
        <Fade in={isOpenModal}>
            <div className={styles.ModalContentContainer}>

                <div className={styles.header}>
                    사업팀 담당자를 선택해주세요
                    <button onClick={() => setIsOpenModal(false)}><Icons name={'icon-close'}/></button>
                </div>
                {managerList.map(({managerName}) =>
                    (<button key={managerName}
                             className={cx(styles.manager, {selected: selected === managerName})}
                             onClick={() => onSelect(managerName)}>
                        {managerName} {selected === managerName ?
                        <Icons style={{marginLeft: '9px'}} name={'check-sh'} size={25}
                               color={colors.accent}/> : ''}
                    </button>))}
                <button className={styles.manager} onClick={() => onSelect('모름')}>모름</button>
            </div>
        </Fade>
    </Modal>
}

export default SelectManager;
