import Request from './base/Request';
import * as base64 from 'base-64';

class RegisterRequest extends Request {
    constructor(APIURL, body) {
        super(APIURL);

        // if (!body.phoneNumber || !body.userid || !body.password || !body.name || !body.district || !body.managerName || !body.userType)
        //     throw Error('Missing parameters');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };

        this.request = this.request.bind(this);
    }
}

export default RegisterRequest;
