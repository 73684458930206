import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom'
import styles from "./style.module.scss";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import ColorIcons from "components/ColorIcons";

export default withRouter(({location, history}) => {
    const [path, setPath] = useState('0');

    useEffect(()=>{
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    return <BottomNavigation
        value={path}
        onChange={(event, newValue) => {
            history.push('/company/' + newValue);
        }}
        className={styles.bottomNav}
        showLabels>
        <BottomNavigationAction
            value={'usestatus/usedetail'}
            label={<div className={styles.label}>이용현황</div>}
            icon={<ColorIcons name={'title-uselist'} size={19}/>}
        />
        <BottomNavigationAction
            value={'benefitadjust'}
            label={<div className={styles.label}>수익정산</div>}
            icon={<ColorIcons name={'title-reportlist'} size={19}/>}
        />
        <BottomNavigationAction
            value={'setting'}
            label={<div className={styles.label}>설정</div>}
            icon={<ColorIcons name={'title-setting'} size={19}/>}
        />
    </BottomNavigation>
});
