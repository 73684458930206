import React from 'react';
import PropTypes from 'prop-types';
import MUIButton from '@material-ui/core/Button';

// We can inject some CSS into the DOM.

function Button(props) {
    const {children, style, ...other} = props;
    return (
        <MUIButton
            {...other}
            style={{
                padding: '5px 0',
                borderRadius:'5px',
                width: '100%',
                    ...style
            }}>
            {children || 'class names'}
        </MUIButton>
    );
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default Button;
