import React from 'react';
import {Snackbar, Slide} from '@material-ui/core';
import Icons from "../Icons";

import styles from './style.module.scss'

const Toast = ({children, open, onClose, actionICon}) => (
    <Snackbar
        open={open? true : false}
        onClose={onClose ? onClose : () => {
        }}
        autoHideDuration={3000}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <div className={styles.ToastContainer}>
            {children}
            <Icons name={actionICon || 'confirm'} color={'#fff'}/>
        </div>
    </Snackbar>
);

export default Toast
