import React, {useEffect, useState} from "react";
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {Button, ColorIcons, Icons} from "components";
import colors from "styles/_colors.scss";
import styles from "./style.module.scss";
import ActionInfo from "./ActionInfo";
import {useMount} from "hooks";


const ActionChangeModal = ({isOpenModal, toggleModal, onDelete, action, onReport, onRegister, onRequestPay}) => {
    const [actionFlag, setActionFlag] = useState(0);
    const [isReport, setIsReport] = useState(false);
    const [isRegister, setIsRegister] = useState(false);
    const [isRequestPay, setIsRequestPay] = useState(false);

    // useEffect(() => {
    //     if(actionFlag == 0){
    //         setIsReport(true);
    //         setIsRegister(false);
    //         setIsRequestPay(false);
    //     } else if(actionFlag == 4){
    //         setIsReport(false);
    //         setIsRegister(true);
    //         setIsRequestPay(false);
    //     } else if(actionFlag == 5){
    //         setIsReport(false);
    //         setIsRegister(false);
    //         setIsRequestPay(true);
    //     } else {
    //         setIsReport(false);
    //         setIsRegister(false);
    //         setIsRequestPay(false);
    //     }    
    // }, [actionFlag]);

    // useEffect(() => {
    //     if (action) {
    //         setActionFlag(action.status)
    //     }
    // }, [action]);

    function chnageFlag(status){
        if(status === 0){
            setActionFlag(0)
            setIsReport(true);
            setIsRegister(false);
            setIsRequestPay(false);
        } else if(status === 4){
            setActionFlag(4)
            setIsReport(false);
            setIsRegister(true);
            setIsRequestPay(false);
        } else if(status === 5){
            setActionFlag(5)
            setIsReport(false);
            setIsRegister(false);
            setIsRequestPay(true);
        } else {
            setIsReport(false);
            setIsRegister(false);
            setIsRequestPay(false);
        }  
    }

    useEffect(() => {
        if (action) {
            console.log(action)
            setActionFlag(action.status)
            if(action.status === 0){
                setIsReport(true);
                setIsRegister(false);
                setIsRequestPay(false);
            } else if(action.status === 4){
                setIsReport(false);
                setIsRegister(true);
                setIsRequestPay(false);
            } else if(action.status === 5){
                setIsReport(false);
                setIsRegister(false);
                setIsRequestPay(true);
            } else {
                setIsReport(false);
                setIsRegister(false);
                setIsRequestPay(false);
            }  
        }
    }, [action]);
    
    let a
    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modalContainer}
        open={isOpenModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}>
        <Fade in={isOpenModal}>
            <div className={styles.ModalContentContainer}>
                <div className={styles.header}>
                    활동내역 수정
                    <button onClick={toggleModal}><Icons name={'icon-close'}/></button>
                </div>
                <div className={styles.actionButtons}>
                    {isReport? <button className={styles.report} onClick={a}> 단속요청 </button> : <button onClick={() =>chnageFlag(0)}> 단속요청 </button>}
                    {isRegister? <button className={styles.report} onClick={a}>가입유도</button> : <button onClick={() => chnageFlag(4)}>가입유도</button>}
                    {isRequestPay? <button className={styles.report} onClick={a}>결제유도</button> : <button onClick={() => chnageFlag(5)}>결제유도</button>}  
                </div>

                <div style={{margin: '24px 0 30px'}}>
                    <ActionInfo/>
                </div>

                <div className={styles.ButtonContainer}>
                    <Button variant={'outlined'} color={'primary'} onClick={onDelete}>
                        삭제
                    </Button>
                    <Button variant={'contained'} color={'primary'} onClick = {actionFlag === 0 ? onReport : actionFlag === 4 ? onRegister : actionFlag === 5 ? onRequestPay : ''}>
                        수정완료
                    </Button>
                </div>
            </div>
        </Fade>
    </Modal>
};

export default ActionChangeModal;
