import React, {useState, useEffect} from 'react'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, TrailButton, Accordian, Toast, CheckButton} from "components";
import API from 'api'
import {numberWithCommas} from "utils";
import MonthPicker from "components/MonthPicker";
import useMonthSelector from "components/MonthPicker/hooks"
import DetailExpand from "./DetailExpand";

const UseDetail = ({store, history}) => {
    const [calDate, handleDateButton] = useMonthSelector();
    const [elements, setElements] = useState({useCount: '', useAmount: ''});
    const [groupDetails, setGroupDetails] = useState({});
    const [dailyInfoList, setDailyInfoList] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [isZeroButton, setIsZeroButton] = useState(false);
    const selected = history.location.selected;

    const getParkingInfo = async () => {
        try {
            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'p', null, selected.parkinglotSeqid);
            if (code === 200)
                setElements(elements);
            else
                setShowToast(message);

        } catch (e) {

        } finally {

        }
    };

    const getGroupInfo = async () => {
        try {
            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'g', selected.groupSeqid);
            elements.gElements = elements.pElements;
            if (code === 200)
                setElements(elements);
            else
                setShowToast(message);
        } catch (e) {

        } finally {

        }
    };

    function handIsZeroButton() {
        getAllInfo()
    }
    const getAllInfo = async () => {
        try {
            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'a');
            if (isZeroButton === false) {
                elements.gElements = elements.gElements.filter(item => item.groupAmount !== 0);
            }
            if (code === 200)
                setElements(elements);
            else {
                setShowToast(message);
            }
        } catch (e) {

        } finally {

        }
    };

    useEffect(() => {
        if ((selected || {}).parkinglotSeqid) {
            getParkingInfo();
        } else if ((selected || {}).groupSeqid) {
            getGroupInfo();
        } else {
            getAllInfo();
        }
    }, [calDate,isZeroButton]);

    async function getDailyInfo(date, id) {
        if (!dailyInfoList.date) {
            const {elements, code, message} = await API().useDetail(store.user.token, date, 'p', null, id)
            if (code === 200)
                setDailyInfoList({...dailyInfoList, [date]: elements.rElements})
        }
    }
    return <>
        <div className={styles.ComponentMainContainer}>
            <div className={styles.Header}>
                <button onClick={() => {
                    history.goBack()
                }}>
                    <Icons name={'back'} size={24}/>
                </button>
                이용현황
            </div>
            <div className={styles.allParkingLot}>
                <div className={styles.parkingLot} >
                {
                    (!selected || Object.keys(selected).length === 0)? <><ColorIcons name={'all'} size={27} style={{marginRight: '8px'}}/>전체주차장</>
                    : selected.groupSeqid ?
                        <><ColorIcons name={'group'} size={27} style={{marginRight: '8px'}}/>{selected.groupName}</>
                        : <><ColorIcons name={'solo'} size={27} style={{marginRight: '8px'}}/>{selected.parkinglotName}</>
                }
                </div>
                <div className={styles.zeroButton}>
                    <CheckButton
                        selected={isZeroButton}
                        onChange={handIsZeroButton}
                        onClick={()=>setIsZeroButton(!isZeroButton)}
                    >
                        모두보기
                    </CheckButton>
                </div>
            </div>
            <MonthPicker calDate={calDate} handleDateButton={handleDateButton}/>

            <div className={styles.dashboard}>
                <div className={styles.ParkDetailContainer}
                     style={{backgroundColor: colors.white, borderRadius: '12px', marginBottom: '15px'}}>
                    <div style={{
                        paddingLeft: 0,
                        marginLeft: "8px",
                        fontSize: '15px',
                        color: colors['place-holder'],
                        height: 'auto'
                    }}
                         color={colors['place-holder']}>
                        {calDate} 이용현황
                    </div>
                    <div className={styles.status}>
                        <div className={styles.value}>
                            {numberWithCommas(String(elements.useCount)|| '0') + ' 건'}
                            <div className={styles.when}>
                                이용건수
                            </div>
                        </div>
                        <div className={styles.value}>
                            {numberWithCommas(String(elements.useAmount)|| '0') + ' 원'}
                            <div className={styles.when}>
                                매출
                            </div>
                        </div>
                    </div>

                    {elements.dElements && elements.dElements.map(park => {
                        return <Accordian
                            style={{backgroundColor: colors.bg, borderRadius: 0}}
                            key={park.parkinglotSeqid}
                            end={<div className={styles.outcome}><span
                                className={styles.price}>{numberWithCommas(park.parkingDateAmount)}</span></div>}
                            onClick={() => {
                                getDailyInfo(park.parkingDate, selected.parkinglotSeqid);
                            }}
                            expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{
                                dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket) => {
                                    return <div className={styles.ticket}>
                                        <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>
                                        <div>
                                            <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}
                                        </div>
                                    </div>
                                })
                            }</div>}>
                            {park.parkingDate}
                        </Accordian>
                    })}
                </div>

                {elements.gElements && elements.gElements.map((group, index) => {
                    return <div key={index} style={{marginBottom: '15px'}}>
                        <Accordian
                            end={
                                <span style={{fontSize:'15pt'}}>{`${numberWithCommas(group.parkinglotAmount || group.groupAmount || 0)}원 `}</span>
                            }
                            expandItem={<DetailExpand store={store} date={calDate}
                                                      group={groupDetails[group.groupSeqid] || {...groupDetails[group.parkinglotSeqid], ...group} || {
                                                          useCount: '',
                                                          useAmount: ''
                                                      }}/>}
                            onClick={async () => {
                                try {
                                    if (group.groupSeqid) {
                                            const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'g', group.groupSeqid)
                                            if (isZeroButton === true){
                                                setGroupDetails({...groupDetails, [group.groupSeqid]: elements});
                                            }else {
                                             elements.pElements=elements.pElements.filter(item => item.parkinglotAmount!==0);
                                             setGroupDetails({...groupDetails, [group.groupSeqid]: elements});
                                            }
                                    } else if (group.parkinglotSeqid) {
                                        const {elements, message, code} = await API().useDetail(store.user.token, calDate, 'p', null, group.parkinglotSeqid)
                                        setGroupDetails({...groupDetails, [group.parkinglotSeqid]: elements})
                                    }else{

                                    }

                                } catch (e) {

                                } finally {

                                }
                            }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {group.groupName ?
                                    <><ColorIcons name={'group'} size={27}/>&nbsp;{group.groupName}</>
                                    : <><ColorIcons name={'solo'} size={27}/>&nbsp;{group.parkinglotName}</>}
                            </div>
                        </Accordian>
                    </div>
                })}

            </div>
        </div>
        <Toast onClose={() => setShowToast(false)} open={showToast}>
            {showToast}
        </Toast>
    </>
};




export default UseDetail;
