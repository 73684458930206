import React, {useEffect, useState} from 'react'
import styles from './style.module.scss'
import API from 'api';
import {ColorIcons, Icons} from "../../../components";
import {useMount} from "../../../hooks";


const Share = ({store}) => {
    const [elements, setElements] = useState();
    const [calDate, setCalDate] = useState(`${new Date().getFullYear()}-${('0' + (new Date().getMonth() )).slice(-2)}`)
    const [showShareModal, setShowShareModal] = useState(false)
    function getDate(inputDate) {
        let date = new Date(Date.parse(inputDate.replace(/-/g, '/')));
        return date.getDate()
    }

    function getMonth(inputDate) {
        let date = new Date(Date.parse(inputDate.replace(/-/g, '/')));
        return date.getMonth() + 1
    }

    function getYear(inputDate) {
        let date = new Date(Date.parse(inputDate.replace(/-/g, '/')));
        return date.getFullYear()
    }

    function getHour(inputDate) {
        let date = new Date(Date.parse(inputDate.replace(/-/g, '/')));
        return date.getHours()
    }

    function getMinute(inputDate) {
        let date = new Date(Date.parse(inputDate.replace(/-/g, '/')));
        return date.getMinutes()
    }

    function getDay(inputDate) {
        let date = new Date(Date.parse(inputDate.replace(/-/g, '/')));
        return date.getDate()
    }

    function isWeekend(inputDate) {
        const date = new Date(inputDate);
        return date.getDay() === 6 || date.getDay() === 0;
    }

    function handleDateButton(e, isPrev) {
        e.preventDefault()
        setCalDate(prev => {
            const targetDate = new Date(prev);
            if (isPrev)
                targetDate.setMonth(targetDate.getMonth() - 1);
            else {
                if (targetDate.getFullYear() + (('0' + (targetDate.getMonth() + 1)).slice(-2))
                    >= new Date().getFullYear() + (('0' + (new Date().getMonth() + 1)).slice(-2))) {
                    return prev
                }
                targetDate.setDate(1);
                targetDate.setMonth(targetDate.getMonth() + 1);
            }
            return `${targetDate.getFullYear()}-${('0' + (targetDate.getMonth() + 1)).slice(-2)}`
        });
    }
    async function handleUseList() {
        try {
            const {code, elements} = await API().useList(store.user.token);
            if (code === 200) {
                setElements(elements.elements);
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        handleUseList();

    }, []);
    // useMount(() => {
    //     const getAssignee = async () => {
    //         try {
    //             const {code, message, elements} = await API().assignee(store.user.token)
    //             if (code === 200) {
    //                 console.log(store)
    //             } else {
    //                 console.error(message)
    //             }
    //         } catch (e) {
    //             console.error(e)
    //         }
    //     };
    //     getAssignee();
    // });
    return <>
    <div className={`${styles.background} ${styles[`width-fill`]}`}></div>
        <div className={`${styles.body} ${styles[`width-fill`]}`}>
        <header className={`${styles[`text-white`]} ${styles[`p-15`]}`}>
            <h1 className={styles.bold}>포인트 이력</h1>
        </header>
        <section className={`${styles[`point-statistics`]} ${styles[`text-white`]} ${styles[`p-15`]}`} >
            <div className={`${styles[`point-information-wrapper`]} ${styles.flex}`}>
                <div className={styles[`profile-image`]}>
                    <ColorIcons name={'logo-default'} size={70}/>
                </div>
                <article className={styles[`text-right`]}>
                    <h2 className={styles.bold}>P</h2>
                    <p>누적 포인트</p>
                </article>
                <article className={`${styles[`text-right`]} ${styles[`mr-20`]} `}>
                    <h2 className={styles.bold}>P</h2>
                    <p>이번달 사용 포인트</p>
                </article>
            </div>
            <div className={`${styles[`badage-name-wrapper`]} ${styles[`mt-20`]} ${styles[`flex-left`]}`}>
                <Icons name={'icon-pf'}  className={styles.badage} alt={"뱃지"}/>
                <h2 className={`${styles.bold} ${styles[`ml-10`]}`}>{store.user.name}님</h2>
            </div>
            <div className={`${styles[`usable-point-wrapper`]} ${styles.flex} ${styles[`mt-20`]} ${styles[`text-black`]} ${styles[`p-10`]} ${styles[`background-white`]}`}>
                <span className={`${styles.h3} ${styles.bold}`}>사용가능 포인트</span>
                <span className={styles.h3}>
                    <span className={`${styles.h3} ${styles.blue} ${styles.bold}`}>95,000</span> P</span>
            </div>
        </section>
        <section className={styles[`p-15`]}>
            <div className={styles.flex}>
                <h3 className={`${styles[`text-white`]} ${styles.bold}`}> 포인트 누적 및 사용이력</h3>
            </div>
            <div className={styles.MonthSelector}>
                <button onClick={e => handleDateButton(e, true)}>
                    <Icons name={'link-left'} size={11}/>
                </button>
                {calDate.slice(0, 4)}년 {calDate.slice(-2)}월
                <button onClick={e => handleDateButton(e, false)}>
                    <Icons name={'link-right'} size={11}/>
                </button>
            </div>
        </section>
        </div>
    </>
};

export default Share;
