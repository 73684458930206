import React, {useRef, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {TextField} from '@material-ui/core'
import API from 'api'
import ReportModal from "./ReportModal";
import ReportCheckModal from "../components/ReportModal"
import styles from './style.module.scss'
import {useMount} from 'hooks';

import {Ticket, Button, ColorIcons, Icons, CarNumInputField, Progress, DatePickerField} from "components";
import {numberWithCommas} from "utils";

const CheckCar = ({history, store}) => {
    const [date, setDate] = useState(new Date());
    const [carNum, setCarNum] = useState(['', '', '']);
    const [elements, setElements] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    const [isShowLog, setIsShowLog] = useState(true);
    const [isReserved, setIsReserved] = useState(false);
    const [selectedSeqid, setSelectedSeqid] = useState({});
    const [illegalMessage, setIllegalMessage] = useState('');
    const [groupList, setGroupList] = useState([]);
    const [reservationList, setReservationList] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [showConfirm, setConfirm] = useState(null);
    const calendar = useRef(null);


    const smallCircle = {
        'backgroundColor': '#ffffff',
        'borderRadius': '100%',
        width: '43px',
        height: '43px',
        display: 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'
      }

    // 불법주차 단속요청
    async function handleIllegal(carNum, parkinglotSeqid, phoneNumber, slotNum) {
        try {
            setIsLoading(true);
            const {code, message} = await API().illegalRequest(store.user.token, carNum, selectedSeqid.parkinglotSeqid, '', '');
            setIllegalMessage(message);
            return illegalMessage;
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    useMount(() => {
        const getGroupInfo = async () => {
            try {
                setIsLoading(true);
                const {elements} = await API().groupList(store.user.token);
                setGroupList(elements);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };
        getGroupInfo();
        handleOrder();

    });


    async function handleAPI(date, carNum) {
        if (!date) {
            alert('날짜를 입력해주세요');
            return;
        }
        if (!carNum) {
            alert('차량 번호를 입력해주세요');
            return;
        }

        try {
            setIsLoading(true)
            const {code, elements, message} = await API().illegalCheck(store.user.token, String(date), String(carNum));
            if (code === 200 && elements) {
                const tmp = elements;
                setElements(tmp)
                setIsShowLog(false);
            } else {
                setElements(null)
                setIsShowLog(false);
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    async function handleOrder(date, carNum) {
        try {
            setIsLoading(true)
            const {elements: {rElements}, code, message} = await API().reservationList(store.user.token, 'g')
            if (code === 200) {
                setReservationList(rElements)
                console.log(rElements)
            }
            setShowToast(message);
        } catch (e) {
            // setShowToast(e);
        } finally {
            setIsLoading(false)
        }
    }

    let flagDate;
    const color = {
        color : 'white'
    }
    
    const hide ={
        display: 'none'
    }



    function statusCode(reservationList) {
        if(reservationList.status === "REFUNDED"){
            return <Ticket time={reservationList.created_at} lotName={''}
                        carNumber={'환불 금액 ' + reservationList.fee + '원'} ticketColor={'#ed9d53'} 
                        phone = {'전화번호 ' + reservationList.phone_number} 
                        endTime = {"종료 시간 : " + reservationList.endTime}
                        startTime = {"시작 시간 : " + reservationList.startTime}
                        lotName ={reservationList.carNum ? "차량 번호 : " + reservationList.carNum : "차량정보 없음" }>
                <div style = {smallCircle}>
                    <ColorIcons name={'title-reportlist'} size={24}/>
                </div> 
                <div style = {color}>환불</div>
            </Ticket>
        } else {
            return <Ticket time={reservationList.created_at} lotName={''} //logElement.lotName
                carNumber={'결제 금액 '+ reservationList.fee + '원'} ticketColor={'#4d7adb'}
                phone = {'전화번호 ' + reservationList.phone_number} 
                endTime = {"종료 시간 : " + reservationList.endTime}
                startTime = {"시작 시간 : " + reservationList.startTime}
                lotName ={reservationList.carNum ? "차량 번호 : " + reservationList.carNum : "차량정보 없음" }>                
                <div style = {smallCircle}>
                <ColorIcons name={'title-reportlist'} size={24}/>
            </div> 
            <div style = {color} > <br/>결제</div>
            </Ticket>
        }
    }



    return <div className={styles.CheckCarContainer}>
        <div className={styles.CheckCarHeader}>
            <div className={styles.titles}>
                <ColorIcons name={'title-carcheck'} size={25}/>
                차량조회
            </div>
            <button onClick={() => {
                history.push('/pmanager/setting')
            }}>
                <ColorIcons name={'title-setting'} size={25}/>
            </button>
        </div>
        <form noValidate>
            <DatePickerField label="검색일"
                className={styles.DatePickerContainer}
                value={date}
                style={{width: '100%'}}
                refs={calendar}
                onChange={ date => setDate(date)}
                onClick={ e => {
                    console.log("CLICKED !!");
                }}
                InputLabelProps={{
                    shrink: true,
            }}/>
            <CarNumInputField placeholder={'예) 123가1234 또는 1234'} className={styles.CarNumInputContainer} refs={calendar}
                onChange={e => setCarNum(e.target.value)}
            />

            <Button variant={'contained'} color={'primary'} onClick={() => {
                handleAPI(date, carNum[1] + carNum[2])
            }}>검색  {() => setIsReserved(false)} </Button>
        </form>

        <div className={styles.result}>
            {isShowLog ? '예약 내역' : <div> 검색 결과
                                <Button variant={"outlined"} color={'primary'}
                                    onClick={() => setIsShowLog(true)}
                                    style={{
                                        flex: '1',
                                        display: 'flex',
                                        margin: '4px',
                                        'margin-left': 'auto'
                                    }}> 예약 내역 보기
                                </Button>  </div>}
        </div>

        {
            elements ?
                elements.map((element) =>
                    element.product !== '배정자' ?
                        <Ticket lotName={'시작   ' + element.startDate}
                        endTime ={'종료   ' + element.endDate}
                        time={element.parkinglotName} carNumber={element.carNum} ticketColor={'#d4e0f7'}>
                            {/* <button className={styles.tag}>
                                입차 확인
                            </button> */}
                            <div className={styles.tag}>{element.product === 'TIME'? '시간권':''}</div>
                            {numberWithCommas(element.fee)}
                        </Ticket> : <div className={styles.CardContainer} style={{flexDirection: 'column', padding: '25px 0'}}>
                    <div><ColorIcons name={'carcheck-noresult'}/></div>
                    예약 또는 이용 내역이 없습니다.
                    {/* <Button variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setIsOpenModal(true)
                            }}
                            style={{
                                width: '150px',
                                height: '38px',
                                borderRadius: '19px',
                                fontWeight: 500,
                                fontSize: '16px',
                            }}>
                        단속요청
                    </Button> */}
                </div>)
                : <div className={styles.CardContainer} style={{flexDirection: 'column', padding: '25px 0'}}>
                    <div><ColorIcons name={'carcheck-noresult'}/></div>
                    예약 또는 이용 내역이 없습니다.
                    {/* <Button variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setIsOpenModal(true)
                            }}
                            style={{
                                width: '150px',
                                height: '38px',
                                borderRadius: '19px',
                                fontWeight: 500,
                                fontSize: '16px',
                            }}>
                        단속요청
                    </Button> */}
                </div>
        }

        

        {isShowLog ? 
            <div >
                {
                    (reservationList) ?
                        (reservationList).map((reservationList, index) =>
                                <div>
                                    <div className={styles.flagDate}>
                                        {/* {reservationList.requestDate} */}
                                    </div>
                                    {statusCode(reservationList)}
                                </div> 
                        )
                        : '조회내역 없음'
                }
            </div>
            : //showRes()
            ''
        }
        
   
    
        


        <ReportModal store={store} groups={groupList} setSeqid={setSelectedSeqid} isOpenModal={isOpenModal}
                     handleIllegal={handleIllegal} carNum={carNum} illegalMessage={illegalMessage}
                     toggleModal={() => setIsOpenModal(!isOpenModal)}/>
        <Progress isLoading={isLoading}/>

    </div>;

    

};

export default withRouter(CheckCar);
