import Request from './base/Request';
import * as base64 from 'base-64';

class ContractRequest extends Request {
    constructor(APIURL, header, body) {
        super(APIURL);

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...header
            },
            body: JSON.stringify(body)
        };
        this.request = this.request.bind(this);
    }
}

export default ContractRequest;
