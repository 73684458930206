import React, {useState} from 'react'

import classnames from 'classnames/bind'
import query from 'query-string'
import {InputAdornment, Modal, Backdrop, Fade, InputLabel} from '@material-ui/core'

import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import colors from "styles/_colors.scss";
import styles from './style.module.scss'
import {Icons, TextField, Button, Toast} from "components";

import SelectBelong from 'pages/Register/SelectBelong'
import CellAuth from "components/CellAuth";
import API from 'api'
import AssigneeAgreement from "./AssigneeAgreement";
import {useMount} from "../../../hooks";

const cx = classnames.bind(styles)

const RegisterInfo = ({history, store, match, location}) => {
    const [userId, setUserId] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [carNumber, setCarNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [district, setDistrict] = useState('');
    const [districtSeqid, setDistrictSeqid] = useState({});

    const [company, setCompany] = useState('');
    const [parkinglotName, setParkinglotName] = useState('');

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showAssigneeAgreement, setShowAssigneeAgreement] = useState(false);
    const [showCellModal, setShowCellModal] = useState(true);
    const [showBelongModal, setShowBelongModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const [marketingAgreement, setMarketingAgreement] = useState(location.state.marketingAgreement||false);

    const [userType, setUserType] = useState('');

    function toggleModal() {
        setShowBelongModal(!showBelongModal);
        setIsOpenModal(!isOpenModal)
    }

    function handleDone(cellNumber) {
        setPhoneNumber(cellNumber);
        if (userType === 'ASSIGNEE') {
            var password=cellNumber.substr(7,11)
            setUserId(cellNumber);
            setPassword(password);
            setPasswordCheck(password);
        }
        setShowCellModal(false);
    }


    async function illegalSubmit() {
        const { code, message } = await API().register(
            phoneNumber,
            userId,
            password,
            name,
            '',
            '',
            '', '',
            '', '', userType);

        if (code === 200) {
            history.push({
                pathname: '/register/done',
                userId: userId,
                name: name,
                phoneNumber: phoneNumber,
                district: '',
                bank:'',
                accountHolder:'',
                accountNumber:'',
                birthday:'',
                manager:'',
                userType : userType
            });
        } else {
            setShowToast(message);
        }
    }

    useMount(() => {
        const strUserType = match.params.userType.split('?')[0];
        setUserType(strUserType);
        const {districtLocation, districtSeqIdLocation} = query.parse(match.params.userType.split('?')[1]);
        if (districtLocation && districtSeqIdLocation) {
            setDistrict(districtLocation);
            setDistrictSeqid(Number(districtSeqIdLocation));
        }
        setMarketingAgreement(location.state.marketingAgreement);
    });

    return <>
        <div className={styles.InfoContainer}>
            <h1>
                <button onClick={() => history.goBack()}>
                    <Icons name={'back'} size={24} color={colors.text}/>
                </button>
                기본 정보 입력
            </h1>
            <div className={styles.card}>
                {userType === 'ASSIGNEE' ?
                    <TextField
                        id="userID"
                        variant="outlined" label="아이디" size={'small'}
                        value={userId}
                        inin={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Icons color={colors.text} name={'check-sh'} size={15}/>
                                </InputAdornment>
                        }}
                        readOnly/>:
                    <TextField
                        id="userID"
                        variant="outlined" label="아이디" size={'small'}
                        value={userId}
                        onChange={e => {
                            setUserId(e.target.value)
                        }}/>
                }
            </div>

            {userType === 'ASSIGNEE' ? null :
            <div className={styles.card} >
                <TextField
                    id="phoneNo"
                    variant="outlined"
                    label="휴대폰 번호"
                    size={'small'}
                    value={phoneNumber}
                    inin={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Icons color={colors.text} name={'link-right'} size={15}/>
                            </InputAdornment>
                    }}
                    readOnly/>
            </div>}

            {userType === 'ASSIGNEE' ?
            <div className={cx(styles.card, styles.password)}>
                <TextField
                    label="비밀번호"
                    size={'small'}
                    type={'password'}
                    className={styles.fullWidthText}
                    value={password}
                    inin={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Icons color={colors.text} name={'check-sh'} size={15}/>
                            </InputAdornment>
                    }}
                    readOnly/>
                <TextField
                    label="비밀번호 확인"
                    size={'small'}
                    type={'password'}
                    className={styles.fullWidthText}
                    value={passwordCheck}
                    inin={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Icons color={colors.text} name={'check-sh'} size={15}/>
                            </InputAdornment>
                    }}
                    readOnly/>
            </div>: <div className={cx(styles.card, styles.password)}>
                    <TextField
                        label="비밀번호"
                        size={'small'}
                        type={'password'}
                        className={styles.fullWidthText}
                        value={password}
                        onChange={e => {
                        setPassword(e.target.value)
                    }}/>
                    <TextField
                        label="비밀번호 확인"
                        size={'small'}
                        type={'password'}
                        className={styles.fullWidthText}
                        value={passwordCheck}
                        onChange={e => {
                            setPasswordCheck(e.target.value)
                        }}
                    />
                </div>}

                <div className={styles.card}>
                    <TextField
                        id="user-name"
                        variant="outlined"
                        label="이름"
                        size={'small'}
                        value={name}
                        autoFocus
                        onChange={e => {
                            setName(e.target.value)
                        }}/>
                </div>
                
                {userType === 'ASSIGNEE' ?
                    <div className={styles.card}>
                        <TextField
                            id="user-car-number"
                            variant="outlined"
                            label="차량번호"
                            size={'small'}
                            placeholder="예: 서울123가4567"
                            value={carNumber}
                            onChange={e => {
                                setCarNumber(e.target.value)
                            }}/>
                    </div> : null
                }

            {['OFFICER', "ASSIGNEE"].includes(userType) ?
                <div className={styles.card}>
                    <TextField
                        id="user-district"
                        className={"primary"}
                        variant="outlined"
                        label={userType === 'ASSIGNEE' ? '거주자우선주차구역 관할구' : "소속 지자체"}
                        size={'small'}
                        inin={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <Icons color={colors.text} name={'dropdown-open'} size={15}/>
                                </InputAdornment>
                        }}
                        onClick={() => {
                            setShowBelongModal(!showBelongModal)
                        }}
                        value={
                            district.includes('undefined') ? null : district
                        }
                        disabled
                        readOnly
                    />
                </div> : null}

            {userType === 'COMPANY' ?
                <div className={styles.card}>
                    <TextField
                        variant="outlined"
                        label="운영사 입력"
                        size={'small'}
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                    />
                </div> : null}


            {userType === 'PMANAGER' ?
                <div className={styles.card}>
                    <TextField
                        variant="outlined"
                        label="주차장명 입력"
                        size={'small'}
                        value={parkinglotName}
                        onChange={e => setParkinglotName(e.target.value)}
                    />
                </div> : null}

            {userType === 'ASSIGNEE' ? 
            <Alert variant="success" className={styles.noticeWarning}>
                <ul className="fa-ul">
                    <li className={styles.keyword}>
                    <i className="fa fa-info-circle fa-lg fa-li" aria-hidden="true"></i>
                    회원 가입 시 <span className={styles.underline}>아이디</span>는 휴대폰번호로, <span className={styles.underline}>초기 비밀번호</span>는 전화번호 뒤4자리로 설정됩니다. 회원 가입 후 <span className={styles.underline}>비밀번호 변경</span>을 통해 수정 할 수 있습니다.</li>
                </ul>
            </Alert>
                : null
            }
            
            <Button variant="contained"
                    color="primary"
                    size={'large'}
                    onClick={async () => {
                        if (password !== passwordCheck) {
                            setShowToast('비밀번호가 일치하지 않습니다')
                            return
                        } else if (password.length < 4 || passwordCheck.length < 4) {
                            setShowToast('비밀번호가 4자 이상이어야합니다')
                            return
                        } else if (RegExp(/[^A-Za-z0-9]+/g).test(userId)) {
                            setShowToast('아이디는 영문과 숫자만 사용가능합니다')
                            return
                        } else if (RegExp(/[^A-Za-z0-9]+/g).test(password)) {
                            setShowToast('비밀번호는 영문과 숫자만 사용가능합니다')
                            return
                        }
                        else {
                            const {code, message} = await API().idCheck(userId);
                            if (code !== 200) {
                                setShowToast(message)
                                return
                            }
                        }

                        if (userType === 'ASSIGNEE') {
                            setShowAssigneeAgreement(true);
                            return;
                        }

                        if (match.params.userType === 'ILLEGAL') {
                            await illegalSubmit();
                            return;
                        }

                        history.push({
                            pathname: '/register/additional/' + match.params.userType,
                            userId,
                            name,
                            password,
                            phoneNumber,
                            carNumber,
                            district,
                            company,
                            marketing: history.location.marketing
                        });
                    }}
                    disabled={(['OFFICER', "ASSIGNEE"].includes(match.params.userType) && !district || !carNumber) || !userId || !name || !password || !passwordCheck || !phoneNumber}>
                다음
            </Button>

            <SelectBelong isOpenModal={showBelongModal}
                          toggleModal={toggleModal}
                          getBelong={(belong) => {
                              console.log('belong: ', belong)
                              const district1 = (((belong[0] || {}).districtTitle) || '');
                              const district2 = (((belong[1] || {}).districtTitle) || '');
                              setDistrict(district1 + ' ' + district2)
                              setDistrictSeqid((belong[1] || {}).districtSeqid)
                          }}
            />
            {/* SelectBank */}
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={showCellModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showCellModal}>
                <div className={styles.ModalContentContainer}>
                    <CellAuth onAuth={handleDone} store={store} history={history} className={styles.modal}/>
                </div>
            </Fade>
        </Modal>
        <AssigneeAgreement
            district={districtSeqid}
            showAssigneeAgreement={showAssigneeAgreement}
            setShowAssigneeAgreement={setShowAssigneeAgreement}
            onAgree={() =>
                history.push({
                    pathname: '/register/additional/' + match.params.userType,
                    userId,
                    name,
                    password,
                    phoneNumber,
                    carNumber,
                    district,
                    company,
                    marketingAgreement
                })}
        />
        <Toast open={showToast} onClose={() => setShowToast(false)} actionICon={'confirm'}>
            {showToast}
        </Toast>
    </>
};

export default RegisterInfo;
