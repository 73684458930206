import LoginRequest from './LoginRequest';
import * as base64 from "base-64";
import CellAuthRequest from "./CellAuthRequest";
import FindIdentityRequest from "./FindIdentityRequest";
import TermListRequest from "./TermListRequest";
import DistrictListRequest from "./DistrictListRequest"
import IdCheckRequest from "./IdCheckRequest"
import RegisterRequest from "./RegisterRequest"
import RegisterAssigneeRequest from "./RegisterAssigneeRequest"
import BenefitAdjustRequest from "./BenefitAdjustRequest";
import BankListRequest from "./BankListRequest";
import ManagerListRequest from "./ManagerListRequest";
import AgreeUrlRequest from "./AgreeUrlRequest";
import GroupListRequest from "./GroupListRequest";
import illegalSummaryRequest from "./illegalSummaryRequest";
import illegalParkinglotRequest from "./illegalParkinglotRequest";
import illegalCheckRequest from "./illegalCheckRequest";
import illegalRequestRequest from "./illegalRequestRequest";
import RequestListRequest from "./RequestListRequest";
import RegisterResultsRequest from "./RegisterResultsRequest";
import UseNshareSummaryRequest from "./UseNshareSummaryRequest";
import UseDetailRequest from "./UseDetailRequest";
import ShareDetailRequest from "./ShareDetailRequest";
import CalculateRequest from "./CalculateRequest";
import ContractRequest from "./ContractRequest";
import ReservationListRequest from "./ReservationListRequest";
import ActionListRequest from "./ActionListRequest";
import ActionChangeRequest from "./ActionChangeRequest";
import AssigneeRequest from "./AssigneeRequest";
import BannerRequest from "./BannerRequest";
import ShareListRequest from "./ShareListRequest";
import ShareTimeChangeRequest from "./ShareTimeChangeRequest";
import ShareTimeListRequest from "./ShareTimeListRequest";
import AssigneecalRequest from "./AssigneecalRequest";
import MonthlyStatusRequest from "./MonthlyStatusRequest";
import ConfirmationRequest from "./ConfirmationRequest";
import PartnerInfoRequest from "./PartnerInfoRequest";
import UserInfoChangeRequest from "./UserInfoChangeRequest";
import PasswordChangeRequest from "./PasswordChangeRequest";
import HomeSummaryRequest from "./HomeSummaryRequest";
import UseListRequest from "./UseListRequest";
import PushLogRequest from "./PushLogRequest";
import AssigneeRemittanceRequest from "./AssigneeRemittanceRequest";
import lotOperTimeRequest from "./lotOperTimeRequest";
import AlbaActionRequest from "./AlbaActionRequest";

const SERVER_URL = 'https://ax.hmobility.co.kr/partner-api/v1/partner';
const SERVER_URL_temp = 'https://ax.hmobility.co.kr/admin/v1/partner';

const API = (store = null) => {
    return {
        certification: (token, userid, password) => {
            return new ConfirmationRequest(
                SERVER_URL + `/confirm`,
                {Authorization: token},
                {userid, password, devide: 'd'}).request()
        },
        login: (userId, userPW) => {
            const headerOption = {
                Authorization: "Basic " + base64.encode(`${userId}:${userPW}`)
            };
            return new LoginRequest(
                SERVER_URL + `/auth`,
                headerOption
            ).request(store);
        },
        confirm: (devide, phoneNumber, otpid, otp) => {
            return new CellAuthRequest(
                SERVER_URL + `/mobilecheck`,
                {devide, phoneNumber, otpid, otp}
            ).request(store);
        },
        findID: (phoneNumber) => {
            return new FindIdentityRequest(
                SERVER_URL + `/useridsearch`,
                phoneNumber
            ).request();
        },
        passwordChange: (phoneNumber, userid)=> {
            return new PasswordChangeRequest(
                SERVER_URL + '/pwdchange',
                {devide: 'r', phoneNumber, userid}
            ).request()
        },
        // 6
        termList: (devide, termSeqid) => {
            return new TermListRequest(
                SERVER_URL + `/termslist`,
                {devide, termSeqid}
            ).request();
        },
        // 7
        districtList: (devide, districtSeqid) => {
            return new DistrictListRequest(
                SERVER_URL + `/districtlist`,
                {devide, districtSeqid}
            ).request();
        },
        // 8
        idCheck: (userid) => {
            return new IdCheckRequest(
                SERVER_URL + `/idcheck`,
                userid
            ).request();
        },
        // 9
        register: (phoneNumber, userid, password, name,
                   district, bankName, accountNumber, accountHolder,
                   birthday, managerName, userType, marketing) => {
            return new RegisterRequest(
                SERVER_URL + `/register`,
                {
                    phoneNumber,
                    userid,
                    password,
                    name,
                    district,
                    bankName,
                    accountNumber,
                    accountHolder,
                    birthday,
                    managerName,
                    userType,
                    marketing
                }
            ).request(store);
        },
        // 10
        registerAssignee: (phoneNumber, userid, password, name, carNumber,
                           district, assigneeType, areaNum, slotNum, startDate, endDate, fee, bankName, accountNumber, accountHolder,
                           birthday, managerName, marketing) => {
            return new RegisterAssigneeRequest(
                SERVER_URL + `/registerAssignee`,
                {
                    phoneNumber, userid, password, name, carNumber,
                    district, assigneeType, areaNum, slotNum,
                    startDate, endDate, fee, bankName, accountNumber,
                    accountHolder, birthday, managerName, marketing
                }
            ).request(store);
        },
        adjust: (token, searchMonth, devide, groupSeqid, parkinglotSeqid) => {
            return new BenefitAdjustRequest(
                SERVER_URL + `/calculate`,
                {Authorization: token}, searchMonth, devide, groupSeqid, parkinglotSeqid
            ).request();
        },
        bankList: (token) => { // 11, postman 에는 있지만, authorization 없어도 조회 가능
            return new BankListRequest(
                SERVER_URL + `/banklist`,
                {Authorization: token}
            ).request();
        },
        managerList: () => { // 12, no authorization
            return new ManagerListRequest(
                SERVER_URL + `/managerlist`,
            ).request();
        },
        agreeUrl: (districtSeqid) => { // 13, no authorization
            return new AgreeUrlRequest(
                SERVER_URL + `/agreeUrl`,
                districtSeqid
            ).request();
        },
        groupList: (token) => { // 14
            return new GroupListRequest(
                SERVER_URL + `/grouplist`,
                {Authorization: token}
            ).request();
        },
        // to do 예외처리
        illegalSummary: (token, devide, groupSeqid, parkinglotSeqid) => { // 15
            return new illegalSummaryRequest(
                SERVER_URL + `/illegalsummary`,
                {Authorization: token},
                {devide, groupSeqid, parkinglotSeqid}
            ).request();
        },
        illegalParkinglot: (token) => { // 16
            return new illegalParkinglotRequest(
                SERVER_URL + `/illegalparkinglot`,
                {Authorization: token}
            ).request();
        },
        illegalCheck: (token, date, carNum) => { // 17
            return new illegalCheckRequest(
                SERVER_URL + `/illegalcheck`,
                {Authorization: token},
                {date, carNum}
            ).request();
        },
        illegalRequest: (token, carNum, parkinglotSeqid, phoneNumber, slotNum) => { // 18
            return new illegalRequestRequest(
                SERVER_URL + `/illegalrequest`,
                {Authorization: token},
                {carNum, parkinglotSeqid, phoneNumber, slotNum}
            ).request();
        },
        requestList: (token) => { // 19
            return new RequestListRequest(
                SERVER_URL + `/requestList`,
                {Authorization: token}
            ).request();
        },
        registerResults: (token, requestSeqid, result) => { // 20
            return new RegisterResultsRequest(
                SERVER_URL + `/registerResults`,
                {Authorization: token},
                {requestSeqid, result}
            ).request(store);
        },
        useNshareSummary: (token, searchDate, devide, groupSeqid, parkinglotSeqid) => { // 21
            return new UseNshareSummaryRequest(
                SERVER_URL + `/usensharesummary`,
                {Authorization: token},
                {searchDate, devide, groupSeqid, parkinglotSeqid}
            ).request();
        },
        useDetail: (token, searchDate, devide, groupSeqid, parkinglotSeqid) => { // 22
            return new UseDetailRequest(
                SERVER_URL + `/usedetail`,
                {Authorization: token},
                {searchDate, devide, groupSeqid, parkinglotSeqid}
            ).request();
        },
        shareDetail: (token, searchDate, devide, groupSeqid, parkinglotSeqid) => { // 23
            return new ShareDetailRequest(
                SERVER_URL + `/sharedetail`,
                {Authorization: token},
                {searchDate, devide, groupSeqid, parkinglotSeqid}
            ).request();
        },
        calculate: (token, searchMonth, devide, groupSeqid, parkinglotSeqid) => { // 24
            return new CalculateRequest(
                SERVER_URL + `/calculate`,
                {Authorization: token},
                {searchMonth, devide, groupSeqid, parkinglotSeqid}
            ).request();
        },
        contract: (token, parkinglotSeqid) => { // 25
            return new ContractRequest(
                SERVER_URL + `/contract`,
                {Authorization: token},
                {parkinglotSeqid}
            ).request();
        },
        reservationList: (token, devide, parkinglotSeqid, long, lat) => { // 26
            return new ReservationListRequest(
                SERVER_URL + `/reservationlist`,
                {Authorization: token},
                {devide, parkinglotSeqid, long, lat}
            ).request();
        },
        actionList: (token, devide, searchDate) => { // 27
            return new ActionListRequest(
                SERVER_URL + `/actionlist`,
                {Authorization: token},
                {devide, searchDate}
            ).request();
        },
        actionChange: (token, devide, actionSeqid, status) => { // 28
            return new ActionChangeRequest(
                SERVER_URL + `/actionChange`,
                {Authorization: token},
                {devide, actionSeqid, status}
            ).request();
        },
        assignee: (token) => { // 29
            return new AssigneeRequest(
                SERVER_URL + `/assignee`,
                {Authorization: token},
            ).request();
        },
        banner: (token) => { // 30
            return new BannerRequest(
                SERVER_URL + `/banner`,
                {Authorization: token},
            ).request();
        },
        shareList: (token, searchMonth) => { // 31
            return new ShareListRequest(
                SERVER_URL + `/sharelist`,
                {Authorization: token},
                {searchMonth}
            ).request();
        },
        shareTimeChange: (token, elements) => { // 32, elements = {weekday, shareForm, shareTo}
            return new ShareTimeChangeRequest(
                SERVER_URL + `/sharetimechange`,
                {Authorization: token},
                {elements}
            ).request();
        },
        shareTimeList: (token) => { // 33
            return new ShareTimeListRequest(
                SERVER_URL + `/sharetimelist`,
                {Authorization: token},
            ).request();
        },
        /*assigneeCal: (token, searchYear) => { // 34
            return new AssigneeCalRequest(
                SERVER_URL + `/assigneecal`,
                {Authorization: token},
                {searchYear}
            ).request();
        },*/
        assigneecal: (token) => { // 34
            return new AssigneecalRequest(
                SERVER_URL + `/assigneecal`,
                {Authorization: token}
            ).request();
        },
        monthlyStatus: (token, devide, status) => { // 35
            return new MonthlyStatusRequest(
                SERVER_URL + `/monthlyStatus`,
                {Authorization: token},
                {devide, status}
            ).request();
        },
        userinfoChange: (token, password, name, bankName, accountNumber, AccountHolder, marketing)=>{
            return new UserInfoChangeRequest(
                SERVER_URL + '/userinfoChange',
                {Authorization: token},
                {password, name, bankName, accountNumber, AccountHolder, marketing}
            ).request()
        },
        partnerInfo: (token) => {
            return new PartnerInfoRequest(
                SERVER_URL + `/partnerInfo`,
                    {Authorization: token}
            ).request();
        },
        income: (token, devide, groupSeqid, parkinglotSeqid)=> {
            return new HomeSummaryRequest(
                SERVER_URL + '/income',
                {Authorization: token},
                {devide, groupSeqid, parkinglotSeqid}
            ).request();
        },
        useList: (token)=> {
            return new UseListRequest(
                SERVER_URL + '/useList',
                {Authorization: token},
                //,{searchMonth}
            ).request();
        },
        pushLog: (token, filter)=> {
            return new PushLogRequest(
                SERVER_URL + '/pushLog',
                {Authorization: token},
                {filter}
            ).request();
        },
        assigneeRemittance: (token)=> {
            return new AssigneeRemittanceRequest(
                SERVER_URL + '/assigneeRemittance',
                {Authorization: token}
            ).request();
        },
        lotOperTime: (token) => { // 11, postman 에는 있지만, authorization 없어도 조회 가능
            return new lotOperTimeRequest(
                SERVER_URL + `/lotOperTime`,
                {Authorization: token}
            ).request();
        },
        albaAction: (token, carNum, parkinglotSeqid, devide, phoneNumber, slotNum) => { // 18
            //console.log("일단 진입??")
            return new AlbaActionRequest(
                SERVER_URL + `/albaAction`,
                {Authorization: token},
                {carNum, parkinglotSeqid, devide, phoneNumber, slotNum}
            ).request();
        }

    }
};
export default API;
