import React, {useState} from 'react'
import {Backdrop, Fade, Modal} from "@material-ui/core";
import styles from "./style.module.scss";
import {Icons, Button, Progress, Toast} from "components";
import {TextField} from "components";
import API from "api";

const Resign = ({isOpen, toggleModal, store, history}) => {
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);

    async function handleResign() {
        try {
            setIsLoading(true)
            const {code, message} = await API().certification(store.user.token, store.user.username, password);
            setShowToast(message)
            if (code === 200) {
                localStorage.clear();
                history.replace('/');
            }
        } catch (e) {
            // setShowToast(e);
        } finally {
            setIsLoading(false)
        }
    }

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpen}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
                <div className={styles.ModalContentContainer}>
                    <div className={styles.header}>
                        탈퇴하시겠습니까?
                        <button onClick={toggleModal}><Icons name={'cancel-sh'} size={18}/></button>
                    </div>
                    <div className={styles.alert}>
                        탈퇴 후에는 해당 아이디와 데이터는 복구할 수 없습니다. 관련 법 및 개인정보취급방침에 따라 회사가 회원 정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 데이터는 소멸됩니다.
                    </div>
                    <TextField
                        style={{marginBottom: '15px'}}
                        variant={'outlined'}
                        label="비밀번호"
                        size={'small'}
                        type={'password'}
                        value={password}
                        onChange={e => {r
                            setPassword(e.target.value)
                        }}/>
                    <br></br>
                    <Button variant={'contained'} color={'primary'} onClick={handleResign}>
                        탈퇴하기
                    </Button>
                    <div> </div>

                </div>
            </Fade>
        </Modal>
        <Progress isLoading={isLoading}/>
        <Toast open={showToast} onClose={() => setShowToast(false)} actionICon={'confirm'}>
            {showToast}
        </Toast>
    </>
};

export default Resign;
