import Request from './base/Request';

class PasswordChangeRequest extends Request {
    constructor(APIURL, body) {
        super(APIURL);

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };

        this.request = this.request.bind(this);
    }
}

export default PasswordChangeRequest;
