import React, {useState} from 'react'
import {BrowserRouter as Router, withRouter} from 'react-router-dom'
import {ThemeProvider, withStyles} from '@material-ui/core/styles';

import {ColorIcons, Icons, Button, Switch, Toast, Progress} from "components";
import {Modal, Backdrop, Fade} from '@material-ui/core'
import colors from 'styles/_colors.scss'

import {useMount} from "hooks";
import styles from './style.module.scss'
import InfoChangeModal from "components/InfoChangeModal";
import CheckPasswordModal from "components/CheckPasswordModal"

import API from "../../../api";
import ReportModal from "../components/ReportModal";

import parse from 'html-react-parser'
import packageJson from '../../../../package.json';

const Setting = ({store, history}) => {
    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);
    const [notificationIn, setNotificationIn] = useState(store.push.i);
    const [notificationOut, setNotificationOut] = useState(store.push.o);
    const [notificationReserve, setNotificationReserve] = useState(store.push.r);
    const [notification, setNotification] = useState(null);
    const [showReportModal, setShowReportModal] = useState(false);
    const passwordChecked = (()=> {
        setIsOpenPasswordModal(!isOpenPasswordModal);
        setIsOpenModal(!isOpenModal);
    })

    const [marketing, setMarketing] = useState(store.marketing);
    const handleChangeMarketing = async (event) => {
        const checked = !store.marketing;

        setMarketing(checked);
        store.setMarketing(checked)
    };

    const handleChangeIn = async (event) => {
        const checked = !store.push.i;

        const {
            code, message,
            elements: {elements: [{phone_number}, ...rest]},
        } = await API().partnerInfo(store.user.token);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            push: checked,
            kind: `p${phone_number}i`
        }))
        setNotificationIn(checked);
        store.setPush(checked, store.push.o, store.push.r, store.push.b)
    };

    const handleChangeOut = async (event) => {
        const checked = !store.push.o;
        const {
            code, message,
            elements: {elements: [{phone_number}, ...rest]},
        } = await API().partnerInfo(store.user.token);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            push: checked,
            kind: `p${phone_number}o`
        }))
        setNotificationOut(checked);
        store.setPush(store.push.i, checked, store.push.r, store.push.b)
    };
    const handleChangeReserve = async (event) => {
        const checked = !store.push.r;
        const {
            code, message,
            elements: {elements: [{phone_number}, ...rest]},
        } = await API().partnerInfo(store.user.token);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({
            push: checked,
            kind: `p${phone_number}r`
        }))
        setNotificationReserve(checked);
        store.setPush(store.push.i, store.push.o, checked, store.push.b)
    };

    const [isOpenTermModal, setIsOpenTermModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    function toggleModal() {
        setIsOpenTermModal(!isOpenTermModal)
    }

    const [agreeDescription, setAgreeDescription] = useState({description: '', title: ''});
    async function setDescription(termSeqid) {
        try {
            setIsLoading(true)
            const {code, description} = await API().termList("2", String(termSeqid + 1));
            if (code === 200) {
                let tmp = {description: description, title: agreeTitleArray[termSeqid]};
                setAgreeDescription(() => tmp);
                toggleModal(termSeqid);
            } else {
                console.error('fail to get agreement')
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    const [agreeArray, setAgreeArray] = useState([false,]);
    const [agreeTitleArray, setAgreeTitleArray] = useState([]);
    const [agreeNecessary, setAgreeNecessary] = useState([]);
    const getAllTitle = async () => {
        try {
            setIsLoading(true)
            const {code, title, message, elements} = await API().termList("1");
            // console.log(elements);
            if (code === 200) {
                elements.map(element => {
                    setAgreeTitleArray((agreeTitleArray) => [...agreeTitleArray, element.termTitle]);
                    setAgreeNecessary((agreeNecessary) => [...agreeNecessary, element.necessary]);
                    setAgreeArray((agreeArray) => [false, ...agreeArray]);
                })
            } else {
                console.log(code, title, message)
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    useMount(() => {
        getAllTitle();
    });

    return <>
        <div className={styles.Background}></div>
        <div className={styles.Body}>
            <div className={styles.TextWhiteTitle}>
                <h1 className={styles.Bold}> 더보기</h1>
                <button className={styles.alertNotification}
                        onClick={() => {
                            setShowReportModal(true);
                            setIsOpenReportModal(true);
                        }}>
                    {(notification || []).length > 0 ? <div className={styles.indicator}>{notification.length}</div> : ''}
                    <Icons name={'notification-icon'} size={20}/>
                </button>
            </div>
            <section className={styles.TextWhite}>
                <div className={styles.ProfileInformation}>
                    <ColorIcons name={'logo-default'} className={styles.Wapen}/>
                    <article className={styles.TextRight}>
                        <h2 className={styles.Bold} style={{fontSize:'1.25rem'}}>{store.user.name}님, 안녕하세요</h2>
                        <p style={{fontSize:'12px'}}>파킹프렌즈와 함께 오늘도 좋은하루!</p>
                    </article>
                </div>
                <div className={styles.BadgeName}>
                    <Icons name={'icon-pf'} className={styles.Badge}/>
                    <h2 className={styles.Bold} style={{fontSize:'1.25rem',marginLeft:'10px'}}>{store.user.name}님</h2>
                </div>
                <div className={styles.buttonGroup}>
                    <button onClick={() => setIsOpenPasswordModal(true)}className={styles.button}>
                        <span className="infoText">회원정보 수정</span>
                        <ColorIcons name={'logo-default'} className={styles.Badge}/>
                    </button>

                </div>

            </section>
            <section className={styles.setting}>
                <div className={styles.Wrapper}>
                    <ul className={styles.listContainer}>
                        <li className={styles.settingItem}>
                            <span className={styles.Bold} style={{fontSize:'1rem'}}>예약 알림</span>
                            {/* <input type="checkbox" checked={notificationReserve} onChange={handleChangeReserve} onClick={handleChangeReserve} name="isNotified"/> */}
                            <Switch checked={notificationReserve} onClick={handleChangeReserve}  name="isNotified"/>
                        </li>
                        <hr/>
                        <a href={'tel:1661-5806'}>
                            <li className={styles.settingItem}>
                                <span className={styles.Bold} style={{fontSize:'1rem'}}>전화문의</span>
                            </li>
                        </a>
                        <hr/>
                        {
                            agreeTitleArray.map((agree, index) =>
                                <div>
                                    <li className={styles.settingItem}>
                                        <span className={styles.Bold} style={{fontSize:'1rem'}}>{agreeTitleArray[index]}</span>
                                        <button 
                                            onClick={async () => {
                                                await setDescription(index);
                                                toggleModal(index);
                                            }}>
                                            <Icons name={'right-arrow'} size={11}/>
                                        </button>
                                    </li>
                                    <hr />
                                </div>
                            )
                        }
                        <li className={styles.settingItem}>
                            <span className={styles.Bold} style={{fontSize:'1rem'}}>앱 버전</span>
                            {packageJson.version}
                        </li>
                        <li className={styles.settingItem}>
                        
                        </li>
                        <li className={styles.settingItem}
                            onClick={() => {
                                localStorage.clear();
                                store.clearUser();
                                history.push('/login');
                            }}>
                            <span className={styles.Bold}></span>
                            <span variant="contained"
                                  color="primary"
                                  className={styles.flex}>
                       <span style={{color: '#72808E'}}>로그아웃 <Icons name={'right-arrow'} size={11}/></span>
                    </span>
                        </li>
                    </ul>
                </div>
            </section>
            <div className={styles.footer}>
                <p className={styles.gray}>(주)한컴모빌리티</p>
                <p className={styles.gray}> 사업자 등록번호 : 119-86-70032</p>
                <p className={styles.gray}>  주소 : 서울특별시 금천구 서부샛길 606, B동 1703-1</p>
                <p className={styles.gray}>  (가산동, 대성디폴리스) 대표전화 : 1661-5806</p>
            </div>
        </div>
        {/*<div className={styles.SettingContainer}>*/}
        {/*    <div className={styles.header}>*/}
        {/*        <ColorIcons name={'title-setting'} size={25} style={{marginRight: '9px'}}/>*/}
        {/*        설정*/}
        {/*    </div>*/}
        {/*    <div className={styles.Wrapper}>*/}
        {/*        <div className={styles.belong}>*/}
        {/*            <ColorIcons name={'logo-default'} className={styles.wapen}/>*/}
        {/*            {store.user.name}*/}
        {/*        </div>*/}

        {/*        <button onClick={() => setIsOpenPasswordModal(true)} style={{width: '100%', padding: '0px', margin: '0px'}}>*/}
        {/*            <div className={styles.item}>*/}
        {/*                회원정보 수정*/}
        {/*                <Icons size={10} color={colors.text} name={'link-right'}/>*/}
        {/*            </div>*/}
        {/*        </button>*/}

        {/*        <div className={styles.item}>*/}
        {/*            차량 주차 알림 (입차 시)*/}
        {/*            <Switch checked={notificationIn} onChange={handleChangeIn} onClick={handleChangeIn} name="isNotified"/>*/}
        {/*        </div>*/}
        {/*        <div className={styles.item}>*/}
        {/*            차량 주차 알림 (출차 시)*/}
        {/*            <Switch checked={notificationOut} onChange={handleChangeOut} onClick={handleChangeOut} name="isNotified"/>*/}
        {/*        </div>*/}

        {/*        <div className={styles.item}>*/}
        {/*            예약 알림*/}
        {/*            <Switch checked={notificationReserve} onChange={handleChangeReserve} onClick={handleChangeReserve} name="isNotified"/>*/}
        {/*        </div>*/}

        {/*        <a href={'tel:1661-5806'} className={styles.telephone}>*/}
        {/*            <div className={styles.item}>*/}
        {/*                전화문의*/}
        {/*                <Icons size={10} color={colors.text} name={'link-right'}/>*/}
        {/*            </div>*/}
        {/*        </a>*/}
        {/*    </div>*/}
        {/*    <Button variant="contained"*/}
        {/*            color="primary"*/}
        {/*            className={styles.button}*/}
        {/*            onClick={() => {*/}
        {/*                localStorage.clear();*/}
        {/*                store.clearUser();*/}
        {/*                history.push('/login');*/}
        {/*            }}>*/}
        {/*        로그아웃*/}
        {/*    </Button>*/}
        {/*</div>*/}
        <InfoChangeModal isOpenModal={isOpenModal} toggleModal={() => setIsOpenModal(!isOpenModal)} store={store} history={history}/>
        <CheckPasswordModal isOpenModal={isOpenPasswordModal} toggleModal={passwordChecked} goBack={() => setIsOpenPasswordModal(!isOpenPasswordModal)} store={store} history={history}/>

        <Toast open={showToast} actionICon={'confirm'} onClose={() => setShowToast(false)}>
            {showToast}
        </Toast>
        <ReportModal store={store} isOpenReportModal={isOpenReportModal} toggleModal={() => setIsOpenReportModal(!isOpenReportModal)}/>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenTermModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={isOpenTermModal}>
                <div className={styles.ModalContentContainer}>
                    <h1>
                        <button onClick={toggleModal}><Icons size={17} name={'icon-close'}/></button>
                        {agreeDescription.title}
                    </h1>
                    <div className={styles.detail}>
                        {parse(agreeDescription.description)}
                    </div>
                </div>
            </Fade>
        </Modal>
    </>
};

export default withRouter(Setting)
