import React, {useState} from 'react'
import styles from './style.module.scss'
import {ColorIcons, Button} from "components";


const RegisterDone = ({history, store}) => {
    const props = history.location;

    // const [showInfoChange, setShowInfoChange] = useState(false);
    return <>
        <div className={styles.RegisterDoneWrapper}>
            <div>
                <ColorIcons name={'emoticon-succeed'} size={52}/>
            </div>
            <h1>수고하셨습니다!</h1>
            <div className={styles.description}>
                파킹프렌즈 관리자의 승인이 필요하며,<br/>
                조금만 기다려주시면 빠르게 승인해드리겠습니다.
            </div>
            <div className={styles.InfoWrapper}>
                <div className={styles.header}>
                <span className={styles.underline}>
                    회원정보
                </span>
                </div>

                <div className={styles.infoDescription}>
                    <span>아이디</span>
                    {props.userId}
                </div>

                <div className={styles.infoDescription}>
                    <span>이름</span>
                    {props.name}
                </div>

                <div className={styles.infoDescription}>
                    <span>휴대폰 번호</span>
                    {props.phoneNumber}
                </div>

                {props.district ?
                    <div className={styles.infoDescription}>
                        <span>소속 지자체</span>
                        {props.district}
                    </div> : null}

                {["ASSIGNEE"].includes(props.userType)?
                    <div>
                        <hr/>
                        {props.assigneeType ?
                            <div className={styles.infoDescription}>
                                <span>전일/주간/야간</span>
                                {printType(props.assigneeType)}
                            </div> : null}

                        {props.carNum ?
                            <div className={styles.infoDescription}>
                                <span>차량번호</span>
                                {props.carNum}
                            </div> : null}

                        {props.areaNum ?
                            <div className={styles.infoDescription}>
                                <span>구간번호</span>
                                {props.areaNum}
                            </div> : null}

                        {props.slotNum ?
                            <div className={styles.infoDescription}>
                                <span>구획번호</span>
                                {props.slotNum}
                            </div> : null}

                        {props.startDate ?
                            <div className={styles.infoDescription}>
                                <span>배정시작일</span>
                                {props.startDate}
                            </div> : null}

                        {props.endDate ?
                            <div className={styles.infoDescription}>
                                <span>배정종료일</span>
                                {props.endDate}
                            </div> : null}

                        {props.fee ?
                            <div className={styles.infoDescription}>
                                <span>배정료</span>
                                {props.fee}
                            </div> : null}
                    </div> : null}

                <div>
                    {(props.bank || props.accountNumber || props.accountHolder)?
                        <div>
                            <hr/>
                            <div className={styles.header}>
                            <span className={styles.underline}>
                                수익금 입금 정보
                            </span>
                            </div>
                        </div> : null}
                    {props.bank?
                        <div className={styles.infoDescription}>
                            <span>은행</span>
                            {props.bank}
                        </div> : null}
                    {props.accountNumber?
                        <div className={styles.infoDescription}>
                            <span>계좌번호</span>
                            {props.accountNumber}
                        </div> : null}

                    {props.accountHolder ?
                        <div className={styles.infoDescription}>
                            <span>예금주</span>
                            {props.accountHolder}
                        </div> : null}
                </div>

                {(props.manager || props.birthday )?
                <div>
                    <hr/>
                    <div className={styles.header}>
                    <span className={styles.underline}>
                        추가정보
                    </span>
                    </div>

                {props.manager ?
                    <div className={styles.infoDescription}>
                        <span>사업팀 담당자</span>
                        {props.manager}
                    </div> : null}

                {props.birthday ?
                    <div className={styles.infoDescription}>
                        <span>생년월일</span>
                        {props.birthday}
                    </div> : null}
                </div> : null }

                {/* <Button variant="outlined" color="primary" onClick={()=>setShowInfoChange(true)}>
                    회원정보수정
                </Button> */}
                <br/>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        history.push('/login')
                    }}>
                    로그인하기
                </Button>
            </div>
        </div>
        {/* <InfoChangeModal isOpenModal={showInfoChange} toggleModal={() => setShowInfoChange(prev=> !prev)} store={store}/> */}
    </>;
};

export default RegisterDone;

