import React from "react";
import {Icons} from "../index";

import styles from './style.module.scss'

const MonthPicker = ({handleDateButton, calDate}) => <div className={styles.MonthSelector}>
    <button onClick={e => handleDateButton(e, true)}>
        <Icons name={'link-left'} size={11}/>
    </button>
    {calDate.slice(0, 4)}년 {calDate.slice(-2)}월
    <button onClick={e => handleDateButton(e, false)}>
        <Icons name={'link-right'} size={11}/>
    </button>
</div>

export default MonthPicker;
