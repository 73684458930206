import React from 'react'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import Icons from "../Icons";

const TrailButton = ({children, className, iconSize, iconStyle, color, ...rest}) => {

    return <button {...rest} className={
        className ?
            [styles.trailButtonContainer, className].join(' ')
            : styles.trailButtonContainer}>
        {children}
        <Icons color={color||colors.text}
               size={15}
               name={'link-right'}
               style={{marginLeft: '22px', ...iconStyle}}/>
    </button>
};

export default TrailButton;
