import Report from 'pages/Officer/ReportCar'
import UseStatus from "pages/Officer/UseStatus";
import UseDetail from "pages/Partner/UseStatus/UseDetail";
import ShareDetail from "pages/Partner/UseStatus/ShareDetail";
import BenefitAdjust from "pages/Officer/BenefitAdjust";
import Setting from "pages/Partner/Setting";
import Footer from "pages/Partner/Footer";
import Partner from "pages/Partner";


const BASE_URI = '/Partner';

const subRoutes = [
    {
        path: BASE_URI,
        component: Partner,
        exact: true,
    },
    {
        path: BASE_URI + '/parkcheck',
        component: Report,
        exact: true,
    },
    {
        path: BASE_URI + '/parkcheck/:selected',
        component: Report,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus',
        component: UseStatus,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus/usedetail',
        component: UseDetail,
        exact: true,
    },
    {
        path: BASE_URI + '/benefitadjust',
        component: BenefitAdjust,
        exact: true,
    },
    {
        path: BASE_URI + '/benefitadjust/:selected',
        component: BenefitAdjust,
        exact: true,
    },
    {
        path: BASE_URI + '/setting',
        component: Setting,
        exact: true,
    },
]

const PartnerRoutes =
    [{
        path: BASE_URI,
        subRoutes,
        footer: Footer
    }];

export default PartnerRoutes;
