import CheckCar from "pages/PManager/CheckCar";
import Setting from 'pages/PManager/Setting'

const BASE_URL = '/PManager';

const PManagerRoute = [{
    path: BASE_URL,
    component: CheckCar,
    exact: true,
}, {
    path: BASE_URL + '/setting',
    component: Setting,
    exact: true,
}];

export default PManagerRoute
