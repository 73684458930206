import React, {useEffect, useState} from 'react'
import styles from './style.module.scss'
import {ColorIcons, Icons, TrailButton} from "components";
import ShareModal from "../ShareModal";
import API from 'api';
import MonthPicker from "components/MonthPicker";
import useMonthSelector from "components/MonthPicker/hooks";
import {numberWithCommas} from "utils";

const Share = ({store}) => {
    const [elements, setElements] = useState();
    const [sumShareTime, setSumShareTime] = useState();
    const [calDate, handleDateButton] = useMonthSelector();
    const [showShareModal, setShowShareModal] = useState(false)

    const today = `${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}-${('0' + (new Date().getDate())).slice(-2)}`;

    function getMonth() {
        const month = new Date(calDate);
        return month.getMonth() + 1;
    }

    function getYear() {
        const year = new Date(calDate);
        return year.getFullYear();
    }

    function isWeekend(inputDate) {
        const date = new Date(inputDate);
        return date.getDay() === 6 || date.getDay() === 0;
    }

    async function handleDateChange() {
        try {
            const {code, elements} = await API().shareList(store.user.token, calDate);
            if (code === 200) {
                setSumShareTime(elements.sumShareTime);
                elements.elements.reverse();
                setElements(elements.elements);
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        handleDateChange();
    }, [calDate]);
    return <>
        <div className = {styles.BackGround}></div>

        <div className = {styles.Body}>
            <header className = {styles.Text}>
                <h1>
                    실적현황
                </h1>
            </header>


            <section className = {styles.textWhite}>
                <div className = {styles.pointInformation}>
                    <ColorIcons name={'logo-default'} size={70}/>
                    <article className = {styles.textRight}> 
                        <h2> 5건</h2>
                        <p>이번달 이용건수</p>
                    </article>
                    <article className = {styles.textRight}>
                        <h2>189시간</h2>
                        <p>총 누적 공유시간</p>
                    </article>
                </div>

                <div className = {styles.badgeName}>
                    <Icons name={'icon-pf'}  className={styles.badage} alt={"뱃지"}/>
                    <h2>도종혁님</h2>
                </div>

                <div className = {styles.buttonGroup}>
                    <button className = {styles.sharingStatus}>
                        <span className = {styles.bold}>
                            공유현황
                        </span>
                        {/* <img src = "../../_common/icons/right-arrow-icon.svg" alt = "바로가기 아이콘"> </img> */}
                    </button>

                    <button className = {styles.sharingStatus}> 
                        <span className = {styles.bold}>
                                이용현황
                        </span>
                        {/* <img src = "../../_common/icons/right-arrow-icon.svg" alt = "바로가기 아이콘"> </img> */}
                    </button>
                </div>
            </section>

            <section className = {styles.result}>
                <h3 className = {styles.whiteBold}>월별 주차면 공유현황</h3>
                
                <div className ={styles.calender}>
                    <div></div>
                    <span className = {styles.textBlack}>{calDate ? getYear() + '년 '+ getMonth() : ''}월 </span>
                    <div></div>
                </div>

                <div className = {styles.shareContainer}>
                    <div className = {styles.flex}>
                        <span className ={styles.h3Bold}>
                            {calDate ? getYear() + "-" + getMonth() : ''}
                        </span>
                    </div>

                    <div className = {styles.m15Grid}>
                        <h2>
                            전체 공유시간
                        </h2>

                        <h2>
                            <span className = {styles.blue}>
                                {sumShareTime ? numberWithCommas(sumShareTime) : '0'}
                            </span>
                            시간
                        </h2>
                    </div>
                    {
                    (elements || []).filter(element => element.shareTime || element.shareDate !== today).map((element, index) =>
                        <div key={index} className={styles.timeWrapper}>
                            <div className={styles.flex}>
                                <div className = {styles.h3}>
                                    {element.shareDate !== today ? element.shareDate : null }
                                </div>

                                <div className={styles.h3Bold}>
                                    {element.shareDate !== today ? element.shareTime === null ? 0 : element.shareTime : null} <span className = {styles.black}>시간 </span>
                                </div>
                            </div>
                        </div>
                    )
                }
                </div>
            </section>
        </div>

        {/* <div className={styles.HomeContainer}>
            <h1 className={styles.HeaderContainer}>
                <ColorIcons name={'title-uselist'} size={25}/>
                <span className={styles.PageTitle}>공유 현황</span>
            </h1>

            <MonthPicker calDate={calDate} handleDateButton={handleDateButton}/>

            <div className={styles.DetailShareContainer}>
                <div className={styles.totalShareContainer}>
                    <div className={styles.title}>
                         {calDate ? getYear() + '년 '+ getMonth() : ''}월 전체 공유 시간
                    </div>
                    <div className={styles.totalShareTime}>{sumShareTime ? numberWithCommas(sumShareTime) : '0'} 시간</div>
                </div>
                {
                    (elements || []).filter(element => element.shareTime || element.shareDate !== today).map((element, index) =>
                            <div key={index} className={styles.Card}>
                                <div className={styles.LMa}>
                                <div className={styles.m}></div>
                                {element.shareDate !== today ?  <div className={styles.log}>
                                        {element.shareDate}
                                </div>: null
                                }
                            {element.shareDate !== today ?
                                <span
                                    className={styles.bold}>{element.shareTime === null ? 0 : element.shareTime} 시간
                                </span> : null
                            }
                        </div>
                        </div>
                    )
                }
            </div>
        </div> */}
        <ShareModal isOpenModal={showShareModal} toggleModal={() => setShowShareModal(!showShareModal)} store={store}/>
    </>
};

export default Share;
