import React, {useEffect, useState} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import classnames from 'classnames/bind'
import styles from './style.module.scss'
import Icons from "components/Icons";
import colors from "styles/_colors.scss";
import GridButtons from "components/GridButtons";
import {useMount} from "hooks";
import API from "api";
import {Progress} from "../../../components";

const cx = classnames.bind(styles)

const SelectBelong = ({isOpenModal, toggleModal, getBelong}) => {
    const [step, setStep] = useState([null, null]);
    const [step0Items, setStep0Items] = useState([]);
    const [step1Items, setStep1Items] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useMount(() => {
        const getDistrictList = async () => {
            try {
                setIsLoading(true)

                API().districtList(1).then(
                    ({elements}) => setStep0Items(elements))
            } catch (e) {

            } finally {
                setIsLoading(false)
            }
        }
        getDistrictList();
    });

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={isOpenModal}
            onClose={() => {
                getBelong([step0Items[step[0]], step1Items[step[1]]]);
                toggleModal();
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={isOpenModal}>
                <div className={styles.BelongingContainer}>
                    <h1>
                        <div className={styles.backbutton}>
                            <button onClick={toggleModal}>
                                <Icons name={'icon-close'} size={24} color={colors.text}/>소속 지자체
                            </button>
                        </div>
                        <div className={styles.backbutton}>
                            <button className={cx(styles.indicator, {selected: step[0] === null})}>1</button>
                            <button className={cx(styles.indicator, {selected: step[0] !== null})}>2</button>
                        </div>
                    </h1>
                    <div className={styles.legend}>
                        관할구의 {step[0] === null ? '시/도' : '구/군/시'}를 선택해주세요
                    </div>
                    <div>
                        {
                            step[0] === null ? <GridButtons
                                items={step0Items.map(district => district.districtTitle)}
                                onChange={index => {
                                    API().districtList(2, step0Items[index].districtSeqid).then(
                                        ({elements}) => setStep1Items(elements[0]))
                                    setStep(prev => [index, prev[1]])
                                }}
                                selectedIndex={step[0]}/> : <button onClick={() => {
                                setStep([null, null])
                            }} className={styles.confirmed}>{step0Items[step[0]].districtTitle}</button>
                        }
                        {
                            step[1] === null ?
                                step[0] === null ? null
                                    : <div style={{marginBottom: '20px'}}>
                                        <GridButtons
                                            items={step1Items.map(item => item.districtTitle)}
                                            onChange={index => {
                                                setStep(prev => [prev[0], index])
                                                getBelong([step0Items[step[0]], step1Items[index]]);
                                                toggleModal();
                                            }}
                                            selectedIndex={step[1]}/>
                                    </div>
                                : <button onClick={() => {
                                    setStep([null, null])
                                }} className={styles.confirmed}>{step1Items[step[1]].districtTitle}</button>
                        }
                    </div>
                </div>
            </Fade>
        </Modal>
        <Progress isLoading={isLoading}/>
    </>
};

export default SelectBelong;
