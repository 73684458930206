import React, {useState} from "react";
import colors from 'styles/_colors.scss'
import styles from "./style.module.scss";
import {ColorIcons, Accordian} from "components";

import {numberWithCommas} from "utils";
import API from "../../../api";
import {element} from "prop-types";

const Receipt = ({income, outcome, s_value, vat, calRate, partner_income, pg, remittance}) => (
    <>
        <div className={styles.info}>
            <div className={styles.accent}> 월 정산액 </div>
            {/* <div className={styles.accent}>정산지급일 2020.07.15</div>
            <div>{numberWithCommas(income||'0')}원</div> */}
            <div>{numberWithCommas(remittance||'0')}원</div>
        </div>
        {/*<div className={styles.help}>*/}
        {/*    내 수익 계산법 = [(&nbsp;*/}
        {/*    <div className={styles.circlarNumber}>1</div>*/}
        {/*    &nbsp;*/}
        {/*    - &nbsp;*/}
        {/*    <div className={styles.circlarNumber}>2</div>*/}
        {/*    &nbsp; x {calRate || 0} ) -&nbsp;*/}
        {/*    <div className={styles.circlarNumber}>3</div>*/}
        {/*</div>*/}
        <div className={styles.item}>
            <div>월매출</div>
            <div>{numberWithCommas(income||'0')}원</div>
        </div>
        <div className={styles.item}>
            <div>
                {/*/!*<div className={styles.circlarNumber}>1</div>*!/*/}
                {/*&nbsp;*/}
                공급가액
            </div>
            <div>{numberWithCommas(s_value||'0')}원</div>
        </div>
        <div className={styles.item}>
            <div>부가세</div>
            <div>{numberWithCommas(vat||'0')}원</div>
        </div>
        {<div className={styles.item}>
            <div>
                {/*<div className={styles.circlarNumber}>2</div>*/}
                {/*&nbsp;*/}
                유지관리비
            </div>
            <div>{numberWithCommas(outcome||'0')}원</div>
        </div>}

        <div className={styles.item}>
            <div>
                {/*<div className={styles.circlarNumber}>3</div>*/}
                {/*&nbsp;*/}
                PG수수료 {/*<span>* 센서, CCTV, 보험료</span> */}
            </div>
            <div>{numberWithCommas(pg||'0')}원</div>
        </div>


        {<div className={styles.item}>
            <div>
                수입 <span>{false ? '*'.concat(calRate * 100 || 0, '%가 원천징수된 금액') : ''}</span> {/*calRate 몰라서 숨김 처리 해둠 */}
            </div>
            <div>{numberWithCommas(income||'0')}원</div>
        </div>}

        {partner_income? <div className={styles.item}>
            <div>내 수익</div>
            <div>{numberWithCommas(partner_income||'0')}원</div>
        </div>:null}
        <div className={styles.item}>
            <div>입금 금액</div>
            <div>{numberWithCommas(remittance||'0')}원</div>
        </div>
        <hr/>

    </>
);

const SearchAdjustExpand = ({...rest}) => {
    return <div className={styles.dashboard}>
        <Receipt {...rest}/>
    </div>
};

export default SearchAdjustExpand;
