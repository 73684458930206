import React from 'react'
import { Alert } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import styles from './style.module.scss'
import Icons from "components/Icons";
import colors from "styles/_colors.scss";
import TrailButton from "components/TrailButton";

const userKindList = [
    {parkName: '거주자 우선주차 배정자', userType: "ASSIGNEE"},
    {parkName: '지자체(구청/공단 등)', userType: "OFFICER"},
    // {parkName: '주차 운영사', userType: "COMPANY"},
    {parkName: '주차장 관리자', userType: "PMANAGER"},
    {parkName: '주차 단속반', userType: "ILLEGAL"},
    {parkName: '일반 파트너', userType: "PARTNER"},
    // {parkName: '아르바이트생', userType: "ALBA"},
];

const UserKind = ({history}) => {
    const marketingAgreement = history.location.state.marketingAgreement;

    return <div className={styles.UserTypeContainer}>
        <div className={styles.titleHeading}>
            <div>
                <button className={styles.backbutton} onClick={() => history.goBack()}>
                    <Icons name={'back'} size={24} color={colors.text}/>
                </button>
            </div>

            <h1>
                어떤 파트너신가요?
            </h1>

            <div className={styles.Items}>
                {userKindList.map(kind =>
                    //(kind.userType === "ASSIGNEE") ?
                    <TrailButton key={kind.userType} value={kind.userType} style={kind.userType!=="ASSIGNEE"? {marginLeft: '0px', color:'darkgray'} : {marginLeft: '0px'}}
                        onClick={(kind.userType !== "ASSIGNEE") ? null : (e) => {
                            history.replace({
                                pathname: '/register/info/'+kind.userType,
                                state: {
                                    value: e.target.value,
                                    marketingAgreement: marketingAgreement
                                }
                            })
                        }}>{kind.parkName}<span><i className="fa fa-address-card"  style={{marginLeft: '6px', marginRight: '6px', fontSize:'small', 
                            color:kind.userType!=="ASSIGNEE"? 'darkgray' : '#82c91e'
                        }}/></span></TrailButton>
                    //: null
                )}
            </div>
            
            <Alert variant="success" className={styles.noticeWarning}>
                
                <ul className="fa-ul">
                    <li className={styles.keyword}>
                    {/* <i class="fa fa-exclamation-triangle fa-li fa-lg"></i> */}
                    <i className="fa fa-info-circle fa-lg fa-li" aria-hidden="true"></i>
                    지금은 <span className={styles.underline}>거주자 우선주차 배정자</span>분들만을 위한 회원 가입을 받고 있습니다. 다른 파트너 회원님들은 밑의 콜 센터로 전화 주시면 아이디 생성을 해 드리고 있습니다.</li>
                </ul>
            </Alert>
            <a href={'tel:1661-5806'} className={styles.telephone}>
                    <div className={styles.item}>
                        전화문의
                        <Icons size={10} color={colors.text} name={'link-right'}/>
                    </div>
            </a>
        </div>
    </div>;
};

export default UserKind;
