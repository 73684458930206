import {useState} from 'react'

const useMonthSelector = () => {
    const [calDate, setCalDate] = useState(`${new Date().getFullYear()}-${('0' + (new Date().getMonth() + 1)).slice(-2)}`);


    function handleDateButton(e, isPrev) {
        // e.preventDefault();
        setCalDate(prev => {
            const targetDate = new Date(prev);
            if (isPrev)
                targetDate.setMonth(targetDate.getMonth() - 1);
            else {
                if (targetDate.getFullYear() + (('0' + (targetDate.getMonth() + 1)).slice(-2))
                    >= new Date().getFullYear() + (('0' + (new Date().getMonth() + 1)).slice(-2))){
                    return prev
                }
                targetDate.setDate(1);
                targetDate.setMonth(targetDate.getMonth() + 1);
            }
            return `${targetDate.getFullYear()}-${('0' + (targetDate.getMonth() + 1)).slice(-2)}`
        });
    }

    return [calDate, handleDateButton]
};

export default useMonthSelector;
