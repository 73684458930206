import React, {useState, useEffect} from "react";
import {Accordian, ColorIcons, Progress, Toast} from "components";
import styles from "./style.module.scss";
import Expand from "./Expand";
import {useMount} from "hooks";
import API from "api";


const Reserve = ({store}) => {
    const [reservationList, setReservationList] = useState()
    const [now, setNow] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [location, setLocation] = useState();
    let pos;
    useMount(() => {

        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({geolocation: true}));
        getLocation();
 
        const handlerID = setInterval(() => {
            const now = new Date();
            setNow(`${now.getHours() <= 12 ? '오전' : '오후'} ${now.getHours() % 12}:${('0' + now.getMinutes()).slice(-2)}`)
        }, 3000)
        return () => {
            clearInterval(handlerID)
        }
    });

    useEffect(() => {
        getReservationInfo()
    }, [location]);

    const getReservationInfo = async () => {
        try {
            setIsLoading(true)
            const {elements: {pElements}, code, message} = await API().reservationList(store.user.token, 'a', '', location.longitude, location.latitude)
            if (code === 200) {
                setReservationList(pElements)
            }
            //console.log(pElements)
            setShowToast(message);
        } catch (e) {
            // setShowToast(e);
        } finally {
            setIsLoading(false)
        }
    }

    async function getParkinglotReservation(index, seqID) {
        try {
            setIsLoading(true)
            const {elements: {rElements}, code, message} = await API().reservationList(store.user.token, 'p', seqID)
            if (code === 200)
                setReservationList(prev => {
                    prev[index] = {...reservationList[index], ...rElements};
                    return prev;
                });
            setShowToast(message);
        } catch (e) {
            // setShowToast(e)
        } finally {
            setIsLoading(false)
        }
    }
    
    const getLocation = () => {
        if (!navigator.geolocation) {
            console.log("gps error")
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                setLocation({longitude : position.coords.longitude , latitude : position.coords.latitude})
                pos = position;
            });
            return pos
        }
    }

    return <>
        <div className={styles.ReserveContainer}>
            <h1 className={styles.HeaderContainer}>
                <ColorIcons name={'title-reservesearch'} size={25}/>
                예약조회
            </h1>

            <div style={{textAlign: 'center'}}>
                <div>현재시간</div>
                <div className={styles.time}>{now}</div>
            </div>
            
            {reservationList ? reservationList.map((parkinglot, index) => {
                //console.log(parkinglot);
                let distance = '알 수 없음';
                // if (location) {
                //             const radlat1 = Math.PI * parkinglot.latitude/180;
                //             const radlat2 = Math.PI * location.latitude/180;
                //             const theta = parkinglot.longitude-location.longitude;
                //             const radtheta = Math.PI * theta/180;
                //             distance = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
                //             //console.log("lot " + radlat1 + " lot2 "+  theta) 
                //             distance = Math.acos(distance);
                //             distance = distance * 180/Math.PI;
                //             distance = distance * 60 * 1.1515;
                //             distance = distance * 1609.344;  // meter   
                //             distance = parseInt(distance) + 'm';
                // }
                let a;
                return parkinglot && <div key={parkinglot.parkinglotSeqid + '-' + index} style={{marginBottom: '10px'}}>
                    <Accordian
                        onClick={() => getParkinglotReservation(index, parkinglot.parkinglotSeqid)}
                        end={<span>{parkinglot.distance + "m"}</span>}
                        expandItem={(parkinglot.rElement || []).map(reservation => {
                            return <div style={{marginBottom: '10px'}}>
                                <Expand {...reservation}/>
                            </div>
                        })}
                    >
                    {a = parkinglot.parkinglotName.replace(/\[.*\]/gi, "")}
                    </Accordian>
                </div>
            }) : ''}
            
        </div>
        <Progress isLoading={isLoading}/>
        <Toast open={showToast} onClose={() => {
            setShowToast(false)
        }} actionICon={'confirm'}>
            {showToast}
        </Toast>
    </>;
}

export default Reserve;
