import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'

import API from 'api'
import {useMount} from "hooks";

import {Backdrop, Fade, Modal} from '@material-ui/core'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {Accordian, CheckButton, ColorIcons, Icons, Progress, TrailButton} from "components";

import SearchModal from '../components/SearchModal'
import ReportModal from "../components/ReportModal";
import SearchHeader from "../components/SearchHeader";
import {element} from "prop-types";


const Expand = ({toggleModal, group, pElements, store}) => {
    const [parkList, setParkList] = useState({})

    return <div className={styles.ParkDetailContainer}>
            {/*<div className={styles.suspicious}>*/}
            {/*    <div className={styles.title}*/}
            {/*        onClick={toggleModal}*/}
            {/*        style={{paddingLeft: '21px'}}*/}
            {/*        iconStyle={{marginLeft: '3px', height: '10px'}}*/}
            {/*        color={colors['place-holder']}>*/}
            {/*        부정주차 의심대수*/}

            {/*    <div className={styles.accent}>최대 {(group || {}).illegalCount}대</div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/* 전체주차장 정보 */}
            <div className={styles.info}>
                <div className={styles.detailDashboard}>
                    <div>
                        <article>
                            <h4 className={styles.detailTitle}>부정주차 의심대수</h4>
                            <span className={styles.bold}>최대 {(group || {}).illegalCount}대</span>
                        </article>
                    </div>
                <div className={styles.detailNumberWrapper}>
                    <article>
                        <h4 className={styles.detailTitle}>주차면수</h4>
                        <span className={styles.bold}>{(group || {}).contractLot}면</span>
                    </article>
                    <article>
                        <h4 className={styles.detailTitle}>점유면수</h4>
                        <span className={styles.bold}> {(group || {}).occupiedLot}면</span>
                    </article>
                    <article>
                        <h4 className={styles.detailTitle}>운영면수</h4>
                        <span className={styles.bold}>{(group || {}).serviceLot}면</span>
                    </article>
                    <article>
                        <h4 className={styles.detailTitle}>예약면수</h4>
                        <span className={styles.bold}>{(group || {}).reservedLot}면</span>
                    </article>
                </div>
                {/*<div>*/}
                {/*    <div>주차면수</div>*/}
                {/*    <span>{(group || {}).contractLot} 면</span>*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*    <div>운영면수</div>*/}
                {/*    {(group || {}).serviceLot} 면*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*    <div>점유면수</div>*/}
                {/*    {(group || {}).occupiedLot} 면*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    <div>예약면수</div>*/}
                {/*    {(group || {}).reservedLot} 면*/}
                {/*</div>*/}
            </div>
        </div>


        {pElements && pElements.map(pElement => <div key={pElement.parkinglotSeqid} style={{marginBottom: '10px'}}
                                                     className={styles.dashboard}>
            <Accordian
                style={{marginTop:'20px',padding: '0 15px', backgroundColor: '#eff2f5'}}
                onClick={async () => {
                    try {
                        const {code, title, message, ...rest} = await API().illegalSummary(
                            store.user.token, 'p', null, pElement.parkinglotSeqid);
                        setParkList({...parkList, [pElement.parkinglotSeqid]: rest});
                        return {...rest};
                    } catch (e) {
                        console.error(e)
                    } finally {
                    }
                }}
                expandItem={
                    <div style={{
                        padding: '10px 15px',
                        backgroundColor: '#eff2f5',
                    }} className={styles.info}>
                        <div>
                            <article>
                                <h4 className={styles.detailTitle}>부정주차 의심대수</h4>
                                <span className={styles.bold}>최대 {pElement.illegalCount}대</span>
                            </article>
                        </div>
                        <div className={styles.detailNumberWrapper}>
                            <article>
                                <h4 className={styles.detailTitle}>주차면수</h4>
                                <span className={styles.bold}>{(parkList[pElement.parkinglotSeqid] || {}).contractLot}면</span>
                            </article>
                            <article>
                                <h4 className={styles.detailTitle}>점유면수</h4>
                                <span className={styles.bold}> {(parkList[pElement.parkinglotSeqid] || {}).occupiedLot}면</span>
                            </article>
                            <article>
                                <h4 className={styles.detailTitle}>운영면수</h4>
                                <span className={styles.bold}>{(parkList[pElement.parkinglotSeqid] || {}).serviceLot}면</span>
                            </article>
                            <article>
                                <h4 className={styles.detailTitle}>예약면수</h4>
                                <span className={styles.bold}> {(parkList[pElement.parkinglotSeqid] || {}).reservedLot}면</span>
                            </article>
                        </div>
                        {/*<div>*/}
                        {/*    <div>주차면수</div>*/}
                        {/*    <span>{(parkList[pElement.parkinglotSeqid] || {}).contractLot} 면</span>*/}
                        {/*</div>*/}

                        {/*<div>*/}
                        {/*    <div>운영면수</div>*/}
                        {/*    {(parkList[pElement.parkinglotSeqid] || {}).serviceLot} 면*/}
                        {/*</div>*/}

                        {/*<div>*/}
                        {/*    <div>점유면수</div>*/}
                        {/*    {(parkList[pElement.parkinglotSeqid] || {}).occupiedLot} 면*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <div>예약면수</div>*/}
                        {/*    {(parkList[pElement.parkinglotSeqid] || {}).reservedLot} 면*/}
                        {/*</div>*/}
                    </div>
                }
                end={
                    <span className={styles.suspicious} style={{fontSize: '0.75rem'}}>
                    <span style={{color: '#007BFF',fontWeight:'600'}}>{pElement.illegalCount}대</span> 추정
                </span>
                }>
                <div className={styles.category}>
                    <div className={styles.bold} style={{fontSize:'0.75rem'}}>
                    <ColorIcons name={'solo'} size={27}/>
                    {pElement.parkinglotName}
                    </div>
                </div>

            </Accordian></div>)}

    </div>;
};

const Report = ({store, match}) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isZeroButton, setIsZeroButton] = useState(false);
    const [gElements, setgElements] = useState();
    const [illegalSummary, setIllegalSummary] = useState({});
    const [groupList, setGroupList] = useState({});
    const [pElements, setpElements] = useState();
    const [selected, setSelected] = useState({});

  const handIsZeroButton =() =>{

    }


    const getGroupSummaryInfo = async groupSeqid => {
        try {
            setIsLoading(true);
            const {pElements, ...rest} = await API().illegalSummary(
                store.user.token, 'g', groupSeqid);

            return {pElements, ...rest};
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false);
        }
    };

    const getIllegalStatus = async () => {
        try {
            console.log("Test get")
            setIsLoading(true)
            const {gElements, message, code, ...rest} = await API().illegalSummary(store.user.token, 'a');

            if (code === 200) {
                setIllegalSummary(rest);
                if (isZeroButton === false) {
                    setgElements(gElements.filter(item =>(item.illegalCount!==0)))
                }else {
                    setgElements(gElements);
                }

            } else {
                console.log(message);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        console.log("test",selected);
        if (selected) {
            getIllegalStatus();
        }
        onSelectSearch(selected)
    }, [isZeroButton]);

    function toggleModal() {
        setIsOpenModal(!isOpenModal)
    }

    const onSelectSearch = async selected => {
        console.log("Test Search")
        if (!selected) {
            getIllegalStatus();
            setSelected({});
        } else {
            setSelected(selected);
            if (selected.parkinglotSeqid) {
                setIsLoading(true);
                try {
                    const {code, title, message, ...rest} = await API().illegalSummary(
                        store.user.token, 'p', null, selected.parkinglotSeqid);
                    console.log("test:",gElements)
                    if (isZeroButton === false) {
                        setIllegalSummary({...rest, ...selected});
                        setpElements([]);
                        setgElements(pElements.filter(item =>(item.illegalCount!==0)))
                        setGroupList({});
                    }
                    setIllegalSummary({...rest, ...selected})
                    setgElements([])
                    setpElements([])
                    setGroupList({})
                } catch (e) {
                    // setShowToastMessage(e)
                } finally {
                    setIsLoading(false);
                }
            } else if (selected.groupSeqid) {
                try {
                    setIsLoading(true)
                    const {pElements, code, message, ...rest} = await API().illegalSummary(
                        store.user.token, 'g', selected.groupSeqid);
                    if (isZeroButton === true) {
                        setIllegalSummary({...rest, ...selected});
                        setpElements([]);
                        setgElements(pElements.filter(item =>(item.illegalCount!==0)))
                        setGroupList({});
                    }
                    else {
                        setIllegalSummary({...rest, ...selected});
                        setpElements([]);
                        setgElements(pElements);
                        setGroupList({});
                    }
                } catch (e) {

                } finally {
                    setIsLoading(false)
                }
// setGroupList();
            }
        }

    }


    return <>
        <div className={styles.background}></div>
        <div className={styles.body}>
            <SearchHeader setShowSearchModal={setShowSearchModal}
                          setShowReportModal={setShowReportModal}
                          store={store}
                          selected={selected}
            />
            <section className={styles.illegalStatistics}>
                <div className={styles.information}>
                    <ColorIcons name={'logo-default'} size={70}/>
                <article className={styles.textRight}>
                    <h1 className={styles.bold}>
                        {illegalSummary.illegalRate}%
                    </h1>
                    <div style={{fontSize: '12px'}}>
                        부정주차 비율
                    </div>
                </article>
                </div>
                <div className={styles.badge} >
                    <ColorIcons name={selected.groupName ? 'group' : selected.parkinglotName ? 'solo' : 'all'} size={30} style={{marginRight: '8px'}}/>
                    <h1 className={styles.bold} style={{marginLeft:'10px'}}> {selected.parkinglotName || selected.groupName || '전체주차장'} </h1>
                </div>
            </section>
            <section className={styles.parkingDashboard}>
                <div>
                    <article>
                        <h4 className={styles.title}>부정주차 의심대수</h4>
                        <span className={styles.bold}>최대 {illegalSummary.illegalCount}대</span>
                    </article>
                </div>
                <div className={styles.numberWrapper}>
                    <article>
                        <h4 className={styles.title}>주차면수</h4>
                        <span className={styles.bold}>{illegalSummary.contractLot || 0}면</span>
                    </article>
                    <article>
                        <h4 className={styles.title}>점유면수</h4>
                        <span className={styles.bold}> {illegalSummary.occupiedLot || 0}면</span>
                    </article>
                    <article>
                        <h4 className={styles.title}>운영면수</h4>
                        <span className={styles.bold}> {illegalSummary.serviceLot || 0}면</span>
                    </article>
                    <article>
                        <h4 className={styles.title}>예약면수</h4>
                        <span className={styles.bold}> {illegalSummary.reservedLot || 0}면</span>
                    </article>
                </div>
            </section>
            <section className={styles.illegalParking}>
                    {
                        gElements && gElements.map((groupSummary, index) => {
                            return <div style={{marginBottom: '10px'}}>
                                <Accordian
                                    expandItem={<Expand
                                        store={store}
                                        toggleModal={toggleModal}
                                        group={groupList[groupSummary.groupSeqid] || groupList[groupSummary.parkinglotSeqid]}
                                        pElements={(groupList[groupSummary.groupSeqid] || groupList[groupSummary.parkinglotSeqid] || {}).pElements || []}/>}
                                    onClick={async () => {
                                        if (groupSummary.groupSeqid) {
                                            const a = await getGroupSummaryInfo(groupSummary.groupSeqid)
                                            if (isZeroButton === false) {
                                                a.pElements= a.pElements.filter(item =>(item.illegalCount!==0))
                                                setGroupList({
                                                    ...groupList,
                                                    [groupSummary.groupSeqid]: {...groupSummary, ...a}
                                                });
                                            }else {
                                                setGroupList({
                                                    ...groupList,
                                                    [groupSummary.groupSeqid]: {...groupSummary, ...a}
                                                });
                                            }
                                        } else if (groupSummary.parkinglotSeqid){
                                            try {
                                                setIsLoading(true);
                                                const {code, message, ...rest} = await API().illegalSummary(
                                                    store.user.token, 'p', null, groupSummary.parkinglotSeqid);
                                                setGroupList({
                                                    ...groupList,
                                                    [groupSummary.parkinglotSeqid]: {...groupSummary, ...rest}
                                                });
                                            } catch (e) {
                                                console.error(e)
                                            } finally {
                                                setIsLoading(false);
                                            }
                                        }
                                    }}
                                    selected={Number(match.params.selected) === 0}
                                    end={ <span className={styles.suspicious} style={{fontSize: '0.75rem'}}>
                                             <span style={{color: '#007BFF',fontWeight:'600'}}>{groupSummary.illegalCount}대</span> 추정
                                          </span>
                                        }>
                                    <div className={styles.category}>
                                        <div className={styles.bold} style={{fontSize:'0.75rem'}}>   <ColorIcons name={groupSummary.groupSeqid ? 'group' : 'solo'} size={27}/>
                                            {groupSummary.groupSeqid ? groupSummary.groupName : groupSummary.parkinglotName}</div>
                                    </div>
                                </Accordian>
                            </div>
                            // onclick
                        })
                    }
            </section>
        </div>
        {/*<div className={styles.ComponentMainContainer}>*/}
        {/*    <SearchHeader setShowSearchModal={setShowSearchModal}*/}
        {/*                  setShowReportModal={setShowReportModal}*/}
        {/*                  store={store}*/}
        {/*                  selected={selected}*/}
        {/*    />*/}
        {/*    <div className={styles.overviewContainer}>*/}
        {/*        <div className={styles.wapen}>*/}
        {/*            /!*<img width={49} height={49}/>*!/*/}
        {/*            <ColorIcons name={'logo-default'} size={50}/>*/}
        {/*            <div>{store.user.name}</div>*/}
        {/*        </div>*/}
        {/*        <div className={styles.description}>*/}
        {/*            <div className={styles.numbers}>*/}
        {/*                {illegalSummary.illegalRate} %*/}
        {/*            </div>*/}
        {/*            <div className={styles.label}>*/}
        {/*                부정주차 비율*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className={styles.allParkingLot}>*/}
        {/*        <div className={styles.parkingLot} >*/}
        {/*            <ColorIcons name={selected.groupName ? 'group' : selected.parkinglotName ? 'solo' : 'all'} size={27} style={{marginRight: '8px'}}/>*/}
        {/*            {selected.parkinglotName || selected.groupName || '전체주차장'}*/}
        {/*        </div>*/}
        {/*        {selected.parkinglotSeqid?null:*/}
        {/*        <div className={styles.zeroButton}>*/}
        {/*            <CheckButton selected={isZeroButton}*/}
        {/*                         onChange={handIsZeroButton}*/}
        {/*                         onClick={()=>setIsZeroButton(!isZeroButton)}*/}
        {/*                         style={{width: '18px', height: '18px'}}>*/}
        {/*                모두보기*/}
        {/*            </CheckButton>*/}
        {/*        </div>}*/}
        {/*    </div>*/}
        {/*    <div className={styles.dashboard}>*/}
              {/*  <div className={styles.suspicious}>
                    <TrailButton
                        className={styles.title}
                        onClick={() => {
                            toggleModal();

                        }}
                        style={{paddingLeft: '21px'}}
                        iconStyle={{marginLeft: '3px', height: '10px'}}
                        color={colors['place-holder']}>
                        부정주차 의심대수
                    </TrailButton>
                    <div className={styles.accent}>최대 {illegalSummary.illegalCount}대</div>
                </div>*/}

                {/* 전체주차장 정보 */}
            {/*    <div className={styles.info}>*/}
            {/*        <div>*/}
            {/*            <div>주차면수</div>*/}
            {/*            <span>{illegalSummary.contractLot || 0} 면</span>*/}
            {/*        </div>*/}

            {/*        <div>*/}
            {/*            <div>운영면수</div>*/}
            {/*            {illegalSummary.serviceLot || 0} 면*/}
            {/*        </div>*/}

            {/*        <div>*/}
            {/*            <div>점유면수</div>*/}
            {/*            {illegalSummary.occupiedLot || 0} 면*/}
            {/*        </div>*/}
            {/*        <div>*/}
            {/*            <div>예약면수</div>*/}
            {/*            {illegalSummary.reservedLot || 0} 면*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*<div style={{marginTop: '40px'}}>*/}
            {/*    {*/}
            {/*        gElements && gElements.map((groupSummary, index) => {*/}
            {/*            return <div style={{marginBottom: '10px'}}>*/}
            {/*                <Accordian*/}
            {/*                    expandItem={<Expand*/}
            {/*                        store={store}*/}
            {/*                        toggleModal={toggleModal}*/}
            {/*                        group={groupList[groupSummary.groupSeqid] || groupList[groupSummary.parkinglotSeqid]}*/}
            {/*                        pElements={(groupList[groupSummary.groupSeqid] || groupList[groupSummary.parkinglotSeqid] || {}).pElements || []}/>}*/}
            {/*                    onClick={async () => {*/}
            {/*                        if (groupSummary.groupSeqid) {*/}
            {/*                            const a = await getGroupSummaryInfo(groupSummary.groupSeqid)*/}
            {/*                             if (isZeroButton === false) {*/}
            {/*                                a.pElements= a.pElements.filter(item =>(item.illegalCount!==0))*/}
            {/*                                 setGroupList({*/}
            {/*                                     ...groupList,*/}
            {/*                                     [groupSummary.groupSeqid]: {...groupSummary, ...a}*/}
            {/*                                 });*/}
            {/*                            }else {*/}
            {/*                                 setGroupList({*/}
            {/*                                     ...groupList,*/}
            {/*                                     [groupSummary.groupSeqid]: {...groupSummary, ...a}*/}
            {/*                                 });*/}
            {/*                            }*/}
            {/*                        } else if (groupSummary.parkinglotSeqid){*/}
            {/*                            try {*/}
            {/*                                setIsLoading(true);*/}
            {/*                                const {code, message, ...rest} = await API().illegalSummary(*/}
            {/*                                    store.user.token, 'p', null, groupSummary.parkinglotSeqid);*/}
            {/*                                setGroupList({*/}
            {/*                                    ...groupList,*/}
            {/*                                    [groupSummary.parkinglotSeqid]: {...groupSummary, ...rest}*/}
            {/*                                });*/}
            {/*                            } catch (e) {*/}
            {/*                                console.error(e)*/}
            {/*                            } finally {*/}
            {/*                                setIsLoading(false);*/}
            {/*                            }*/}
            {/*                        }*/}
            {/*                    }}*/}
            {/*                    selected={Number(match.params.selected) === 0}*/}
            {/*                    end={<span*/}
            {/*                        className={styles.suspicious}> <span>추정 {groupSummary.illegalCount}대</span>*/}
            {/*                        </span>}>*/}
            {/*                    <div className={styles.category}>*/}
            {/*                        <ColorIcons name={groupSummary.groupSeqid ? 'group' : 'solo'} size={27}/>*/}
            {/*                        <div*/}
            {/*                            className={styles.name}>{groupSummary.groupSeqid ? groupSummary.groupName : groupSummary.parkinglotName}</div>*/}
            {/*                    </div>*/}
            {/*                </Accordian>*/}
            {/*            </div>*/}
            {/*            // onclick*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
        {/*</div>*/}

        <Progress isLoading={isLoading}/>
        {/* <Modal*/}
        {/*    aria-labelledby="transition-modal-title"*/}
        {/*    aria-describedby="transition-modal-description"*/}
        {/*    className={styles.modalContainer}*/}
        {/*    open={isOpenModal}*/}
        {/*    onClose={toggleModal}*/}
        {/*    closeAfterTransition*/}
        {/*    BackdropComponent={Backdrop}*/}
        {/*    BackdropProps={{*/}
        {/*        timeout: 500,*/}
        {/*    }}*/}
        {/*>*/}
        {/*    <Fade in={isOpenModal}>*/}
        {/*        <div>*/}
        {/*            <div className={styles.ModalContentContainer}>*/}
        {/*                <div className={styles.header}>*/}
        {/*                    주차장별 부정주차대수*/}
        {/*                    <button onClick={toggleModal}>*/}
        {/*                        <Icons name={'icon-close'}/>*/}
        {/*                    </button>*/}
        {/*                </div>*/}
        {/*                <div style={{overflowY: 'auto'}}>*/}
        {/*                    {*/}
        {/*                        pElements ?*/}
        {/*                            pElements.map(pElementArray => {*/}
        {/*                                return pElementArray.map(pElement => {*/}
        {/*                                    if (pElement.illegalCount !== null) {*/}
        {/*                                        return <div className={styles.item}>*/}
        {/*                                            <ColorIcons name={'solo'} size={27} style={{flex: '0 1 auto'}}/>*/}
        {/*                                            <span className={styles.name}>*/}
        {/*                                {pElement.parkinglotName}*/}
        {/*                            </span>*/}
        {/*                                            <div className={styles.accent}>{pElement.illegalCount} 대</div>*/}
        {/*                                        </div>*/}
        {/*                                    }*/}
        {/*                                })*/}
        {/*                            }) : ''*/}
        {/*                    }*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </Fade>*/}
        {/*</Modal>*/}
        <SearchModal isOpenModal={showSearchModal}
                     store={store}
                     toggleModal={() => setShowSearchModal(!showSearchModal)}
                     setSeqid={onSelectSearch}
        />
        <ReportModal store={store} isOpenModal={showReportModal}
                     toggleModal={() => setShowReportModal(!showReportModal)}/>
    </>
};

export default withRouter(Report);
