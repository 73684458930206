import React, {useState} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, TextField} from "components";

import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const ReportModal = ({isOpenModal, toggleModal}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modalContainer}
        open={isOpenModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={isOpenModal}>
            <div>
                <div className={styles.ModalContentContainer}>
                    <div className={styles.header}>
                        단속 요청하시겠습니까?
                        <button onClick={toggleModal}><Icons name={'icon-close'}/></button>
                    </div>

                    <div className={styles.accent}>
                        서울 123가 1234
                    </div>
                    <div className={styles.description}>
                        도로교통법 제32조(정차 및 주차의 금지), 제 33조(주차 금지 장소)근거하여 구청으로 단속 요청을 하겠습니다.
                    </div>

                </div>
            </div>
        </Fade>
    </Modal>
};

export default ReportModal;
