import React, {useEffect, useState} from 'react'
import styles from "./style.module.scss";
import colors from "styles/_colors.scss";
import {numberWithCommas} from "utils";
import {Accordian, ColorIcons, Icons} from "components";
import API from "api";
import {element} from "prop-types";

const ParkDetailExpand = ({date, parkList, token, id, dElements}) => {
    const [dailyInfoList, setDailyInfoList] = useState({});
    const [useCount,setUseCount] = useState({});
    async function getDailyInfo(date) {
        if (!dailyInfoList.date) {
            const {elements, code, message} = await API().useDetail(token, date, 'p', null, id)
            if (code === 200)
                setDailyInfoList({...dailyInfoList, [date]: elements.rElements})
            console.log(dailyInfoList)
            setUseCount(elements.useCount)
            console.log(elements.useCount)
        }
    }

    return <div className={styles.ParkDetailContainer} style={{backgroundColor: colors.bg, marginTop: 0}}>
        <div className={styles[`content-container`]} style={{backgroundColor:'#EFF2F5'}}>
            <h3 style={{fontWeight:'600',fontSize:'15px',color:'#1E4A64'}}> {date} 이용현황</h3>
            <div className={`${styles.grid} ${styles[`mt-10`]}`}>
                <article>
                    <h4>총 이용건수</h4>
                    <h2 className={styles.bold}> {numberWithCommas(String(parkList.useCount))+ '건'}</h2>
                </article>
                <article>
                    <h4>총 매출</h4>
                    <h2 className={styles.bold}> {numberWithCommas(String(parkList.useAmount))+ ' 원'}</h2>
                </article>
            </div>
        </div>
        <div className={styles.status}>
            {dElements && dElements.map((park,index) => {
                return <Accordian
                    key={index}
                    style={{backgroundColor: 'white',marginTop:'10px', borderRadius: 0}}
                    key={park.parkinglotSeqid}
                    end={<h3 className={styles.bold} style={{color: '#007BFF',fontSize: '1rem'}}>{numberWithCommas(park.parkingDateAmount)}</h3>}
                    onClick={() => {
                        getDailyInfo(park.parkingDate);
                    }}
                    expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{

                        dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket) => {
                            return <div className={styles.ticket}>
                                <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>
                                <div>
                                    <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}
                                </div>
                            </div>
                        })
                    }</div>}>
                    {park.parkingDate}
                </Accordian>
            })}
        </div>
        {parkList.dElements && parkList.dElements.map((park, index) => {
            return <Accordian
                style={{backgroundColor: 'white',marginTop:'10px', borderRadius: 0}}
                key={index/*park.parkinglotSeqid*/}
                end={<h3 className={styles.bold} style={{color: '#007BFF',fontSize: '1rem'}}>{numberWithCommas(park.parkingDateAmount)}원</h3>}
                onClick={() => {
                    getDailyInfo(park.parkingDate, parkList);
                }}
                expandItem={<div style={{padding: '10px'}}>
                    <div className={styles.statistic}>
                        <h4 className={styles.bold}>이용건수</h4>
                        <h2 className={styles.bold} style={{color: '#007BFF'}}> {useCount+ '건'}</h2>
                    </div>
                    <hr/>
                    {
                    dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket, index) => {
                        return <>
                        <div className={styles.ticket} key={index}>
                            <div className={styles.statistic}>
                                <h3 className={styles.bold} style={{fontSize: '1rem',color: '#5A8CAA'}}>{ticket.carNum}</h3>
                                <h3 className={styles.bold} style={{fontSize: '1rem'}}>{numberWithCommas(ticket.fee)}원</h3>
                            </div>
                            <div className={styles.statistic}>
                                <h4>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</h4>
                                <h4> {ticket.product} </h4>
                            </div>
                        </div>
                        </>
                    })
                }</div>}>
                {park.parkingDate}
            </Accordian>
        })}
    </div>
};

const DetailExpand = ({date, group, store}) => {
    const [dailyInfoList, setDailyInfoList] = useState({});
    async function getDailyInfo(date, id) {
        if (!dailyInfoList.date) {
            const {elements, code, message} = await API().useDetail(store.user.token, date, 'p', null, id)
            if (code === 200)
                setDailyInfoList({...dailyInfoList, [date]: elements.rElements})
        }
    }

    return <div className={styles.ParkDetailContainer}>
        <div className={styles[`content-container`]}>
            <h3 style={{fontWeight:'600',fontSize:'15px',color:'#1E4A64'}}> {date} 이용현황</h3>
            <div className={`${styles.grid} ${styles[`mt-10`]}`}>
                <article>
                    <h4>총 이용건수</h4>
                    <h2 className={styles.bold}>  {numberWithCommas(String(group.useCount||0)) + '건'}</h2>
                </article>
                <article>
                    <h4>총 매출</h4>
                    <h2 className={styles.bold}>  {numberWithCommas(String(group.useAmount||0) ) + ' 원'}</h2>
                </article>
            </div>
        </div>
        {group.dElements && group.dElements.map(park => {
            return <Accordian
                style={{backgroundColor: colors.bg, borderRadius: 0}}
                key={park.parkinglotSeqid}
                onClick={() => {
                    getDailyInfo(park.parkingDate, group.parkinglotSeqid);
                }}
                end={<div className={styles.outcome}>매출<span
                    className={styles.price}>{numberWithCommas(park.parkingDateAmount)}</span></div>}
                expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{
                    dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket) => {
                        return <div className={styles.ticket}>
                            <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>
                            <div>
                                <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}
                            </div>
                        </div>
                    })
                }</div>}>
                {park.parkingDate}
            </Accordian>
        })}

        {group.pElements && group.pElements.map((parkinglot, index) => {
            return <DetailExpanedItem token={store.user.token} key={index} date={date} parkinglot={parkinglot} dElements={group.dElements || []}></DetailExpanedItem>
        })}
    </div>
};

const DetailExpanedItem = (props) => {
    const {parkinglot, date, dElements, token} = props;
    const [parkinglotDetail, setParkinglotDetail] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const getParkingInfo = async () => {
        try {
            setIsLoading(true);
            const {elements} = await API().useDetail(token, date, 'p', null, parkinglot.parkinglotSeqid);
            setParkinglotDetail(elements);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }
    return <Accordian
        expandItem={!isLoading ? <ParkDetailExpand  date={date} token={token} parkList={parkinglotDetail} id={parkinglot.parkinglotSeqid} dElements={dElements || []}/>
            : null
        }
        end={ <h3 className={styles.bold} style={{color: '#007BFF',fontSize: '1rem'}}> {numberWithCommas(parkinglot.parkinglotAmount)}원</h3>}
        onClick={() => {
            getParkingInfo(parkinglot.parkinglotSeqid);
        }}
        style={{marginTop: '10px', backgroundColor: colors.bg}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <ColorIcons name={'solo'} size={17}/> {parkinglot.parkinglotName}
        </div>
    </Accordian>
};

export default DetailExpand;
