import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import colors from "styles/_colors.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import style from './style.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale("ko", ko);

const styles = {
    root: {
        width: '100%',
        height: '54px',
        marginLeft: '15px',
        'padding-left': '2px', 
        '& > *': {
            width: '100%', borderRadius: '12px',
            height: '54px',
        },
    },
};

function DatePickerField(props) {
    const {children, className, refs, inin, onChange, onClick, value, ...other} = props;

    return (
        <div className={className}>
            <DatePicker
                selected={value} 
                onChange={onChange}
                locale="ko"
                dateFormat="yyyy-MM-dd"
                refs={refs}
                popperModifiers={{
                    preventOverflow: { enabled: true, }, }
                }
                popperPlacement="auto"
                style={styles.root} {...other}
            >
                {children}
            </DatePicker>
        </div>
    );
}

DatePickerField.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default withStyles(styles)(DatePickerField);
