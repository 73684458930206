import React, {useState} from 'react'
import {InputAdornment} from '@material-ui/core'
import Button from 'components/Button'
import TextField from 'components/TextField'

import styles from './style.module.scss'
import Icons from "components/Icons";
import colors from "styles/_colors.scss";

import API from 'api'
import {Toast} from "../index";

const CellAuth = ({onAuth, history}) => {
    const [cellNumber, setCellNumber] = useState('');
    const [authNumber, setAuthNumber] = useState('');
    const [otpid, setOtpid] = useState(null);

    const [timer, setTimer] = useState(180);
    const [isSMSSent, setIsSMSSent] = useState(null);
    const [isTimeOver, setIsTimeOver] = useState(false);

    const [showToast, setShowToast] = useState(false)

    function handleCellNumber(e) {
        setCellNumber(e.target.value)
    }

    async function handleVerification() {

        if(!cellNumber){
            setShowToast('전화번호를 입력해 주세요.')
        }
        else if(cellNumber.indexOf('010') != -1 || cellNumber.indexOf('011') != -1 || 
            cellNumber.indexOf('016') != -1 || cellNumber.indexOf('017') != -1 || cellNumber.indexOf('019') != -1){
                try {

                    const {otpid, code, message} = await API().confirm(
                        's',
                        cellNumber,
                    );
        
                    if(code === 200) {
                        setOtpid(otpid);
                        setTimer(180);
                        setIsTimeOver(false);
        
                        if (isSMSSent) clearInterval(isSMSSent);
                        setIsSMSSent(setInterval(() => {
                            setTimer(prev => {
                                if (prev <= 0) {
                                    // setIsSMSSent(false);
                                    setIsTimeOver(true);
                                    clearInterval();
                                    return 0;
                                }
        
                                return prev - 1;
                            })
                        }, 1000));
                    }
                    else {
                        setShowToast(message)
                    }
                } catch (e) {
        
                } finally {
        
                }
        }
        else{
            setShowToast('010, 011, 016, 017, 019로 시작하는 전화번호를 입력해 주세요.')
        }

    }

    async function handleAuth() {
        try {
            const {code, message} = await API().confirm('c', cellNumber, otpid, authNumber);
            if (code === 200){
                onAuth(cellNumber)
                setShowToast(message)
            }
            else
                setShowToast(message)
        } catch (e) {
        }
    }

    return <>
        <div className={styles.CellAuthContainer}>
            <div className={styles.titleHeading}>
                <div>
                    <button className={styles.backbutton} onClick={() => history.goBack()}>
                        <Icons name={'back'} size={24} color={colors.text}/>
                    </button>
                </div>

                <h1>
                휴대폰번호 인증
                </h1>

                <form noValidate>
                    <TextField
                        id={'contained'}
                        variant={'outlined'}
                        type={'number'}
                        label={'휴대폰 번호'}
                        style={{marginBottom: '15px'}}
                        placeholder={'휴대폰번호 입력(’-’제외)'}
                        name={'cell-number'}
                        value={cellNumber}
                        onChange={handleCellNumber}
                        // inin={{
                        //     startAdornment: <InputAdornment positsdddsdsion="start">
                        //         <span className={styles.adornment}>010-</span>
                        //     </InputAdornment>
                        // }}
                        autoFocus
                    />

                    {isSMSSent ? <TextField
                        id={'authNumber'}
                        variant={'outlined'}
                        type={'number'}
                        label={'인증번호'}
                        style={{marginBottom: '15px'}}
                        placeholder={'숫자 4자리 입력'}
                        name={'auth-number'}
                        value={authNumber}
                        onChange={e => setAuthNumber(e.target.value)}
                        inin={{
                            endAdornment: <InputAdornment position="end">
                                {isTimeOver ? '시간초과' : `${parseInt(timer / 60)}:${('0' + timer % 60).slice(-2)}`}
                            </InputAdornment>
                        }}
                    /> : null}
                </form>

                <ul className={styles.subtle}>
                    {isSMSSent ? null : <li>3분 이내로 인증번호(4자리)를 입력해 주세요.</li>}
                    <li>입력하신 휴대폰 정보가 일치하지 않을 경우 인증문자가 발송되지 않습니다.</li>
                    {isSMSSent ? <li>1분이 내에 인증문자가 수신되지 않을 경우 정확한 정보로 재시도해주시기 바랍니다.</li> : null}
                </ul>

                {isSMSSent ? <div className={styles.buttonHolder}>
                        <Button variant="outlined" color="primary"
                                onClick={handleVerification}
                                className={styles.buttonWrapper}>
                            재시도
                        </Button>
                        <Button variant="contained" color="primary"
                                className={styles.buttonWrapper}
                                disabled={isTimeOver}
                                onClick={handleAuth}>
                            인증 완료
                        </Button>
                    </div>
                    :
                    <Button variant="contained"
                            color="primary"
                            className={styles.button}
                            onClick={handleVerification}>
                        인증
                    </Button>
                }
            </div>
        </div>
        <Toast open={showToast} onClose={() => setShowToast(false)}>
            {showToast}
        </Toast>
    </>
};

export default CellAuth;
