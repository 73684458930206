import FindIdentity from "pages/FindIdentity";
import FindPassword from "pages/FindPassword";

const BASE_URI = '/find';

const FindRoutes = [
    {
        path: BASE_URI,
        component: FindIdentity,
        exact: true
    },
    {
        path: BASE_URI+'/identity',
        component: FindIdentity,
        exact: true
    },
    {
        path: BASE_URI+'/password',
        component: FindPassword,
        exact: true
    },
];

export default FindRoutes;
