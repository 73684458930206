import React from "react";
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {Button, ColorIcons, Icons} from "components";
import colors from "styles/_colors.scss";
import styles from "./style.module.scss";
import ActionInfo from "./ActionInfo";
import API from "api";

const NewActionModal = ({isOpenModal, toggleModal, onAdd, carNum, lotName}) => {


    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modalContainer}
        open={isOpenModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}>
        <Fade in={isOpenModal}>
            <div className={styles.ModalContentContainer}>
                <div className={styles.header}>
                    활동을 등록 하시겠습니까?
                    <button onClick={toggleModal}><Icons name={'icon-close'}/></button>
                </div>
                <div>주차장명 : {lotName} </div>
                <div>차량번호 : {carNum}</div>

                <div style={{margin: '24px 0 30px'}}>
                    <ActionInfo/>
                </div>

                <div className={styles.ButtonContainer}>
                    <Button variant={'outlined'} color={'primary'} onClick={toggleModal}>
                        취소
                    </Button>
                    <Button variant={'contained'} color={'primary'} onClick={onAdd}>
                        등록
                    </Button>
                </div>
            </div>
        </Fade>
    </Modal>
};

export default NewActionModal;
