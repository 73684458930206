import React, {useState, useEffect} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {Ticket, ColorIcons, Icons, Progress} from "components";
import API from 'api'
import { withRouter } from "react-router-dom";

function statusCode(element) {
    if (element.result === 0) {
        return <Ticket time={element.requestDate} lotName={element.parkinglotName}
                       carNumber={element.carNum} ticketColor={'#ffd109'}>
            <div className={styles.circle}>
                <ColorIcons name={'notify-report'} size={24}/>
            </div>
            신고접수
        </Ticket>
    } else {
        return <Ticket time={element.requestDate} lotkName={element.parkinglotName}
                       carNumber={element.carNum} ticketColor={'#d4e0f7'}>
            <div className={styles.circle}>
                <ColorIcons name={'carcheck-signed'} size={20}/>
            </div>
            처리완료{
            element.result === 1 ? <div className={styles.tag}>상황종료</div>
                : element.result === 2 ? <div className={styles.tag}>권고</div>
                : element.result === 3 ? <div className={styles.tag}>범칙금</div>
                    : ''
        }
        </Ticket>
    }
}

const ReportMdoal = ({store, history}) => {
    const [elements, setElements] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        const getAllInfo = async () => {
            try {
                setIsLoading(true)
                const {code, elements} = await API().requestList(store.user.token);
                if (code === 200 && elements) {
                    const tmp = elements;
                    setElements(() => tmp)
                } else if (code === 400) {
                    console.log('단속 내역이 조회되지 않았습니다')
                }
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false);
            }
        };
        getAllInfo();
    }, []);

    return <>
        <div className={styles.ModalContentContainer}>
            <div className={styles.header}>
                <button onClick={() => history.goBack()}><Icons name={'back'}/></button>
                신고 내역
            </div>
            {
                elements ?
                    elements.map((element, index) =>
                        index === 0 ?
                            <div>
                                <div className={styles.date}>
                                    {element.requestDate.split(' ')[0]}
                                </div>
                                {statusCode(element)}
                            </div>
                            : element.requestDate.split(' ')[0] !== elements[index - 1].requestDate.split(' ')[0] ?
                            <div>
                                <div className={styles.date}>
                                    {element.requestDate}
                                </div>
                                {statusCode(element)}
                            </div> : <div>
                                {statusCode(element)}
                            </div>
                    )
                    : ''
            }
        </div>
        <Progress isLoading={isLoading}/>
    </>
};

export default withRouter(ReportMdoal);
