import {withStyles} from "@material-ui/core";
import _Switch from "@material-ui/core/Switch";
import colors from "styles/_colors.scss";
import React from "react";

const Switch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(18px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: colors.parking,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: colors.parking,
            border: '18px solid #fff',
        },
    },
    thumb: {
        width: 18,
        height: 18,
        margin: 3,
    },
    track: {
        borderRadius: 26 / 2,
        border: `none`,
        backgroundColor: theme.palette.grey[100],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({classes, ...props}) => {

    return (
        <_Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default Switch;
