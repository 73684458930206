import Alba from "pages/Alba";

import Reserve from "pages/Alba/Reserve";
import CheckCar from "pages/Officer/CheckCar";
import Setting from "pages/Alba/Setting";
import Footer from "pages/Alba/Footer";
import ActionList from "pages/Alba/Action";


const BASE_URI = '/Alba';

const subRoutes = [
    {
        path: BASE_URI,
        component: Alba,
        exact: true,
    },
    {
        path: BASE_URI + '/reservation',
        component: Reserve,
        exact: true,
    },
    {
        path: BASE_URI + '/carcheck',
        component: CheckCar,
        exact: true,
    },
    {
        path: BASE_URI + '/action',
        component: ActionList,
        exact: true,
    },
    {
        path: BASE_URI + '/setting',
        component: Setting,
        exact: true,
    },
]

const AlbaRoutes =
    [{
        path: BASE_URI,
        subRoutes,
        footer: Footer
    }];

export default AlbaRoutes;
