import React from "react";
import {action, observable} from "mobx";

/* Store start */
export default class Store {
    @observable user = undefined;
    @observable geolocation = undefined;
    @observable request = false;
    @observable push = {i: false, o: false, r: false, b: false};
    @observable filter = 1;
    @observable openMonthly = false;
    @observable marketing = false;

    @action
    setUser(user) {
        this.user = {...this.user, ...user};
    }

    @action
    updateUser(data) {
        this.user = {...this.user, ...data};
    }

    @action
    clearUser() {
        this.user = {};
    }

    @action
    setGeolocation(data) {
        this.geolocation = data;
    }

    @action
    setRequest(isRequested) {
        this.request = isRequested;
    }

    @action
    setPush(i, o, r, b) {
        this.push = {i, o, r, b};
    }

    @action
    setMarketing(ischecked) {
        this.marketing = ischecked;
    }

    @action
    setFilter(filter) {
        this.filter = filter;
    }

    @action
    setOpenMonthly(openMonthly) {
        this.openMonthly = openMonthly;
    }
}
/* Store end */

/* Store helpers */
const StoreContext = React.createContext();

export const StoreProvider = ({children, store}) => {
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

/* Hook to use store in any functional component */
export const useStore = () => React.useContext(StoreContext);

/* HOC to inject store to any functional or class component */
export const withStore = (Component) => (props) => {
    return <Component {...props} store={useStore()}/>;
};
