/*******************************************************************************************
 * 대상: 배정자 및 공단(Officer)
 * 메뉴: 차량조회
 * 페이지: 메인
 *******************************************************************************************/

import React, {useState, useRef} from 'react';
import {withRouter} from 'react-router-dom';
import {TextField} from '@material-ui/core';
import colors from 'styles/_colors.scss';
import API from 'api';
import ReportModal from "./ReportModal";
import ReportCheckModal from "../components/ReportModal";
import styles from './style.module.scss';
import {useMount} from 'hooks';

import {Ticket, Button, CarNumInputField, ColorIcons, Icons, Progress, DatePickerField} from "components";

const CheckCar = ({history, store}) => {
    const [date, setDate] = useState(new Date());
    const [carNum, setCarNum] = useState('');
    const [elements, setElements] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isOpenReportModal, setIsOpenReportModal] = useState(false);

    const [isOpenSearchModal, setIsOpenSearchModal] = useState(false);
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [selectedSeqid, setSelectedSeqid] = useState({});
    const [illegalMessage, setIllegalMessage] = useState('');
    const [groupList, setGroupList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [notification, setNotification] = useState(null);
    const calendar = useRef(null);

    async function handleIllegal(carNum, parkinglotSeqid, phoneNumber, slotNum) {
        try {
            setIsLoading(true);
            const {code, message} = await API().illegalRequest(store.user.token, carNum, selectedSeqid.parkinglotSeqid, '', '');
            setIllegalMessage(message);
            return illegalMessage;
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    useMount(() => {
        const getGroupInfo = async () => {
            try {
                setIsLoading(true);
                const {elements} = await API().groupList(store.user.token);
                setGroupList(elements);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        };
        getGroupInfo();
    });

    async function handleAPI(date, carNum) {
        if (!date) {
            alert('날짜를 입력해주세요');
            return;
        }
        if (!carNum) {
            alert('차량 번호를 입력해주세요');
            return;
        }

        try {
            setIsLoading(true)
            const {code, elements, message} = await API().illegalCheck(store.user.token, String(date), String(carNum));
            if (code === 200 && elements) {
                const tmp = elements;
                setElements(() => tmp)
            } else {
                setElements(null)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    return <>
        <div className={styles.background}></div>
        <div className={styles.body}>
            <div className={styles.header}>
                <h1 className={styles.bold}>차량조회</h1>
            <div className={styles.notification}>
                <button className={styles.alertNotification} onClick={() => {
                    setIsOpenReportModal(true)
                }}>
                    {(notification || []).length > 0 ?
                        <div className={styles.indicator}>{notification.length}</div>
                        : ''}
                    <Icons name={'notification-icon'} size={20}/>
                </button>
            </div>
            </div>
                <form noValidate>
                <section className={styles.search}>
                    <div className={styles.dateWrapper}>
                        <Icons name={'calender-icon'} />
                        <div style={{marginLeft:'15px',width: '100%'}}>
                    <DatePickerField label="검색일"
                                     className={styles.DatePickerContainer}
                                     value={date}
                                     refs={calendar}
                                     onChange={ date => setDate(date)}
                                     onClick={ e => {
                                         console.log("CLICKED !!");
                                     }}
                                     InputLabelProps={{
                                         shrink: true,
                                     }}/>
                    </div>
                    </div>
                    <div className={styles.dateWrapper}>
                        <Icons name={'search-car-icon'} />
                        <div style={{marginLeft:'15px',width: '100%'}}>
                            <CarNumInputField placeholder={'예) 123가1234 또는 1234'} className={styles.CarNumInputContainer} refs={calendar}
                                              onChange={e => setCarNum(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className={styles.buttonWrapper}>
                     <Button variant={'contained'} color={'primary'} onClick={() => {
                handleAPI(date, carNum)
            }}>차량 조회하기</Button>
                    </div>
                </section>
                </form>
            <div className={styles.searchResult}>
                <div className={styles.result}>
                  <h3 className={styles.bold} style={{fontSize:'1rem'}}> 검색결과</h3>
                </div>

                {/* ROW-30: 검색결과 목록*/}
                {
                    elements ?
                        elements.map((element) =>
                            element.product !== '배정자' ?
                                <Ticket time={'시작  : ' + element.startDate}
                                        endTime ={'종료  : ' + element.endDate}
                                        lotName={element.parkinglotName} carNumber={element.carNum} ticketColor={'#d4e0f7'}>
                                        <span className={styles.tag}>
                                            시간권
                                        </span>
                                        {element.fee}
                                </Ticket>
                                : <div className={styles.CardContainer}
                                       style={{flexDirection: 'column', padding: '25px 0'}}>
                                    <div><ColorIcons name={'carcheck-signed'}/></div>
                                    <div style = {{color : '#3CB371'}}>배정자 차량입니다.</div>
                                    <div style={{color:colors.text}}>{element.carNum}({element.parkinglotName})</div>
                                </div>)
                        : 
                            <Ticket time={'예약 또는 이용 내역이 없습니다.'}
                                    endTime ={''}
                                    lotName={''} carNumber={''} ticketColor={'#DC143C'}>
                                    <Button 
                                            color={'white'}
                                            onClick={() => {
                                                setIsOpenModal(true)
                                            }}
                                            style={{
                                                width: '50px',
                                                height: '38px',
                                                borderRadius: '19px',
                                                fontWeight: 'bold',
                                                fontSize: '17.5px',
                                            }}>
                                        단속요청
                                    </Button>
                            </Ticket>
                            // <div className={styles.CardContainer} style={{flexDirection: 'column', padding: '25px 0'}}>
                            //     <div><ColorIcons name={'notify-report'}/></div>
                            //     <div style = {{color : '#DC143C'}}>예약 또는 이용 내역이 없습니다.</div>
                            //     <Button variant={'outlined'}
                            //             color={'secondary'}
                            //             onClick={() => {
                            //                 setIsOpenModal(true)
                            //             }}
                            //             style={{
                            //                 width: '150px',
                            //                 height: '38px',
                            //                 borderRadius: '19px',
                            //                 fontWeight: 500,
                            //                 fontSize: '16px',
                            //             }}>
                            //         단속요청
                            //     </Button>
                            // </div>

                }
                <ReportModal store={store} groups={groupList} setSeqid={setSelectedSeqid} isOpenModal={isOpenModal}
                             handleIllegal={handleIllegal} carNum={carNum} illegalMessage={illegalMessage}
                             toggleModal={() => setIsOpenModal(!isOpenModal)}/>
                <ReportCheckModal store={store} isOpenReportModal={isOpenReportModal}
                                  toggleModal={() => setIsOpenReportModal(!isOpenReportModal)}/>
                <Progress isLoading={isLoading}/>
            </div>

   {/* <div className={styles.CheckCarContainer}>*/}
{/*  ROW-00: 메인 타이틀*/}
     {/*   <div className={styles.CheckCarHeader}>
            <div className={styles.titles}>
                <ColorIcons name={'title-carcheck'} size={24}/><span style={{ marginLeft: '8px'}}>차량조회</span>
            </div>
            {false ? <button className={styles.alertNotification} onClick={() => {
                setIsOpenReportModal(true)
            }}>
                {(notification || []).length > 0 ?
                    <div className={styles.indicator}>{notification.length}</div>
                    : ''}
                <Icons name={'notice-normal'}/>
            </button> : ''}
        </div>*/}


        {/* ROW-10: 차량검색 폼 */}
       {/* <form noValidate>
            <DatePickerField label="검색일"
                className={styles.DatePickerContainer}
                value={date}
                style={{width: '100%'}}
                refs={calendar}
                onChange={ date => setDate(date)}
                onClick={ e => {
                    console.log("CLICKED !!");
                }}
                InputLabelProps={{
                    shrink: true,
                }}/>

            <CarNumInputField placeholder={'예) 123가1234 또는 1234'} className={styles.CarNumInputContainer} refs={calendar}
                onChange={e => setCarNum(e.target.value)}
            />

            <Button variant={'contained'} color={'primary'} onClick={() => {
                handleAPI(date, carNum)
            }}>검색</Button>
        </form>
*/}
        {/* ROW-20: 검색결과 레이블 */}
    {/*    <div className={styles.result}>
            검색결과
        </div>

         ROW-30: 검색결과 목록
        {
            elements ?
                elements.map((element) =>
                    element.product !== '배정자' ?
                        <Ticket time={'시작   ' + element.startDate}
                            endTime ={'종료   ' + element.endDate}
                                lotName={element.parkinglotName} carNumber={element.carNum} ticketColor={'#d4e0f7'}>
                     <span className={styles.tag}>
                         시간권
                    </span>
                            {element.fee}
                        </Ticket>
                        : <div className={styles.CardContainer}
                               style={{flexDirection: 'column', padding: '25px 0'}}>
                            <div><ColorIcons name={'carcheck-signed'}/></div>
                            <div style = {{color : '#3CB371'}}>배정자 차량입니다.</div>
                            <div style={{color:colors.text}}>{element.carNum}({element.parkinglotName})</div>
                        </div>)
                : <div className={styles.CardContainer} style={{flexDirection: 'column', padding: '25px 0'}}>
                    <div><ColorIcons name={'notify-report'}/></div>
                    <div style = {{color : '#DC143C'}}>예약 또는 이용 내역이 없습니다.</div>
                    <Button variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                setIsOpenModal(true)
                            }}
                            style={{
                                width: '150px',
                                height: '38px',
                                borderRadius: '19px',
                                fontWeight: 500,
                                fontSize: '16px',
                            }}>
                        단속요청
                    </Button>
                </div>

        }
        <ReportModal store={store} groups={groupList} setSeqid={setSelectedSeqid} isOpenModal={isOpenModal}
                     handleIllegal={handleIllegal} carNum={carNum} illegalMessage={illegalMessage}
                     toggleModal={() => setIsOpenModal(!isOpenModal)}/>
        <ReportCheckModal store={store} isOpenReportModal={isOpenReportModal}
                          toggleModal={() => setIsOpenReportModal(!isOpenReportModal)}/>
        <Progress isLoading={isLoading}/>*/}
   {/* </div>;*/}
   </div>
    </>
};

export default withRouter(CheckCar);
