import React, {useState} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import {ColorIcons, Icons, TextField, Button} from "components";
import API from 'api'

import classnames from 'classnames/bind'

const cx = classnames.bind(styles)

const ReportModal = ({isOpenModal, toggleModal, groups, setSeqid, illegalMessage, carNum, handleIllegal}) => {
    const [parkinglotName, setParkinglotName] = useState('')
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [modalIndex, setModalIndex] = useState(0)

    function handleSeqid(obj) {
        setSeqid(obj); // = setSelectedSeqid(in checkCar)
    }

    function Illegalrequest(carNum) {
        handleIllegal(carNum);
    }

    function handleBack() {
        if (modalIndex === 0) {
            toggleModal();
        }
        else
            setModalIndex(modalIndex - 1)
    }

    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={styles.modalContainer}
        open={isOpenModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
    >
        <Fade in={isOpenModal}>
            <div>
                <div className={styles.ModalContentContainer}>
                    <button onClick={handleBack}><Icons name={'back'}/></button>
                    {
                        modalIndex === 0 ? // button 눌렀을때 index 1
                            groups ?
                                // 주차장 클릭
                                groups.map((group, index) =>
                                    <div key={group.groupName + index}>
                                        <button
                                            style={{display: 'flex', alignItems: 'center'}}
                                            onClick={() => {
                                            handleSeqid({
                                                groupSeqid: group.groupSeqid,
                                                parkinglotSeqid: group.parkinglotSeqid
                                            });
                                            setParkinglotName(group.parkinglotName)
                                            setModalIndex(1)
                                            setSelectedIndex(index);
                                        }}
                                                className={cx(styles.item, {selected: selectedIndex === index})}>
                                            <ColorIcons name={'solo'} size={27} style={{flex: '0 1 auto'}}/>
                                            <span className={styles.name}>
                                            {group.parkinglotName}</span>
                                            {selectedIndex === index ?
                                                <Icons color={colors.accent} name={'check-sh'}/> : ''}
                                        </button>
                                    </div>)
                                : ''
                            : modalIndex === 1 ?// index===1, back button시 index -1
                            <div>
                                <div className={styles.header}>
                                    단속 요청하시겠습니까?
                                    <button onClick={toggleModal}><Icons name={'icon-close'}/></button>
                                </div>

                                <div className={styles.accent}>
                                    {carNum}, {parkinglotName}
                                </div>
                                <div className={styles.description}>
                                    도로교통법 제32조(정차 및 주차의 금지), 제 33조(주차 금지 장소)근거하여 구청으로 단속 요청을 하겠습니다.
                                </div>
                                <div>
                                    <Button variant={'contained'} color={'primary'} onClick={() => {
                                        setModalIndex(2);
                                        Illegalrequest(carNum);
                                    }}>부정주차 단속 요청</Button>
                                </div>
                            </div>
                            : modalIndex === 2 ?
                                <div>
                                    {
                                        illegalMessage ?
                                            illegalMessage : '진행중입니다'
                                    }
                                    <br/>
                                    <Button variant={'contained'} color={'primary'}
                                            onClick={() => {
                                                toggleModal();
                                                setModalIndex(0);
                                            }}>돌아가기
                                    </Button>
                                </div>
                                : ''
                    }
                </div>
            </div>
        </Fade>
    </Modal>
};

export default ReportModal;
