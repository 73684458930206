import Request from './base/Request';
import * as base64 from 'base-64';

class TermListRequest extends Request {
    constructor(APIURL, body) {
        super(APIURL);

        if (!(['1', '2'].includes(String(body.devide))))
            throw Error('Wrong devide');
        else if (body.devide === '2' && !body.termSeqid)
            throw Error('term id missing');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };

        this.request = this.request.bind(this);
    }
}

export default TermListRequest;
