import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import {BrowserRouter as Router, Switch, withRouter, Route, Redirect} from 'react-router-dom';
import {ThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme, Modal, CircularProgress} from '@material-ui/core';
import { koKR } from '@material-ui/core/locale';
import Store, {StoreProvider, withStore} from "hoc";

import colors from 'styles/_colors.scss'
import NotoSansRegular from 'fonts/NotoSansCJKkr-Regular.otf'
import NotoSansBold from 'fonts/NotoSansCJKkr-Bold.otf'

import Routes from 'routes';

import './App.css';
import {useMount, useUrlChange} from "hooks";

//Text
const Regular = {
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: `
    local('NotoSansCJKkr'),
    local('NotoSansCJKkr-Regular'),
    url(${NotoSansRegular}) format('otf')
  `,
};

const Bold = {
    fontFamily: 'NotoSans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    src: `
    local('NotoSansCJKkr'),
    local('NotoSansCJKkr-Bold'),
    url(${NotoSansBold}) format('otf')
  `,
};

// Init MUI
const pfTheme = createMuiTheme({
    // Colors
    palette: {
        primary: {main: colors.primary},
        secondary: {main: colors.accent},
    },
    typography: {
        fontFamily: 'NotoSans',
        fontSize: 17.5
    },
    // Fonts
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [
                    Regular,
                    Bold,
                ],
            },
        },
    }
}, koKR);


const routers = Routes.map(
    ({path, component, subRoutes, footer, ...rest}, key) => {
        let Footer = footer;
        if (!subRoutes)
            return <Route key={key}
                          path={path}
                          component={withStore(component)}
                          {...rest}/>
        else {
            return <Route key={key}
                          path={path}
                          component={withStore(component)}>
                {
                    subRoutes.map(({path, component, subRoutes, footer, ...rest}, key) =>
                        <Route key={key}
                               path={path}
                               component={withStore(component)}
                               {...rest}/>)
                }
                <Footer/>
            </Route>
        }
    }
);

const UrlWatcher = withRouter(({history}) => {
    const [url, setUrl] = useUrlChange(store);

    if (url !== '') {
        // history.push('/register/info/ASSIGNEE?districtLocation='+url.split('/')[0]+'&districtSeqIdLocation='+url.split('/')[1]);
        history.push('/register?districtLocation='+url.split('/')[0]+'&districtSeqIdLocation='+url.split('/')[1]);
        setUrl('');
    }
    return null;
});

const store = new Store();

function App() {
    // Check if user has logged in and stored with `keepLogin`
    const type = localStorage.getItem('type');
    const token = localStorage.getItem('token');
    const id = localStorage.getItem('id');
    const userName = localStorage.getItem('username')
    const name = localStorage.getItem('name');

    if (type && token && id && userName) store.setUser({type, token, id, userName, name});
    else if (!store.user && location.href.split('/').slice(-1)[0].toLowerCase() !== 'login') {
        location.href = '/login';
    }

    useMount(
        () => {
            if (window.ReactNativeWebView)
                window.ReactNativeWebView.postMessage(JSON.stringify({init: true}));
        }
    )

    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={pfTheme}>
                <Router>
                    <UrlWatcher/>
                    <Switch>
                        {routers}
                        <Route path="/" exact>
                            <Redirect to={type || '/login'}/>
                        </Route>
                    </Switch>
                </Router>
                {/*<button onClick={() => {*/}
                {/*    window.ReactNativeWebView.postMessage(JSON.stringify({push: false}));*/}
                {/*}}>*/}
                {/*    getNav*/}
                {/*</button>*/}
            </ThemeProvider>
        </StoreProvider>
    );
}

export default App;
