import React, {useEffect, useState} from "react";
import classnames from 'classnames/bind'
import {TextField, ColorIcons, Icons, Button, Progress, Toast} from "components";
import {useMount} from "hooks";
import styles from "./style.module.scss";
import ActionChangeModal from "./Modals/ActionChangeModal";
import ActionDeleteModal from "./Modals/ActionDeleteModal";
import NewActionModal from "./Modals/NewActionModal";

import API from "../../../api";
import {Backdrop, Fade, Modal} from "@material-ui/core";
import ActionInfo from "./Modals/ActionInfo";

const cx = classnames.bind(styles)

const today = new Date();
const todayString = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${('0' + today.getDate()).slice(-2)}`

const statusList = ['단속요청', '상황종료', '권고', '범칙금', '가입유도', '결제요청', '기타'];

const ActionList = ({store}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [actionList, setActionList] = useState([]);
    const [carNum, setCarNum] = useState(['', '', '']);

    const [registration, setRegistration] = useState(false);
    const [payment, setPayment] = useState(false);

    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedParkinglot, setSelectedParkinglot] = useState(null);

    const [requestList, setRequestList] = useState(['', '', '']);

    const [groupList, setGroupList] = useState([]);
    const [showParkSelect, setShowParkSelect] = useState(false)
    const [showChangeAction, setShowChangeAction] = useState(false);
    const [showNewAction, setShowNewAction] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);

    async function getActionList() {
        try {
            setIsLoading(true);
            const {elements, code, message} = await API().actionList(store.user.token, '3', todayString)
            setActionList(elements);
            setShowToast(message);
        } catch (e) {

        } finally {
            setIsLoading(false);
        }

    }

    async function getGroupList() {
        try {
            setIsLoading(true)
            const {elements} = await API().groupList(store.user.token);
            setGroupList(elements);
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }

    async function changeStatus(status) {
        try {
            const {code, message} = await API().actionChange(store.user.token, 'u', selectedAction.actionSeqid, status)
            setShowToast(message);
            await getActionList();
            setShowDeleteModal(false );
        } catch (e) {

        } finally {

        }
    }

    useMount(() => {
        getActionList();
        getGroupList();
    });

    function handleAddAction() {
        if (selectedParkinglot && carNum[2]) {
            if(registration || payment){
                setShowNewAction(true);
            } else {
                setShowToast('활동 유형을 선택해 주세요')
            }
        }
        else
            setShowToast('주차장과 차량 번호를 확인해주세요')
    }

    return <>
        <div className={styles.ReserveContainer}>
            <h1 className={styles.HeaderContainer}>
                <ColorIcons name={'title-reportlist'} size={25}/>
                활동.신고내역
            </h1>
            <div className={styles.Tab}>
                <button onClick={() => {
                    setTabIndex(0)
                }} className={cx({active: tabIndex === 0})}>
                    내 활동내역
                </button>
                <button onClick={async () => {
                    setTabIndex(1);
                    try {
                        setIsLoading(true)
                        const {code, elements, message} = await API().actionList(store.user.token, '3', todayString)
                        if (code === 200) {
                            setRequestList(elements)
                        } else {
                            setRequestList([])
                        }
                        setShowToast(message)
                    } catch (e) {
                        console.error(e)
                    } finally {
                        setIsLoading(false)
                    }
                }} className={cx({active: tabIndex === 1})}>
                    전체 신고내역
                </button>
            </div>


            {tabIndex === 0 && <div>
                <button className={styles.Card}
                        onClick={async () => {
                            setShowParkSelect(true);
                        }}>
                    {selectedParkinglot ? selectedParkinglot.parkinglotName : <>주차장을 선택하세요 <Icons size={10}
                                                                                                  name={"link-right"}/></>}
                </button>

                <div className={styles.Card}>
                    {/* <TextField placeholder={'서울'} className={styles.CarField}
                               value={carNum[0]}
                               onChange={e => setCarNum([e.target.value, carNum[1], carNum[2]])}/>
                    /
                    <TextField placeholder={'123가'} className={styles.CarField}
                               value={carNum[1]}
                               onChange={e => setCarNum([carNum[0], e.target.value, carNum[2]])}/>
                    / */}
                    <TextField placeholder={'123가1234'} className={styles.CarField}
                               value={carNum[2]}
                               onChange={e => setCarNum([carNum[0], carNum[1], e.target.value])}/>
                </div>

                <div className={styles.ActionKindWrapper}>
                    활동내역 선택
                    <div className={styles.buttons}>
                        <button className={cx({active: registration})} onClick={() => setRegistration(prev => !prev)}>
                            가입유도
                        </button>
                        <button className={cx({active: payment})} onClick={() => setPayment(prev => !prev)}>
                            결제유도
                        </button>
                    </div>
                </div>

                <Button
                    onClick={handleAddAction}
                    variant={'contained'}
                    color={'primary'}
                    style={{marginBottom: '24px'}}>
                    활동내역 추가
                </Button>

                {actionList && actionList.map(action => (
                    <button style={{width: '100%'}} onClick={() => {
                        setShowChangeAction(true);
                        setSelectedAction(action);
                    }}>
                        <div className={styles.Card} style={{height: 'auto'}}>
                            <div className={styles.Ticket}>
                                <div className={styles.time}>{action.actionTime}
                                    &nbsp;<span
                                        className={action.status === 0 ? styles.else : styles.report}>{statusList[action.status]}</span>
                                </div>
                                <div className={styles.title}>{action.actionDate}</div>
                                <div className={styles.title}>{action.parkinglotName.replace(/\[.*\]/gi, "")}</div>
                                <div className={styles.title}>{"차량번호 : " + action.carNum}</div>
                            </div>
                            <Icons size={12} name={"link-right"}/>
                        </div>
                    </button>
                ))}
            </div>}


            {tabIndex === 1 && <div>
                {requestList.map(request => <div key={request.requestSeqid} className={styles.ReportTicket}>
                    <ActionInfo parkinglotName={request.parkinglotName} actionTime={request.actionDate}
                                carNum={request.carNum}/>
                    <div>{ 
                        request.status == 0 ? "단속요청" : 
                        request.status == 1 ? "단속완료" : 
                        request.status == 2 ? "권고처리" : 
                        request.status == 3 ? "범칙금부과" : 
                        request.status == 4 ? "가입유도" : 
                        request.status == 5 ? "결제유도" :
                        request.status == 7 ? "삭제처리" : "기타"
                    }</div>
                </div>)}
            </div>}
        </div>

        <Progress isLoading={isLoading}/>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.ModalContainer}
            open={showParkSelect}
            onClose={() => setShowParkSelect(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={showParkSelect}>
                <div className={styles.modalContent}>
                    <div className={styles.Header}>
                        주차장 선택 <button onClick={() => setShowParkSelect(false)}><Icons name={'icon-close'}/></button>
                    </div>
                    {groupList && groupList.map(group => <button onClick={() => {setSelectedParkinglot(group); setShowParkSelect(false);} }>
                        <ColorIcons name={'solo'} size={24}/>{group.parkinglotName}
                    </button>)}
                </div>
            </Fade>
        </Modal>
        <ActionChangeModal
            isOpenModal={showChangeAction}
            action={selectedAction}
            toggleModal={() => {
                setShowChangeAction(false)
            }}
            onReport ={() => {
                console.log("일단 도착")
                changeStatus(0)
                setShowChangeAction(false)
            }}
            onRegister ={() => {
                console.log("일단 도착")

                changeStatus(4)
                setShowChangeAction(false)
            }}
            onRequestPay ={() => {
                console.log("일단 도착")

                changeStatus(5)
                setShowChangeAction(false)
            }}
            onDelete={() => {
                setShowChangeAction(false);
                setShowDeleteModal(true);
            }}/>
        <ActionDeleteModal
            isOpenModal={showDeleteModal}
            onDelete={async () => {
                try {
                    const {code, message} = await API().actionChange(store.user.token, 'd', selectedAction.actionSeqid, null)
                    setShowToast(message);
                    await getActionList();
                    setShowDeleteModal(false );
                } catch (e) {

                } finally {

                }
            }}
            toggleModal={() => setShowDeleteModal(false)}/>
        
        <NewActionModal
            isOpenModal={showNewAction}
            toggleModal={() => {
                setShowNewAction(false)
            }}
            carNum = {carNum[2]}
            lotName = {selectedParkinglot? selectedParkinglot.parkinglotName : selectedParkinglot}
            onAdd={async () => {
                if(registration){
                    try {
                        const {code, message} = await API().albaAction(store.user.token, carNum[2], selectedParkinglot.parkinglotSeqid, 'register', '', '');
                        await getActionList();
                    } catch (e) {
                        console.log(e)
                    } finally {
                    }
                }
                if(payment){
                    try {
                        const {code, message} = await API().albaAction(store.user.token, carNum[2], selectedParkinglot.parkinglotSeqid, 'payRequest', '', '');
                        await getActionList();
                    } catch (e) {
                        console.log(e)
                    } finally {
                    }
                }
                setShowNewAction(false)
                setShowToast('활동내역 추가를 완료했습니다.')
            }}
        />

        <Toast actionICon={'confirm'} open={showToast} onClose={() => setShowToast(false)}>
            {showToast}
        </Toast>
    </>;
}

export default ActionList;
