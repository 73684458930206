import Company from "pages/Company";

import Setting from "pages/Company/Setting";

import Footer from "pages/Company/Footer";
import BenefitAdjust from "pages/Officer/BenefitAdjust";
import UseStatus from "pages/Officer/UseStatus";
import UseDetail from "pages/Partner/UseStatus/UseDetail";
import ShareDetail from "pages/Officer/UseStatus/ShareDetail";

const BASE_URI = '/Company';

const subRoutes = [
    {
        path: BASE_URI,
        component: Company,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus',
        component: UseStatus,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus/usedetail',
        component: UseDetail,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus/sharedetail',
        component: ShareDetail,
        exact: true,
    },
    {
        path: BASE_URI + '/benefitadjust',
        component: BenefitAdjust,
        exact: true,
    },
    {
        path: BASE_URI + '/setting',
        component: Setting,
        exact: true,
    },
]

const CompanyRoutes =
    [{
        path: BASE_URI,
        subRoutes,
        footer: Footer
    }];

export default CompanyRoutes;
