import Request from './base/Request';
import * as base64 from 'base-64';

class UseNshareSummaryRequest extends Request {
    constructor(APIURL, header, body) {
        super(APIURL);

        if(!body.searchDate || !body.devide)
            throw Error('Missing param');
        if(!(['a','g', 'p'].includes(body.devide)))
            throw Error('Wrong devide');
        // else if (body.devide === 'g' && !body.groupSeqid)
        //     throw Error('Missing group id');
        // else if (body.devide === 'p' && !body.parkinglotSeqid)
        //     throw Error('Missing group id');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...header
            },
            body: JSON.stringify(body)
        };
        this.request = this.request.bind(this);
    }
}

export default UseNshareSummaryRequest;
