import React from 'react'
import classnames from 'classnames/bind'
import styles from './style.module.scss'


const cx = classnames.bind(styles);

const Ticket = ({ticketColor, children, time, lotName, carNumber, ...rest})=>{
    return <div className={styles.TicketContainer}>
        <div className={styles.info}>
            <div className={styles.time}>
                {time}
            </div>

            <div className={styles.lotName}>
                {lotName}
            </div>
           
            {rest.phone ? <div className={styles.time}>
                {rest.phone}
            </div> : ''}

            {rest.startTime ? <div className={styles.time}>
                {rest.startTime}
            </div> : ''}
            
            {rest.endTime ? <div className={styles.time}>
                {rest.endTime}
            </div> : ''}

            <div className={styles.carNumber}>
                {carNumber}
            </div>
            
            <div className={styles.carNumber}>
                {rest.partnerType ? <div> {'신고자 :' + rest.partnerType}</div> : ''}
            </div>

        </div>
        <div className={styles.ticket} style={{backgroundColor: ticketColor}}>
            {children}
        </div>
    </div>
};

export default Ticket;
