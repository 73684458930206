import React, {useState} from 'react'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import Button from "components/Button";

const GridButtons = ({items, onChange, selectedIndex}) => {
    return <div className={styles.GridContainer}>
        {
            items.map((item, index) => {
                const isSelected = index === selectedIndex || (Array.isArray(selectedIndex) && selectedIndex.includes(index))
                return <Button
                    key={index + item}
                    onClick={() => onChange(index)}
                    variant={isSelected ? 'contained' : 'outlined'}
                    color={index === selectedIndex ? '' : 'secondary'}
                    style={{
                        fontSize: '0.7em',
                        color: isSelected ? colors.white : colors['place-holder'],
                        height: '43px',
                        lineHeight: '1.1',
                        borderRadius: '21.5px',
                        border: `1px solid ${isSelected ? colors.accent : colors['place-holder']}`
                    }}
                    size={'small'}>
                    {item}
                </Button>
            })
        }
    </div>
};

export default GridButtons;
