
import React, {useEffect, useState} from 'react'
import classnames from 'classnames/bind'
import {InputAdornment, IconButton, makeStyles, Backdrop, Fade, Modal} from '@material-ui/core'
import styles from './style.module.scss'
import colors from "styles/_colors.scss";
import Icons from "components/Icons";
import TextField from "components/TextField";
import Button from "components/Button";
import SelectBank from "pages/Register/SelectBank";
import Resign from "components/Resign";
import {Toast} from "components";
import {Switch} from "components";
import API from 'api'
import {useMount} from "hooks";
import { Alert } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

const cx = classnames.bind(styles)

const InfoChangeModal = ({isOpenModal, toggleModal, store, history}) => {

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordCheck, setPasswordCheck] = useState('');
    const [bank, setBank] = useState('');
    const [accountHolder, setAccountHolder] = useState('');
    const [accountNumber, setAccountNumber] = useState('');

    const [showSelectBank, setShowSelectBank] = useState(false);
    const [showResignModal, setShowResignModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [marketing, setMarketing] = useState(store.marketing);
    const [birthday, setBirthday] = useState('')

    const handleChangeMarketing = async (event) => {
        const checked = !store.marketing;

        setMarketing(checked);
        store.setMarketing(checked)
    };

    async function handleDone() {
        if (password !== passwordCheck) {
            setShowToast('비밀번호와 비밀번호 확인 값이 일치하지 않습니다.');
            return;
        }
        try {
            const {code, message} = await API().userinfoChange(store.user.token, password, name, bank, accountNumber, accountHolder, marketing);
            if (code === 200) {
               
            } else {
                setShowToast(message);
            }
            console.log("InfoChangeModal", "handleDone", message);
            setShowToast(message);
            toggleModal();
        } catch (e) {
            // setShowToast(e)
        } finally {
            localStorage.clear();
            store.clearUser();
            history.push('/login');
        }
    }
    /*useEffect(() => {
        document.addEventListener('message', handleMessage);
        window.addEventListener('message', handleMessage);
        return () => {
            document.removeEventListener('message', handleMessage);
            window.removeEventListener('message', handleMessage);
        }
    }, [name]);*/
    useMount(() => {
        async function getParntnerInfo() {
            try {
                setIsLoading(true)
                const {
                    code, message,
                    elements: {elements: [{name, bank_name, account_number, account_holder, marketing}, ...rest]},
                } = await API().partnerInfo(store.user.token);
                if (code === 200) {
                    setName(name);
                    setBank(bank_name);
                    setAccountNumber(account_number);
                    setAccountHolder(account_holder);
                    setMarketing(marketing);
                }

                console.log("InfoChangeModal", "getParntnerInfo", message);
                //DEL - setShowToast(message);
            } catch (e) {
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }

        getParntnerInfo();
    });

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenModal}>
                <div>
                    {/* <div className={styles.ModalContentContainer}>
                        <div className={styles.InfoContainer}>
                            <div className = {styles.header}>
                                <button onClick={toggleModal}><Icons name={'back'}/></button>
                                <div className = {styles.text}>회원정보 수정</div>
                            </div>
                            <div className = {styles.baseInfo}>
                                <div>
                                    <h4>아이디</h4>
                                    <h3>{store.user.username}</h3>
                                </div>
                                <div className = {styles.mt20}>
                                    <h4>이름</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="text"  className = {styles.name}
                                                size={'small'}
                                                value={name} 
                                                placeholder="이름을 입력하세요"
                                                onChange={e => {
                                                    setName(e.target.value)
                                                }
                                        }/>
                                    </div>
                                </div>

                                <div className = {styles.mt20}>
                                    <h4>비밀번호</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="text"  className = {styles.name}
                                                size={'small'}
                                                type={'password'}
                                                value={password} 
                                                placeholder="비밀번호를 입력하세요"
                                                onChange={e => {
                                                    setPassword(e.target.value)
                                                }
                                        }/>
                                    </div>
                                </div>

                                <div className = {styles.mt20}>
                                    <h4>비밀번호 확인</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="text"  className = {styles.name}
                                                size={'small'}
                                                type={'password'}
                                                value={passwordCheck} 
                                                placeholder="비밀번호를 다시 한 번 입력하세요"
                                                onChange={e => {
                                                    setPasswordCheck(e.target.value)
                                                }
                                        }/>
                                    </div>
                                </div>
                            </div>

                            <section className ={styles.title}>
                                <h3>수익금 입금 은행</h3>
                            </section>
                            
  
                            <section className = {styles.baseInfo}>
                                <div>
                                    <p>
                                        관할 지자체에 따라 
                                        <span className ={styles.greenP}> 수익금의 정산 방법이 다를 수 있습니다.</span><br/>
                                        변경을 원하실 경우 고객센터로 연락바랍니다.
                                    </p>
                                </div>
                                <div className = {styles.mt20}>
                                    <h4>수익금 입금 은행</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="text"  className = {styles.name}
                                                placeholder="수입금 입금 은행을 선택하세요"
                                                variant="outlined"
                                                size={'small'}
                                                disabled={true || (store.user || {}).type !== 'ASSIGNEE'}
                                                value={accountNumber} onChange={e => {
                                                    setAccountNumber(e.target.value)
                                                 }
                                        }/>
                                    </div>
                                </div>
                                
                                
                                <div className = {styles.mt20}>
                                    <h4>수익금 입금 계좌번호</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="text"  className = {styles.name}
                                                placeholder="수입금 입금 계좌번호를 입력하세요"
                                                variant="outlined"
                                                size={'small'}
                                                disabled={true || (store.user || {}).type !== 'ASSIGNEE'}
                                                value={accountNumber} onChange={e => {
                                                    setAccountNumber(e.target.value)
                                                 }
                                        }/>
                                    </div>
                                </div>

                                <div className = {styles.mt20}>
                                    <h4>수익금 입금 계좌 예금주</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="text"  className = {styles.name}
                                                placeholder="수입금 입금 계좌 예금주를 입력하세요"
                                                variant="outlined"
                                                size={'small'}
                                                disabled={true || (store.user || {}).type !== 'ASSIGNEE'}
                                                value={accountHolder} onChange={e => {
                                                    setAccountHolder(e.target.value)
                                                }

                                        }/>
                                    </div>
                                </div>
                            </section>
                            
                            <section className = {styles.baseInfo}>
                                <div className = {styles.buttonGroup}>
                                    <Button variant="contained"
                                        color="primary"
                                        style={{ 'background-color': '#72808E',
                                            'width': '48%',
                                            'height': '40px',
                                            'border-radius': '5px'}}
                                        onClick={toggleModal}>
                                        취소하기
                                    </Button>

                                    <Button variant="contained"
                                        color="primary"
                                        style={{ 'background-color': '#09D046',
                                        'width': '48%',
                                        'height': '40px',
                                        'border-radius': '5px'}}                                        onClick={handleDone}>
                                        저장하기
                                    </Button>
                                </div>
                            </section>
                        </div>
                        
                    </div> */}


                    <div className={styles.ModalContentContainer}>
                        <div className={styles.InfoContainer}>
                            <header className={`${styles['flex-left']} ${styles['p-15']} ${styles['text-black']}`}>
                                <button onClick={toggleModal}><Icons name={'back'}/></button>
                                <div className = {`${styles.bold} ${styles['ml-10']}`}>회원정보 수정</div>
                            </header>
                            <div className = {`${styles['member-info']} ${styles['width-fill']} ${styles['p-15']}`}>
                                <div>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>아이디</p>
                                    <p className = {`${styles['m-5']} ${styles.black} ${styles.bold} ${styles.h3}`}>{store.user.username}</p>
                                </div>
                                <div className = {styles['mt-20']}>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>이름</p>
                                    <div className = {styles['input-container']}>
                                        <input type="text"  className = {styles['input-name']}
                                                size={'small'}
                                                value={name} 
                                                placeholder="이름을 입력하세요"
                                                onChange={e => {
                                                    setName(e.target.value)
                                                }
                                        }/>
                                    </div>
                                </div>

                                <div className = {styles['mt-20']}>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>비밀번호</p>
                                    <div className = {styles['input-container']}>
                                        <input  className = {styles['input-password']}
                                                size={'small'}
                                                type={'password'}
                                                value={password} 
                                                placeholder="비밀번호를 입력하세요"
                                                onChange={e => {
                                                    setPassword(e.target.value)
                                                }
                                        }/>
                                    </div>
                                </div>

                                <div className = {styles['mt-20']}>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>비밀번호 확인</p>
                                    <div className = {styles['input-container']}>
                                        <input  className = {styles['input-password-repeat']}
                                                size={'small'}
                                                type={'password'}
                                                value={passwordCheck} 
                                                placeholder="비밀번호를 다시 한 번 입력하세요"
                                                onChange={e => {
                                                    setPasswordCheck(e.target.value)
                                                }
                                        }/>
                                    </div>
                                </div>
                                
                                {/* 생일도 혹시 받고 싶을까봐
                                <div className = {styles.mt20}>
                                    <h4>생년월일</h4>
                                    <div className = {styles.inputContainer}>
                                        <input type="date"  className = {styles.name}
                                                placeholder="생년월일을 입력하세요"
                                                size={'small'}
                                                value={birthday} onChange={e => {
                                                setBirthday(e.target.value)
                                        }}/>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className={styles.item}>
                                마케팅 수신 동의
                                <Switch checked={store.marketing} onClick={handleChangeMarketing}  name="isNotified"/>
                            </div> */}
                            <section className ={`${styles.title} ${styles['pt-20']} ${styles['pl-15']}`}>
                                <h3 className = {styles.bold}>수익금 입금 은행</h3>
                            </section>
                            
  
                            <section className = {`${styles.bank} ${styles['p-15']}`}>
                                
                                <p className = {styles['gray-secondary']}>
                                    관할 지자체에 따라 
                                    <span className ={`${styles.green} ${styles['p']}`}> 수익금의 정산 방법이 다를 수 있습니다.</span><br/>
                                    변경을 원하실 경우 고객센터로 연락바랍니다.
                                </p>
                                
                                <div className = {styles['mt-20']}>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>수익금 입금 은행</p>
                                    <div className = {styles['input-container']}>
                                        <input type="text"  className = {styles.name}
                                                placeholder="수입금 입금 은행을 선택하세요"
                                                variant="outlined"
                                                size={'small'}
                                                disabled={true || (store.user || {}).type !== 'ASSIGNEE'}
                                                value={accountNumber} onChange={e => {
                                                    setAccountNumber(e.target.value)
                                                 }
                                        }/>
                                    </div>
                                </div>
                                
                                {/* <div className = {styles.mt20}>
                                    <h4>수익금 입금 은행</h4>
                                    <div className={styles.card}>
                                        <TextField
                                            variant="outlined" label={"수익금 입금 은행"} size={'small'}
                                            label={bank ? '' : "수익금 입금 은행"}
                                            onClick={() => {
                                                if ((store.user || {}).type === 'ASSIGNEE')
                                                    setShowSelectBank(true)
                                            }}
                                            value={bank}
                                            inin={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        <Icons color={colors.text} name={'link-right'} size={15}/>
                                                    </InputAdornment>
                                            }}
                                            disabled={(store.user || {}).type !== 'ASSIGNEE'}
                                        />
                                    </div>
                                </div> */}
                                <div className = {styles['mt-20']}>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>수익금 입금 계좌번호</p>
                                    <div className = {styles['input-container']}>
                                        <input type="text"  className = {styles.name}
                                                placeholder="수입금 입금 계좌번호를 입력하세요"
                                                variant="outlined"
                                                size={'small'}
                                                disabled={true || (store.user || {}).type !== 'ASSIGNEE'}
                                                value={accountNumber} onChange={e => {
                                                    setAccountNumber(e.target.value)
                                                 }
                                        }/>
                                    </div>
                                </div>

                                <div className = {styles['mt-20']}>
                                    <p className = {`${styles.h4} ${styles['gray-secondary']}`}>수익금 입금 계좌 예금주</p>
                                    <div className = {styles['input-container']}>
                                        <input type="text"  className = {styles.name}
                                                placeholder="수입금 입금 계좌 예금주를 입력하세요"
                                                variant="outlined"
                                                size={'small'}
                                                disabled={true || (store.user || {}).type !== 'ASSIGNEE'}
                                                value={accountHolder} onChange={e => {
                                                    setAccountHolder(e.target.value)
                                                }

                                        }/>
                                    </div>
                                </div>
                            </section>
                            {/*
                            <Alert variant="success" className={styles.noticeWarning}>
                                <ul className="fa-ul">
                                <li className={styles.keyword}>
                                    <i className="fa fa-info-circle fa-lg fa-li" aria-hidden="true"></i>
                                    관할 지자체에 따라 <span className={styles.underline}>수익금의 정산 방법</span>이 다를 수 있습니다. 아래 계좌 정보는 현금을 수령하시는 경우에 기입해 주시기 바랍니다.
                                </li>
                                </ul>
                            </Alert>

                            <div className={styles.card}>
                                <TextField
                                    variant="outlined" label={bank ? '' : "수익금 입금 은행"} size={'small'}
                                    onClick={() => {
                                        if ((store.user || {}).type === 'ASSIGNEE')
                                            setShowSelectBank(true)
                                    }}
                                    value={bank}
                                    inin={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <Icons color={colors.text} name={'link-right'} size={15}/>
                                            </InputAdornment>
                                    }}
                                    disabled={(store.user || {}).type !== 'ASSIGNEE'}
                                />
                            </div>


                            <div className={styles.card}>
                                <TextField variant="outlined"
                                           label="수익금 입금 계좌번호"
                                           size={'small'}
                                           value={accountNumber}
                                           disabled={(store.user || {}).type !== 'ASSIGNEE'}
                                           onChange={e => {
                                               if ((store.user || {}).type === 'ASSIGNEE')
                                                   setAccountNumber(e.target.value)
                                           }}/>
                            </div>
                            <div className={styles.card}>
                                <TextField variant="outlined"
                                           label="수익금 입금 계좌 예금주"
                                           size={'small'}
                                           value={accountHolder}
                                           disabled={(store.user || {}).type !== 'ASSIGNEE'}
                                           onChange={e => {
                                               if ((store.user || {}).type === 'ASSIGNEE')
                                                   setAccountHolder(e.target.value)
                                           }}/>
                            </div> */}
                            <section className = {`${styles['bottom-button']} ${styles['pl-15']}  ${styles['pr-15']}`}>
                                <div className = {`${styles['button-group']} ${styles.flex}`}>
                                    <Button variant="contained"
                                        color="primary"
                                        style={{ 'background-color': '#72808E',
                                            'width': '48%',
                                            'height': '40px',
                                            'border-radius': '5px'}}
                                        onClick={toggleModal}>
                                        취소하기
                                    </Button>

                                    <Button variant="contained"
                                        color="primary"
                                        style={{ 'background-color': '#09D046',
                                        'width': '48%',
                                        'height': '40px',
                                        'border-radius': '5px'}}                                        onClick={handleDone}>
                                        저장하기
                                    </Button>
                                </div>
                            </section>
                            
                           
                            {/* <div> 회원탈퇴 버튼은 임시로 없앴다. 2021-03-10
                            <br/>
                                <button onClick={() => {
                                    setShowResignModal(true)
                                }}>
                                    <div className={styles.underBar}>회원 탈퇴</div>
                                </button>
                            </div> */}
                        </div>
                        
                    </div>
                </div>
            </Fade>
        </Modal>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={showSelectBank}
            onClose={() => setShowSelectBank(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={showSelectBank}>
                <div className={styles.ModalContentContainer}>
                    <SelectBank
                        onSelect={(val) => {
                            setBank(val.bankName);
                            setShowSelectBank(false);
                        }}
                        onClose={() => setShowSelectBank(false)}
                    />
                </div>
            </Fade>
        </Modal>
        <Resign isOpen={showResignModal} toggleModal={() => setShowResignModal(false)} store={store} history={history}/>
        <Toast open={showToast} onClose={() => setShowToast(false)}>
            {showToast}
        </Toast>
    </>
};

export default InfoChangeModal;
