/*** WARNING ***
 *
 * @author Minbeom Victor Joo
 * @date 2018. 04. 27.
 *
 * This is kind of prototype, (or you can call it proof of concept)
 * So you need to consider to enhance, such as below:
 * - Protocol or code between API server
 * - Header
 * - Status code
 */

//Abstract class
class Request {
    constructor(APIURL) {
        //If there is no requestHandling(something like interface)
        // if(this.prototype.responseQueue === undefined)
        //     this.prototype.responseQueue = [];
        if (typeof APIURL === 'string') this.requestURL = APIURL;

        this.request = this.request.bind(this);
    }

    request() {
        //resolve and promise
        return fetch(this.requestURL, this.options)
            .then(response => {
                return this.resolveResponse(response);
            })
            .then(json => {
                return json;
            })
    }

    async resolveResponse(response) {
        let json = await response.json();
        if (json.errorCode !== undefined) {
            if (json.errorCode !== 200) {
                return json;
            } else {
                throw new Error(this.constructor.name + ' : ' + json.message || json.case)
            }
        } else {
            return json;
        }
    }
}

export default Request;
