import React, {useState} from 'react'
import {TextField} from '@material-ui/core'
import styles from './style.module.scss'

import {Button, ColorIcons, Icons} from "components";
import ReportModal from "./ReportModal";

const CheckCar2 = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    return <>
        <div className={styles.CheckCarContainer}>
        <div className={styles.CheckCarHeader}>
            <div className={styles.titles}>
                <ColorIcons name={'title-carcheck'} size={20}/>
                차량조회
            </div>

            <button className={styles.alertNotification}>
                <div className={styles.indicator}>17</div>
                <Icons name={'notice-normal'}/>
            </button>
        </div>
        <form noValidate>
            <div className={styles.CardContainer}>
                <TextField label=""
                           type="date"
                           defaultValue="2017-05-24"
                           style={{width: '100%'}}
                           InputLabelProps={{
                               shrink: true,
                           }}/>
            </div>
            <div className={styles.CardContainer}>

                <TextField placeholder={'서울'} className={styles.CarField}/>
                /
                <TextField placeholder={'123가'} className={styles.CarField}/>
                /
                <TextField placeholder={'1234'} className={styles.CarField}/>
            </div>
            <Button variant={'contained'} color={'primary'}>검색</Button>
        </form>

        <div className={styles.result}>
            검색결과
        </div>
        <div className={styles.CardContainer} style={{flexDirection: 'column', padding: '25px 0'}}>
            <div><ColorIcons name={'carcheck-noresult'}/></div>
            예약 또는 이용 내역이 없습니다.
            <Button variant={'outlined'}
                    color={'secondary'}
                    onClick={()=>{setIsOpenModal(true)}}
                    style={{
                        width: '150px',
                        height: '38px',
                        borderRadius: '19px',
                        fontWeight: 500,
                        fontSize: '16px',
                    }}>
                단속요청
            </Button>
        </div>

        <div className={styles.CardContainer}
             style={{flexDirection: 'column', padding: '25px 0'}}>
            <div><ColorIcons name={'carcheck-signed'}/></div>
            배정자 차량입니다.
        </div>
    </div>
        <ReportModal isOpenModal={isOpenModal} toggleModal={()=>setIsOpenModal(!isOpenModal)}/>
        </>;
};

export default CheckCar2;
