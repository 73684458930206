import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import colors from "styles/_colors.scss";
import style from './style.module.scss';

const styles = {
    root: {
        width: '100%',
        fontSize: '16px',
        height: '30px',
        paddingLeft: '2px',
        color: colors.red, 
        '& > *': {
            width: '100%', borderRadius: '12px',
            height: '54px',
        },
    },
    icon: {
        color: colors.accent,
        width: '16px',
        display: 'flex',
        justifyContent: 'center',
        itemAlign: 'center',
        fontSize: '16pt'
    },
};

function CarNumInputField(props) {
    const {className, onChange, value, ...other} = props;

    return (
        <div className={className}>
            <input
                type="text"
                value={value}
                onChange={onChange}
                style={styles.root} {...other}
            >
            </input>
        </div>
    );
}

CarNumInputField.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default withStyles(styles)(CarNumInputField);
