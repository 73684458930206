import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom'
import styles from "./style.module.scss";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import ColorIcons from "components/ColorIcons";

export default withRouter(({location, history}) => {
    const [path, setPath] = useState('0');

    useEffect(()=>{
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    return <BottomNavigation
        value={path}
        onChange={(event, newValue) => {
            history.push('/officer/' + newValue);
        }}
        className={styles.bottomNav}
        showLabels>

        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'parkcheck'}
            label={<div className={styles.label}>부정주차</div>}
            icon={<ColorIcons name={'illegal-icon'} size={25}/>}
        />
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'carcheck'}
            label={<div className={styles.label}>차량조회</div>}
            icon={<ColorIcons name={'find-icon'} size={25}/>}
        />
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'usestatus'}
            label={<div className={styles.item}>이용현황</div>}
            icon={<ColorIcons name={'statisics-icon'} size={25}/>}
        />
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'benefitadjust'}
            label={<div className={styles.label}>정산내역</div>}
            icon={<ColorIcons name={'calculate_icon'} size={25}/>}
        />
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'setting'}
            label={<div className={styles.label}>더보기</div>}
            icon={<ColorIcons name={'more-icon'} size={25}/>}
        />
    </BottomNavigation>
});
