import React, {useEffect, useState} from "react";
import {ColorIcons, Icons} from "components";
import styles from "./style.module.scss";
import API from 'api';
import {numberWithCommas} from "utils";


const Benefit = ({store}) => {
    const [calDate, setCalDate] = useState(`${new Date().getFullYear()}`);
    const [elements, setElements] = useState();

    const handleDateButton = (e, prev) => {
        if (prev) {
            setCalDate('' + (Number(calDate) - 1));
        }
        else {
            setCalDate('' + (Number(calDate) + 1));
        }
    }

    function getMonth(inputDate) {
        const month = new Date(inputDate);
        return month.getMonth()+1;
    }
    
    async function handleAPI(inputDate) {
        try{
            const {code, elements} = await API().assigneeCal(store.user.token, inputDate);
            if(code === 200){
                setElements(elements.elements)
            }
        }catch(e){
            console.error(e)
        }
    }
    
    useEffect(()=>{
        handleAPI(calDate);
    }, [calDate])

    return <div className={styles.BenefitContainer}>
        <h1 className={styles.HeaderContainer}>
            <ColorIcons name={'title-benefit'} size={25}/>
            나의 혜택
        </h1>

        <div className={styles.MonthSelector}>
            <button onClick={e => handleDateButton(e, true)}>
                <Icons name={'link-left'} size={11}/>
            </button>
            {calDate}년
            <button onClick={e => handleDateButton(e, false)}>
                <Icons name={'link-right'} size={11}/>
            </button>
        </div>

        {
            elements && elements.map(element=>{
                return <div className={styles.BenefitCard}>
                    <div>
                        <span>{calDate}년</span>
                        <div className={styles.bold}>{getMonth(element.calDate)}월</div>
                    </div>
                    <div>
                        <span>공유시간</span>
                        <div className={styles.bold}>{numberWithCommas(element.shareTime)} 시간</div>
                    </div>

                    <div>
                        <span>현금</span>
                        <div className={styles.bold}>{numberWithCommas(element.sharePoint)}</div>
                    </div>
                </div>
            })
        }
    </div>
};

export default Benefit;
