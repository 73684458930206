import React from 'react'

import styles from './style.module.scss'
import classnames from 'classnames/bind'

const cx = classnames.bind(styles);

const ParkCategoryContent = ({category}) => {
}

const ParkCategory = (category) => {
    let categoryContent;
    switch (category) {
        case 'group':
            categoryContent = '그룹';
            break;
        case 'all':
            categoryContent = '전체';
            break;
        default:
            categoryContent = 'P';
    }
    return <div className={
        cx(styles.ParkCategoryContainer, category)}>
        {categoryContent}
    </div>
};

export default ParkCategory;
