import React, {useState} from 'react'

import styles from './style.module.scss'
import Icons from "components/Icons";

import classnames from 'classnames/bind'
import {numberWithCommas} from "../../utils";

const cx = classnames.bind(styles)

const





    Accordian = ({children, expandItem, end, selected, onClick, onOpenEnd, ...rest}) => {
    const [isExpand, setIsExpand] = useState(false);

    return <div>
        <button
            className={cx(styles.AccordionContainer, {[styles.closed]: !isExpand})}
            onClick={(e) => {
                if (onClick && !isExpand) onClick(e);
                setIsExpand(prev => !prev)
            }}
            {...rest}>

            <div className={styles.chidren}>
                {children}
            </div>

            <div className={styles.end}>
                {isExpand ? onOpenEnd || end : end}
                <Icons style={{marginLeft: '11px'}} name={isExpand ? 'dropdown-close' : 'dropdown-open'} size={11}/>
            </div>
        </button>

        {selected || isExpand ?
            <div className={styles.expandContainer}>
                {/* <hr style={{marginBottom:'0rem'}}/> */}
                {expandItem}
                {/* <hr style={{marginTop:'0rem'}}/> */}
            </div>
            : null}
    </div>

}

export default Accordian;
