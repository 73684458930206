import Officer from "pages/Officer";

import Report from 'pages/Officer/ReportCar'
import CheckCar from "pages/Officer/CheckCar"
import CheckCar2 from "pages/Officer/CheckCar2";
import UseStatus from "pages/Officer/UseStatus";
import UseDetail from "pages/Officer/UseStatus/UseDetail";
import ShareDetail from "pages/Officer/UseStatus/ShareDetail";
import BenefitAdjust from "pages/Officer/BenefitAdjust";
import Setting from "pages/Officer/Setting";
import Footer from "pages/Officer/Footer";
import ReportModal from "pages/Officer/CheckCar2/ReportModal";

const BASE_URI = '/officer';

const subRoutes = [
    {
        path: BASE_URI,
        component: Officer,
        exact: true,
    },
    {
        path: BASE_URI + '/parkcheck',
        component: Report,
        exact: true,
    },
    {
        path: BASE_URI + '/parkcheck/:selected',
        component: Report,
        exact: true,
    },

    {
        path: BASE_URI + '/carcheck',
        component: CheckCar,
        exact: true,
    },
    {
        path: BASE_URI + '/carcheck/none',
        component: CheckCar2,
        exact: true,
    },
    {
        path: BASE_URI + '/carcheck/modal1',
        component: ReportModal,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus',
        component: UseStatus,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus/usedetail',
        component: UseDetail,
        exact: true,
    },
    {
        path: BASE_URI + '/usestatus/sharedetail',
        component: ShareDetail,
        exact: true,
    },
    {
        path: BASE_URI + '/benefitadjust',
        component: BenefitAdjust,
        exact: true,
    },
    {
        path: BASE_URI + '/benefitadjust/:selected',
        component: BenefitAdjust,
        exact: true,
    },
    {
        path: BASE_URI + '/setting',
        component: Setting,
        exact: true,
    },
];

const OfficerRoutes = [{
    path: BASE_URI,
    subRoutes,
    footer: Footer
}];

export default OfficerRoutes;
