import Agreement from "pages/Register/Agreement";
import UserKind from "pages/Register/UserType";
import Progress from "pages/Register/Progress";
import SelectBank from "pages/Register/SelectBank";
import RegisterInfo from "pages/Register/RegisterInfo";
import RegisterAdditionalInfo from "pages/Register/RegisterAdditionalInfo";
import RegisterDone from "pages/Register/RegisterDone";
import NoLot from "pages/Register/NoLot";

const BASE_URI = '/register';

const RegisterRoutes = [
    {
        path: BASE_URI,
        component: Agreement,
        exact: true
    },
    {
        path: BASE_URI+'/agreement',
        component: Agreement,
        exact: true
    },
    {
        path: BASE_URI+'/userKind',
        component: UserKind,
        exact: true
    },
    {
        path: BASE_URI+'/info/:userType',
        component: RegisterInfo,
        exact: true
    },
    {
        path: BASE_URI+'/additional/:userType',
        component: RegisterAdditionalInfo,
        exact: true
    },
    {
        path: BASE_URI+'/bank',
        component: SelectBank,
        exact: true
    },
    {
        path: BASE_URI+'/done',
        component: RegisterDone,
        exact: true
    },

    {
        path: BASE_URI+'/noLot',
        component: NoLot,
        exact: true
    }
];

export default RegisterRoutes;
