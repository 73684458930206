import React, {useState, useEffect} from 'react'
import {Modal, Backdrop, Fade} from '@material-ui/core'
import colors from 'styles/_colors.scss'
import styles from './style.module.scss'
import classnames from 'classnames/bind'
import {Ticket, ColorIcons, Icons, Progress} from "components";
import API from 'api'
import {useMount} from "hooks";
import {numberWithCommas} from "utils";

const showSensorFlag = false; //센서 내역 숨김 설정 true 변경시 나타남

const cx = classnames.bind(styles);

const color = {
    color : 'white'
}

const hide ={
    display: 'none'
}

function statusCode(element) {
    if (element.type === "sensor") {
        if (showSensorFlag){
            if(element.status === "OUT"){
                return <Ticket time={element.created_at} lotName={''}
                            carNumber={'주차시간 ' + numberWithCommas(element.value) + '분'} ticketColor={'#46d6be'}>
                    <div className={styles.circle}>
                        <ColorIcons name={'carcheck-signed'} size={24}/>
                    </div> 
                    <div style = {color}> 출차 알림 </div>
                </Ticket>
            } else {
                return <Ticket time={element.created_at} lotName={''}
                            carNumber={element.value} ticketColor={'#53ed79'}>
                    <div className={styles.circle}>
                        <ColorIcons name={'carcheck-signed'} size={24}/>
                    </div> 
                    <div style = {color}> 입차 알림 </div>
                </Ticket> 
            }
        }
    
    //    부정주차 알림  티켓 비활성화 2021/03/12
    // } else if(element.type === "illegal") {
    //     return <Ticket time={element.created_at} lotName={''}
    //                    carNumber={'차량번호 ' + element.value.replaceAll(',', ' ')} ticketColor={'#ee5c5c'}>
    //         <div className={styles.smallCircle}>
    //             <ColorIcons name={'notify-report'} size={20}/>
    //         </div>
    //         <div style = {color}> 부정주차 </div>
    //         {
    //             element.status == 0 ? <div className={styles.tag}>요청됨</div>
    //             : element.status == 1 ? <div className={styles.tag}>상황종료</div>
    //             : element.status == 2 ? <div className={styles.tag}>권고 처리</div>
    //             : element.status == 3 ? <div className={styles.tag}>범칙금</div>
    //             : element.status == 4 ? <div className={styles.tag}>권고</div>
    //             : element.status == 5 ? <div className={styles.tag}>결제요청됨</div>
    //             : element.status == 6 ? <div className={styles.tag}>기타</div>
    //             : element.status == 7 ? <div className={styles.tag}>삭제처리</div>
    //             : ''
    //         }
    //
    //     </Ticket>

    } else if (element.type === "order") {
        if(element.status === "REFUNDED") {
            return <Ticket time={element.created_at} lotName={''}
                        carNumber={'환불 금액 ' + numberWithCommas(element.value) + '원'} ticketColor={'#ed9d53'}>
                <div className={styles.circle}>
                    <ColorIcons name={'title-reportlist'} size={24}/>
                </div> 
                <div style = {color}>환불 알림</div>
            </Ticket>
        } else {
            return <Ticket time={element.created_at} lotName={''} //element.lotName
                carNumber={'결제 금액 '+ numberWithCommas(element.value) + '원'} ticketColor={'#4d7adb'}>
            <div className={styles.circle}>
                <ColorIcons name={'title-reportlist'} size={24}/>
            </div> 
            <div style = {color} > 결제 알림 </div>
            </Ticket>
        }
    }
}

const status = showSensorFlag ? ['전체보기', '입/출차 알림', '예약 알림'] : ['예약 알림']; 

const ReportMdoal = ({store, isOpenReportModal, toggleModal}) => {
    const [elements, setElements] = useState([]);
    const [filter, setFilter] = useState(0);
    const [filteredElements, setFilteredElements] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [showFilterSelect, setShowFilterSelect] = useState(false);

    useMount(() => {
        const getAllInfo = async () => {
            try {
                setIsLoading(true)
                const {code, elements} = await API().pushLog(store.user.token);
                if (code === 200 && elements) {
                    const tmp = elements.elements;
                    //tmp.map((elem) => elem.type === "sensor" ? elem.type ='센서' : elem.type === "order"  ? elem.type = '결제' : elem.type === "illegal" ? elem.type = '부정주차 단속' : '')
                    setElements(tmp)
                    setFilteredElements(tmp)
                } else if (code === 400) {
                    //setShowToast('단속 내역이 조회되지 않았습니다')
                }
            } catch (e) {
                // console.error(e)
            } finally {
                setIsLoading(false);
            }
        };
        getAllInfo();
    });

    useEffect(() => { 
        if(showSensorFlag){
            if (filter === 0) {
                setFilteredElements(elements);
            } else if (filter === 1) {
                setFilteredElements(elements.filter(element => element.type === 'sensor'))
            } else if (filter === 2) {
                setFilteredElements(elements.filter(element => element.type === 'order'))
            } else if (filter === 3) {
                setFilteredElements(elements.filter(element => element.type === 'illegal'))
            }
        } else {
            if (filter === 0) {
                setFilteredElements(elements);
            }
        }
    }, [filter]);

    let date;

    return <>
        <div className={styles.HomeContainer}>
            <h1 className={styles.HeaderContainer}>
                <ColorIcons name={'notice-reserve'} size={25}/>
                <span className={styles.PageTitle}>알림 이력</span>
            </h1>
            
            <div>{showSensorFlag ? 
                <button style={{width: '100%', padding: 0}} onClick={() => setShowFilterSelect(true)}>
                    <div className={styles.CardContainer}>
                        {status[filter]}
                        <Icons name={'link-right'} size={10}/>
                    </div>
                </button>
            : ''}</div>

            <div className={styles.ItemListContainer}> {
                (filteredElements) ?
                    (filteredElements).map((element, index) =>
                        date !== element.created_at.split(' ')[0]?
                            <div key={index} className={styles.Item}>
                                <div className={styles.ItemDate}>
                                    <div style = {hide}> {date = element.created_at.split(' ')[0]} </div>
                                    {showSensorFlag ? element.type == "order" || element.type == "sensor" ? element.created_at.split(' ')[0] : '' : element.type == "order"? element.created_at.split(' ')[0] : ''}
                                </div>
                                {statusCode(element)}
                            </div>
                                : '' /*element.created_at.split(' ')[0] !== elements[index - 1].requestDate.split(' ')[0]*/ ?
                            <div>
                                <div className={styles.ItemDate}>
                                    {element.requestDate}
                                </div>
                                {statusCode(element)}
                            </div> : <div key={index} className={styles.Item}>
                                {statusCode(element)}
                            </div>
                    )
                    : '조회내역 없음'
            }</div>
        </div>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.FilterModalContainer}
            open={showFilterSelect}
            onClose={() => setShowFilterSelect(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
             <Fade in={showFilterSelect}>
                <div className={styles.FilterModalContentContainer}>
                    <div className={styles.header}>
                        알림 선택
                        <button onClick={()=>setShowFilterSelect(false)}>
                            <Icons color={colors.text} size={20} name={'cancel-sh'}/>
                        </button>
                    </div>
                    {status.map((status, index) =>
                        <button key={index}
                                onClick={() => {
                                    setFilter(index);
                                    setShowFilterSelect(false);
                                }}
                                className={cx(styles.filter, {selected: index === filter})}>
                            <div className={styles.label}>
                                {status}
                                {index === filter ?
                                    <Icons style={{marginLeft: '9px'}} name={'check-sh'} size={25}
                                           color={colors.accent}/> : ''}
                            </div>
                        </button>)}
                </div>
            </Fade> 
        </Modal>
        <Progress isLoading={isLoading}/>
    </>
};

export default ReportMdoal;
