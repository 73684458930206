import styles from "../style.module.scss";
import {Accordian, ColorIcons, Icons} from "components";
import React, {useState} from "react";

import {numberWithCommas} from "utils";
import API from "../../../../api";

const UseDetailExpand = ({group, store, today}) => {
    const [parkList, setParkList] = useState({});

    return <div className={styles.ParkDetailContainer}>
        <div className={styles.grid}>
            <article>
                <div className={styles.flex}  onClick={() => history.push({pathname: 'usestatus/usedetail', selected})}>
                    <h4 className={styles.bold}>매출 현황 <Icons name={'right-arrow'} size={12}/></h4>
                </div>
                <div className={styles.mt10}>
                    <h4 className={styles.bold}>오늘</h4>
                    <h3 className={styles.bold}>{numberWithCommas((group.dayAmount || '0'))} 원</h3>
                </div>
                <div className={styles.mt5}>
                    <h4 className={styles.bold}>이번 달</h4>
                    <h3 className={styles.bold}>{numberWithCommas((group.monthAmount || '0'))} 원</h3>
                </div>
            </article>
            <div className={styles.vertical}></div>
            <article>
                <div className={styles.flex} onClick={() => history.push({pathname: 'usestatus/sharedetail', selected})}>
                    <h4 className={styles.bold}>공유시간 현황  <Icons name={'right-arrow'} size={15}/></h4>
                </div>
                <div className={styles.mt10}>
                    <h4 className={styles.bold}>어제</h4>
                    <h3 className={styles.bold}> {(group.dayTime / 60).toFixed(1) || '0'} 시간</h3>
                </div>
                <div className={styles.mt5}>
                    <h4 className={styles.bold}>이번 달</h4>
                    <h3 className={styles.bold}>  {(group.monthTime / 60).toFixed(1) || '0'} 시간</h3>
                </div>
            </article>
        </div>
        {/*<div className={styles.status}>*/}
        {/*    이용현황*/}
        {/*    <div className={styles.value}>*/}
        {/*        {numberWithCommas((group.dayAmount || '0'))} 원*/}
        {/*        <div className={styles.when}>*/}
        {/*            오늘*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className={styles.value}>*/}
        {/*        {numberWithCommas((group.monthAmount || '0'))} 원*/}
        {/*        <div className={styles.when}>*/}
        {/*            이번 달*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className={styles.status}>*/}
        {/*    공유현황*/}
        {/*    <div className={styles.value}>*/}
        {/*        {(group.dayTime / 60).toFixed(1) || '0'} 시간*/}
        {/*        <div className={styles.when}>*/}
        {/*            어제*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className={styles.value}>*/}
        {/*        {(group.monthTime / 60).toFixed(1) || '0'} 시간*/}
        {/*        <div className={styles.when}>*/}
        {/*            이번 달*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {group.pElements && group.pElements.map((parkinglot) => {
            return <div key={parkinglot.parkinglotSeqid}  style={{marginBottom: '10px'}}>
                <Accordian
                    style={{padding: '10px', marginTop:'15px', backgroundColor: '#eff2f5'}}
                    end={ <span className={styles.accent} style={{fontSize: '0.75rem'}}>
                    <span className={styles.bold} style={{color: '#007BFF'}}>{numberWithCommas(parkinglot.parkinglotAmount)}</span>원</span>}
                    onClick={async () => {
                        if(parkinglot.parkinglotSeqid) {
                            try {
                                const {code, elements, message} = await API().useNshareSummary(store.user.token, today, 'p', null, parkinglot.parkinglotSeqid)
                                if (code === 200 && elements) {
                                    setParkList(prev => ({
                                        ...prev,
                                        [parkinglot.parkinglotSeqid]: {...parkinglot, ...elements}
                                    }));
                                }
                            } catch (e) {
                                // alert(e)
                            }
                        }
                    }}
                    expandItem={<div style={{backgroundColor: '#eff2f5'}}>
                    <div style={{padding:'10px'}}>
                        <div className={styles.grid} style={{backgroundColor: '#eff2f5'}}>
                            <article>
                                <div className={styles.flex}  onClick={() => history.push({pathname: 'usestatus/usedetail', selected})}>
                                    <h4 className={styles.bold}>매출 현황 <Icons name={'right-arrow'} size={12}/></h4>
                                </div>
                                <div className={styles.mt10}>
                                    <h4 className={styles.bold}>오늘</h4>
                                    <h3 className={styles.bold}>{numberWithCommas((parkList[parkinglot.parkinglotSeqid] || {}).dayAmount|| '0')}원</h3>
                                </div>
                                <div className={styles.mt5}>
                                    <h4 className={styles.bold}>이번 달</h4>
                                    <h3 className={styles.bold}>{numberWithCommas((parkList[parkinglot.parkinglotSeqid] || {}).monthAmount|| '0')}원</h3>
                                </div>
                            </article>
                            <div className={styles.vertical}></div>
                            <article>
                                <div className={styles.flex} onClick={() => history.push({pathname: 'usestatus/sharedetail', selected})}>
                                    <h4 className={styles.bold}>공유시간 현황  <Icons name={'right-arrow'} size={12}/></h4>
                                </div>
                                <div className={styles.mt10}>
                                    <h4 className={styles.bold}>어제</h4>
                                    <h3 className={styles.bold}> {((parkList[parkinglot.parkinglotSeqid] || {}).dayTime / 60).toFixed(1)} 시간</h3>
                                </div>
                                <div className={styles.mt5}>
                                    <h4 className={styles.bold}>이번 달</h4>
                                    <h3 className={styles.bold}> {((parkList[parkinglot.parkinglotSeqid] || {}).monthTime / 60).toFixed(1)|| '0'} 시간</h3>
                                </div>
                            </article>
                        </div>
                        </div>
                        {/*<div className={styles.status}>*/}
                        {/*    이용현황*/}
                        {/*    <div className={styles.value}>*/}
                        {/*        {numberWithCommas((parkList[parkinglot.parkinglotSeqid] || {}).dayAmount|| '0')}원*/}
                        {/*        <div className={styles.when}>*/}
                        {/*            오늘*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className={styles.value}>*/}
                        {/*        {numberWithCommas((parkList[parkinglot.parkinglotSeqid] || {}).monthAmount|| '0')}원*/}
                        {/*        <div className={styles.when}>*/}
                        {/*            이번 달*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className={styles.status} style={{padding: '0 15px', backgroundColor: '#eff2f5'}}>*/}
                        {/*    공유현황*/}
                        {/*    <div className={styles.value}>*/}
                        {/*        {((parkList[parkinglot.parkinglotSeqid] || {}).dayTime / 60).toFixed(1)} 시간*/}
                        {/*        <div className={styles.when}>*/}
                        {/*            어제*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className={styles.value}>*/}
                        {/*        {((parkList[parkinglot.parkinglotSeqid] || {}).monthTime / 60).toFixed(1)|| '0'} 시간*/}
                        {/*        <div className={styles.when}>*/}
                        {/*            이번 달*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>}>
                    <div className={styles.category}>
                        <div className={styles.bold} style={{fontSize:'0.75rem'}}>
                            <ColorIcons name={'solo'} size={27}/>
                            {parkinglot.parkinglotName}
                        </div>
                    </div>
                </Accordian>
            </div>;
        })}
    </div>;
};

export default UseDetailExpand;
