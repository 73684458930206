import React, {useState} from 'react'
import Button from 'components/Button'
import TextField from "components/TextField";
import Icons from "components/Icons";
import CellAuth from "components/CellAuth";
import colors from "styles/_colors.scss";
import styles from './style.module.scss'
import {Backdrop, Fade, Modal} from "@material-ui/core";
import API from "api";
import {Toast} from "../../components";

const FindPassword = ({history, store}) => {
    const [userid, setUserid] = useState('')

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);

    async function handleDone(phoneNumber) {
        try {
            setIsLoading(true);
            console.log("finding password: ", phoneNumber, userid)
            const {code, message} = await API().passwordChange(phoneNumber, userid);
            setIsOpenModal(false);
            setShowToast(message);
            if(code === 200)
                history.push('/');
        } catch (e) {
            // setShowToast(e);
        } finally {
            setIsLoading(false);
        }
    }

    return <>
        <button onClick={() => history.goBack()} style={{margin: '22px 16px'}}>
            <Icons name={'back'} size={24} color={colors.text}/>
        </button>
        <div className={styles.FindIdentityContainer}>

            <div className={styles.innerWrapper}>
                <h1>비밀번호 찾기</h1>
                <h2>새로운 비밀번호를 휴대폰 번호로<br/>
                    발송해드리겠습니다.</h2>
            </div>
            <div>
                <TextField
                    id="outlined-basic"
                    placeholder="아이디를 입력해주세요"
                    variant="outlined"
                    value={userid}
                    onChange={e => setUserid(e.target.value)}/>
            </div>
            <Button variant="contained"
                    color="primary"
                    size={'large'}
                    onClick={() => {
                        setIsOpenModal(true)
                    }}
                    className={styles.button}>
                본인인증
            </Button>
        </div>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={styles.modalContainer}
            open={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpenModal}>
                <div className={styles.ModalContentContainer}>
                    <CellAuth onAuth={handleDone} store={store} history={history}/>
                </div>
            </Fade>
        </Modal>
        <Toast open={showToast} onClose={()=>setShowToast(false)}>
            {showToast}
        </Toast>
    </>;
};

export default FindPassword;
