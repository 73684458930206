import Request from './base/Request';
import * as base64 from 'base-64';

class RegisterAssigneeRequest extends Request {
    constructor(APIURL, body) {
        super(APIURL);
        console.log(body);
        if (!body.phoneNumber
            || !body.userid ||
            !body.password ||
            !body.name ||
            !body.district || !body.carNumber )
            throw Error('입력하지 않은 값이 있습니다');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };

        this.request = this.request.bind(this);
    }
}

export default RegisterAssigneeRequest;
