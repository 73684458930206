import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom'
import styles from "./style.module.scss";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import ColorIcons from "components/ColorIcons";

export default withRouter(({location, history}) => {
    const [path, setPath] = useState('0');

    useEffect(()=>{
        setPath(location.pathname.split('/')[2]);
    }, [location]);

        return <BottomNavigation
        style={{minWidth:'5%'}}
        value={path}
        onChange={(event, newValue) => {
            history.push('/partner/' + newValue);
        }}
        className={styles.bottomNav}
        showLabels>
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'usestatus/usedetail'}
            label={<div className={styles.label}>이용현황</div>}
            icon={<ColorIcons name={'statisics-icon'} size={25}/>}
        />
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'benefitadjust'}
            label={<div className={styles.label}>수익정산</div>}
            icon={<ColorIcons name={'calculate_icon'} size={25}/>}
        />
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'setting'}
            label={<div className={styles.label}>더보기</div>}
            icon={<ColorIcons name={'more-icon'} size={25}/>}
        />
    </BottomNavigation>
});
