import * as base64 from 'base-64';
import jwt_decode from 'jwt-decode'
import Request from './base/Request';

class LoginRequest extends Request {
    constructor(APIURL, options) {
        super(APIURL);

        this.options = {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                ...options
            },
        };

        this.request = this.request.bind(this);
    }

    request(store) {
        return super.request().then(jsonResponse => {
            //Additional handling over parent
            //Set Redux Action
            if (jsonResponse.code === 200 && store) {
                const decoded = jwt_decode(jsonResponse.token);
                store.setUser({
                    token: jsonResponse.token,
                    ...decoded,
                });
                decoded.token = jsonResponse.token;
                return {...decoded, ...jsonResponse};
            }
            else return jsonResponse;
        });
    }
}

export default LoginRequest;
