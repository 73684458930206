import React, {useEffect, useState} from "react";
import {withRouter} from 'react-router-dom'
import styles from "./style.module.scss";
import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import {ColorIcons, Icons} from "components";

export default withRouter(({location, history}) => {
    const [path, setPath] = useState('0');

    useEffect(() => {
        setPath(location.pathname.split('/')[2]);
    }, [location]);

    return <footer>
        <BottomNavigation
        value={path}
        onChange={(event, newValue) => {
            history.push('/assignee/' + newValue);
        }}
        className={styles.footer}
        showLabels>
        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'home'}
            label={<div >홈</div>}
            icon={<Icons name={'nav-carcheck-normal'} size={19}/>}
        />

        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'use'}
            label={<div className={styles.label}>포인트이력</div>}
            icon={<Icons name={'nav-uselist-normal'} size={19}/>}
        />

        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'share'}
            label={<div className={styles.label}>실적현황</div>}
            icon={<Icons name={'nav-uselist-normal'} size={19}/>}
        />

        <BottomNavigationAction
            style={{minWidth:'5%'}}
            value={'pushLog'}
            label={<div className={styles.label}>알림이력</div>}
            icon={<Icons name={'nav-uselist-normal'} size={19}/>}
        />

        {/* <BottomNavigationAction
            value={'benefit'}
            label={<div className={styles.label}>나의혜택</div>}
            icon={<Icons name={'nav-benefit-normal'} size={19}/>}
        /> */}

        <BottomNavigationAction
            value={'setting'}
            label={<div className={styles.label}>더보기</div>}
            icon={<ColorIcons name={'title-setting'} size={19}/>}
        />
    </BottomNavigation>
    </footer>
});
