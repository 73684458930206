import Request from './base/Request';

class AgreeUrlRequest extends Request {
    constructor(APIURL, districtSeqid) {
        super(APIURL);

        if (!districtSeqid)
            throw Error('No district Id');

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({districtSeqid})
        };

        this.request = this.request.bind(this);
    }
}

export default AgreeUrlRequest;
