import React, {useEffect, useState} from 'react'
import styles from "./style.module.scss";
import colors from "styles/_colors.scss";
import {numberWithCommas} from "utils";
import {Accordian, ColorIcons, Icons} from "components";
import API from "api";
import {element} from "prop-types";

const ParkDetailExpand = ({date, parkList, token, id, dElements}) => {
    const [dailyInfoList, setDailyInfoList] = useState({});

    async function getDailyInfo(date) {
        if (!dailyInfoList.date) {
            const {elements, code, message} = await API().useDetail(token, date, 'p', null, id)
            if (code === 200)
                setDailyInfoList({...dailyInfoList, [date]: elements.rElements})
        }
    }

    return <div className={styles.ParkDetailContainer} style={{backgroundColor: colors.bg, marginTop: 0}}>
        <div
            style={{paddingLeft: 0, marginLeft: "8px", fontSize: '15px', color: colors['place-holder'], height: 'auto'}}
            color={colors['place-holder']}>
            {date} 이용현황
        </div>
        <div className={styles.status}>
            <div className={styles.value}>
                {numberWithCommas(String(parkList.useCount||'0')) + ' 건'}
                <div className={styles.when}>
                    이용건수
                </div>
            </div>
            <div className={styles.value}>
                {numberWithCommas(String(parkList.useAmount||'0')) + ' 원'}
                <div className={styles.when}>
                    매출
                </div>
            </div>

            {dElements && dElements.map((park,index) => {
                return <Accordian
                    key={index}
                    style={{backgroundColor: colors.bg, borderRadius: 0}}
                    key={park.parkinglotSeqid}
                    end={<div className={styles.outcome}>매출<span className={styles.price}>{numberWithCommas(park.parkingDateAmount)}</span></div>}
                    onClick={() => {
                        getDailyInfo(park.parkingDate);
                    }}
                    expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{

                        dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket) => {
                            return <div className={styles.ticket}>
                                <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>
                                <div>
                                    <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}
                                </div>
                            </div>
                        })
                    }</div>}>
                    {park.parkingDate}
                </Accordian>
            })}
        </div>
        {parkList.dElements && parkList.dElements.map((park, index) => {
            return <Accordian
                style={{backgroundColor: colors.bg, borderRadius: 0}}
                key={index/*park.parkinglotSeqid*/}
                end={<div className={styles.outcome}>+<span className={styles.price}>{numberWithCommas(park.parkingDateAmount)}</span></div>}
                onClick={() => {
                    getDailyInfo(park.parkingDate, parkList);
                }}
                expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{
                    dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket, index) => {
                        return <div className={styles.ticket} key={index}>
                            <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>
                            <div>
                                <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}
                            </div>
                        </div>
                    })
                }</div>}>
                {park.parkingDate}
            </Accordian>
        })}
    </div>
};

const DetailExpand = ({date, group, store}) => {
    const [dailyInfoList, setDailyInfoList] = useState({});

    async function getDailyInfo(date, id) {
        if (!dailyInfoList.date) {
            const {elements, code, message} = await API().useDetail(store.user.token, date, 'p', null, id)
            if (code === 200)
                setDailyInfoList({...dailyInfoList, [date]: elements.rElements})
        }
    }

    return <div className={styles.ParkDetailContainer}>
        <div
            style={{paddingLeft: 0, marginLeft: "8px", fontSize: '15px', color: colors['place-holder'], height: 'auto'}}
            color={colors['place-holder']}>
            {date} 이용현황
        </div>
        <div className={styles.status}>
            <div className={styles.value}>
                {numberWithCommas(String(group.useCount||'0')) + ' 건'}
                <div className={styles.when}>
                    이용건수
                </div>
            </div>
            <div className={styles.value}>
                {numberWithCommas(String(group.useAmount||'0')) + ' 원'}
                <div className={styles.when}>
                    매출
                </div>
            </div>
        </div>
        {group.dElements && group.dElements.map(park => {
            return <Accordian
                style={{backgroundColor: colors.bg, borderRadius: 0}}
                key={park.parkinglotSeqid}
                onClick={() => {
                    getDailyInfo(park.parkingDate, group.parkinglotSeqid);
                }}
                end={<div className={styles.outcome}>매출<span
                    className={styles.price}>{numberWithCommas(park.parkingDateAmount)}</span></div>}
                expandItem={<div style={{backgroundColor: colors.bg, borderRadius: 0}}>{
                    dailyInfoList[park.parkingDate] && dailyInfoList[park.parkingDate].map((ticket) => {
                        return <div className={styles.ticket}>
                            <div><span>{ticket.carNum}</span> <span>{numberWithCommas(ticket.fee)}</span></div>
                            <div>
                                <span>{ticket.startTime.split(' ')[1].substring(0, 5)}~{ticket.endTime.split(' ')[1].substring(0, 5)}</span> {ticket.product}
                            </div>
                        </div>
                    })
                }</div>}>
                {park.parkingDate}
            </Accordian>
        })}

        {group.pElements && group.pElements.map((parkinglot, index) => {
            return <DetailExpanedItem token={store.user.token} key={index} date={date} parkinglot={parkinglot} dElements={group.dElements || []}></DetailExpanedItem>
        })}
    </div>
};

const DetailExpanedItem = (props) => {
    const {parkinglot, date, dElements, token} = props;
    const [parkinglotDetail, setParkinglotDetail] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getParkingInfo = async () => {
            try {
                setIsLoading(true);
                const {elements} = await API().useDetail(token, date, 'p', null, parkinglot.parkinglotSeqid);
                setParkinglotDetail(elements);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
       
        if (!parkinglotDetail) {
            getParkingInfo();
        }
    }, []);

    return <Accordian
        expandItem={!isLoading ? <ParkDetailExpand date={date} token={token} parkList={parkinglotDetail} id={parkinglot.parkinglotSeqid} dElements={dElements || []}/>
            : null
        }
        end={numberWithCommas(parkinglot.parkinglotAmount) + '원'}
        style={{marginTop: '2px', backgroundColor: colors.bg}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
            <ColorIcons name={'solo'} size={27}/> {parkinglot.parkinglotName}
        </div>
    </Accordian>
};

export default DetailExpand;
