import Request from './base/Request';
import * as base64 from 'base-64';

class ManagerListRequest extends Request {
    constructor(APIURL) {
        super(APIURL);

        this.options = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
        this.request = this.request.bind(this);
    }
}

export default ManagerListRequest;
