import React, {useEffect, useState} from 'react'
import Carousel from "react-elastic-carousel";

import styles from './style.module.scss'
import {ColorIcons, GridButtons, Icons, Switch, TrailButton} from "components";
import {useMount} from "hooks";
import API from 'api'
import ShareModal from "../ShareModal";
import {numberWithCommas} from "utils";
import classnames from "classnames/bind";

const cx = classnames.bind(styles)
const renderDevide = devide => {
    if (devide === 'a') return '전일';
    else if (devide === 'd') return '주간';
    else if (devide === 'n') return '야간'
    else '';
}

const timeList = [
    'AM 0:00~0:59',
    '1:00~1:59',
    '2:00~2:59',
    '3:00~3:59',
    '4:00~4:59',
    '5:00~5:59',
    '6:00~6:59',
    '7:00~7:59',
    '8:00~8:59',
    '9:00~9:59',
    '10:00~10:59',
    '11:00~11:59',
    'PM 12:00~12:59',
    '13:00~13:59',
    '14:00~14:59',
    '15:00~15:59',
    '16:00~16:59',
    '17:00~17:59',
    '18:00~18:59',
    '19:00~19:59',
    '20:00~20:59',
    '21:00~21:59',
    '22:00~22:59',
    '23:00~23:59',
];

function mapDay(day) {
    switch (day) {
        case 0:
            return 'monday';

        case 1:
            return 'tuesday';

        case 2:
            return 'wednesday';

        case 3:
            return 'thursday';

        case 4:
            return 'friday';

        case 5:
            return 'saturday';

        case 6:
            return 'sunday';
        default:
            return 'holiday'
    }
}

function mapDaytoIndex(day) {

    switch (day) {
        case 'mon':
            return 0;
        case 'tue':
            return 1;
        case 'wed':
            return 2;
        case 'thu':
            return 3;
        case 'fri':
            return 4;
        case 'sat':
            return 5;
        case 'sun':
            return 6;
        default:
            return 7;
    }
}

const Home = ({history, store}) => {
    const [summary, setSummary] = useState();
    const [banner, setBanner] = useState([]);
    const [showShareModal, setShowShareModal] = useState(false);
    
    const [elements, setElements] = useState({ totalPoint: 0, });
    const [remElements, setRemElements] = useState({ totalPoint: 0, });

    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedTimes, setSelectedTimes] = useState([]);

    function handleSelectDay(index) {
        setSelectedDay(index)
    }


    const getShareTime = async () => {

        try {
            const {elements: {elements}, code, message} = await API().shareTimeList(store.user.token);
            let result = [];
            console.log(elements)
            for (let k = 0; elements.length > k; k++) {
                const tempArray = (new Array( Number((elements[k].endTime === null ? "00:00:00" :  elements[k].endTime).slice(3, 5)) > 49 ? (Number((elements[k].endTime === null ? "00:00:00" : elements[k].endTime).slice(0, 2)) + 1) - Number((elements[k].startTime === null ? "00:00:00" : elements[k].startTime).slice(0, 2)) : (Number((elements[k].endTime === null ? "00:00:00" : elements[k].endTime).slice(0, 2)))  - Number((elements[k].startTime === null ? "00:00:00" : elements[k].startTime).slice(0, 2))))
                    .fill(-1).map((empty, index) => {
                        return (index + Number((elements[k].startTime === null ? "00:00:00" : elements[k].startTime).slice(0, 2))) % 24
                    });
                result.push(tempArray)
            }
            setSelectedTimes(result);
        } catch (e) {
            console.error(e)
        }
    };

    const handleAPI = async () => {
        try{
            const {code, elements} = await API().assigneecal(store.user.token);
            if(code === 200){
                setElements(elements)
            }
        }catch(e){
            console.error(e)
        }
    }

    const handleOperAPI = async () => {
        try{
            const {code, elements} = await API().lotOperTime(store.user.token);
            if(code === 200){
                setElements(elements)
                //console.log(elements)
            }
        }catch(e){
            console.error(e)
        }
    }

    const handleAPIRem = async () => {

        try{
            const {code, elements} = await API().assigneeRemittance(store.user.token);
            if(code === 200){
                setRemElements(elements)
            }
            //console.log(elements)
        }catch(e){
            console.error(e)
        }
    }

    useMount(() => {
        const getAssignee = async () => {
            try {
                const {code, message, elements} = await API().assignee(store.user.token)
                if (code === 200) {
                    setSummary(elements);
                } else {
                    console.error(message)
                }
            } catch (e) {
                console.error(e)
            }
        };

        const getBannerInfo = async () => {
            try {
                const {elements: {elements}, code, message} = await API().banner(store.user.token)
                setBanner(elements);
            } catch (e) {
                console.error()

            }
        }
        getShareTime()
        getAssignee();
        getBannerInfo()
        handleAPIRem()
        handleOperAPI()
        handleAPI()
    });

    const sharingTime = selectedTimes.reduce((prev, cur)=>(prev+cur.length), 0);

    return <>
        <div className={styles.background}></div>
    {summary ? summary.parkinglotName ?
        <div className={styles.body}>
            <h1 className={styles.HeaderContainer}>
                <span className={styles.PageTitle}>파킹프렌즈 파트너</span>
            </h1>

            <div className={styles.Wapen}>
                <div className={styles.TimeInfo}>
                    <ColorIcons name={'logo-default'} size={70}/>
                <article>
                    <h2 className={styles.bold}>{numberWithCommas((summary || {}).shareTime)}시간</h2>
                    <p>이번달 공유시간</p>
                </article>
                    <article>
                        <h2 className={styles.bold}>{numberWithCommas((summary || {}).totalShare)}시간</h2>
                        <p>총 누적 공유시간</p>
                    </article>
                </div>
                <div className={`${styles.badageName} ${styles.flexleft}`}>
                    <Icons name={'icon-pf'}  className={styles.badage} alt={"뱃지"}/>
                    <h2 className={styles.bold}>{store.user.name}님</h2>
                </div>

                <div className={styles.buttonGroup}>
                    <TrailButton  color={'#72808E'} iconStyle={{marginleft: '30px'}} onClick={() => history.push('/assignee/share')}>
                        공유현황
                        보기</TrailButton>
                    <TrailButton color={'#72808E'} iconStyle={{margin: 0}} onClick={() => setShowShareModal(true)}>공유시간
                        변경</TrailButton>
                </div>
            </div>
            <div className={styles.parkingLot}>
                <h3 className={styles.lotName}>내 주차면</h3>
                <article>
                    <h3 className={styles.lotTitle}>주차구역</h3>
                    <h2 className={styles.extraBold}> {(summary || {}).parkinglotName}</h2>
                </article>
                <article className={styles.grid}>
                    <div className={styles.container}>
                        <h3 className={styles.lotTitle}>구획번호</h3>
                        <h2 className={styles.extraBold}>{(summary || {}).slotNum}</h2>
                    </div>
                    <div className={styles.container}>
                        <h3 className={styles.lotTitle}>구분</h3>
                        <h2 className={styles.extraBold}>거주자우선</h2>
                    </div>
                </article>
            </div>
            <div className={styles.statistics}>
                <div className={styles.timePoint}>
                    <Icons name={'icon-save-time'} size={40}/>
                    <article>
                        <h4 className={styles.textBold}>일주일 공유시간</h4>
                        <span className={styles.textBold}><span className={styles.blue}>{numberWithCommas(sharingTime)}</span>시간</span>
                    </article>
                    <article>
                        <h4 className={styles.textBold}>현재 포인트</h4>
                        <span className={styles.textBold}>
                            <span className={styles.blue}>{numberWithCommas(elements.totalPoint)}</span>P</span>
                    </article>
                </div>
                <hr style={{width:'100%', height:'0.1px'}}/>
                <div className={styles.saveTime}>
                    <Icons name={'icon-share-time'} size={40}/>
                    <article>
                        <h4 className={styles.textBold}>설정된 공유시간</h4>
                        <h4 style={{color:'#72808E',fontSize:'12px'}}>{store.user.name} 파트너님,</h4>
                        <h4 style={{color:'#72808E',fontSize:'12px'}}>{store.user.name} 공유시간을 설정해주세요.</h4>
                    </article>
                </div>
            </div>
            {/*<div>*/}
            {/*    /!*<div className={styles.status}>*!/*/}
            {/*    /!*    <div>*!/*/}
            {/*    /!*        <div className={styles.description}>*!/*/}
            {/*    /!*            일주일 공유시간*!/*/}
            {/*    /!*            <div className={styles.bold}>*!/*/}
            {/*    /!*                <ColorIcons*!/*/}
            {/*    /!*                name={`emoticon-${ sharingTime < 10 ? 'low' : sharingTime ? 'high' : 'normalhigh'}`}*!/*/}
            {/*    /!*                size={52} className={styles.icon}/> *!/*/}
            {/*    /!*                {' '} {numberWithCommas(sharingTime)} 시간 </div>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}

            {/*    /!*    <div>*!/*/}
            {/*    /!*        <div className={styles.description}>*!/*/}
            {/*    /!*                {*!/*/}
            {/*    /!*                    remElements.totalPoint > 0*!/*/}
            {/*    /!*                    ?  <div> *!/*/}
            {/*    /!*                        <div> 누적 수익금 </div>*!/*/}
            {/*    /!*                        <div className={styles.bold} style={{marginTop: '6px'}}> {numberWithCommas(remElements.totalPoint)} 원</div> *!/*/}
            {/*    /!*                    </div>*!/*/}
            {/*    /!*                    : elements.elements ? elements.elements.length > 0 ?<div> *!/*/}
            {/*    /!*                        <div> 현재 포인트 </div>*!/*/}
            {/*    /!*                        <div className={styles.bold} style={{marginTop: '6px'}}>{numberWithCommas(elements.totalPoint)} P </div>  *!/*/}
            {/*    /!*                    </div> : '' : ''*!/*/}
            {/*    /!*                }*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    <div>*/}
            {/*        {elements.elements ? elements.elements.length < 6 ?*/}
            {/*        <div>    */}
            {/*            {*/}
            {/*            remElements.totalPoint > 0 ? '' :*/}
            {/*            <div className={styles.HistoryContainer}>*/}
            {/*            {*/}
            {/*                elements.elements*/}
            {/*                ? <div className={styles.ContainerTitle}>*/}
            {/*                    <span>*/}
            {/*                        포인트 누적 및 사용 이력*/}
            {/*                    </span>*/}
            {/*            </div> : ''*/}
            {/*            }*/}
            {/*            {*/}
            {/*                elements.elements */}
            {/*                ? elements.elements.length > 0 ? elements.elements.map((log, index) => */}
            {/*                    <div key={index} className={styles.Card}>*/}
            {/*                        <div className={styles.LMa}>*/}
            {/*                            <div className={styles.m}></div>*/}

            {/*                            <div className={styles.Item}>*/}
            {/*                                <div className={styles.r0}>*/}
            {/*                                    <span className={styles.c0}>{log.일시}</span>*/}
            {/*                                    <span className={styles.c1}>{log.구분}</span>*/}
            {/*                                    <span className={styles.c2}>{numberWithCommas(log.포인트)} P</span>*/}
            {/*                                </div>*/}
            {/*                                <div className={styles.r1}>*/}
            {/*                                    <span className={styles.c0}>※ {log.사유}</span>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                ) */}
            {/*                : '' :''*/}
            {/*            }*/}
            {/*            </div>*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*        :'' : ''}*/}
            {/*        {remElements.elements ? remElements.elements.length < 6 ?*/}
            {/*        <div>*/}
            {/*            <div className={styles.HistoryContainer}>*/}
            {/*            {*/}
            {/*                remElements.totalPoint > 0*/}
            {/*                ? <div className={styles.ContainerTitle}>*/}
            {/*                    <span>*/}
            {/*                        공유 주차 수익금*/}
            {/*                    </span>*/}
            {/*                </div> : ''*/}
            {/*            }*/}
            {/*            {*/}
            {/*                remElements.totalPoint > 0*/}
            {/*                ? remElements.elements.map((log, index) => */}
            {/*                    <div key={index} className={styles.Card}>*/}
            {/*                        <div className={styles.LMa}>*/}
            {/*                            <div className={styles.m}></div>*/}

            {/*                            <div className={styles.Item}>*/}
            {/*                                <div className={styles.r0}>*/}
            {/*                                    <span className={styles.c0}>{log.check_date}</span>*/}
            {/*                                    /!* <span className={styles.c1}>{log.구분}</span> *!/*/}
            {/*                                    <span className={styles.c2}>{numberWithCommas(log.remittance)} 원</span>*/}
            {/*                                </div>*/}
            {/*                                /!* <div className={styles.r1}>*/}
            {/*                                    <span className={styles.c0}>※ {log.사유}</span>*/}
            {/*                                </div> *!/*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                ) */}
            {/*                : ''*/}
            {/*            }*/}
            {/*        </div>*/}

            {/*        </div>*/}
            {/*        : '' : ''}*/}
            {/*    </div>*/}





            {/*    <div className={styles.ShareModalContainer}>*/}

            {/*        <table className={styles.weekPicker}>*/}
            {/*            <thead>*/}
            {/*            <tr>*/}
            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({selectable: true, selected: selectedDay === (0)})}*/}
            {/*                        onClick={() => handleSelectDay(0)}>월*/}
            {/*                    </button>*/}
            {/*                </td>*/}
            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({selectable: true, selected: selectedDay === (1)})}*/}
            {/*                        onClick={() => handleSelectDay(1)}>화*/}
            {/*                    </button>*/}
            {/*                </td>*/}

            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({selectable: true, selected: selectedDay === (2)})}*/}
            {/*                        onClick={() => handleSelectDay(2)}>수*/}
            {/*                    </button>*/}
            {/*                </td>*/}

            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({selectable: true, selected: selectedDay === (3)})}*/}
            {/*                        onClick={() => handleSelectDay(3)}>목*/}
            {/*                    </button>*/}
            {/*                </td>*/}

            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({selectable: true, selected: selectedDay === (4)})}*/}
            {/*                        onClick={() => handleSelectDay(4)}>금*/}
            {/*                    </button>*/}
            {/*                </td>*/}

            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({*/}
            {/*                            selectable: true,*/}
            {/*                            selected: selectedDay === (5)*/}
            {/*                        })}*/}
            {/*                        onClick={() => {*/}
            {/*                            handleSelectDay(5)*/}
            {/*                        }}*/}
            {/*                        style={{color: '#5384ed'}}>*/}
            {/*                        토*/}
            {/*                    </button>*/}
            {/*                </td>*/}

            {/*                <td>*/}
            {/*                    <button*/}
            {/*                        className={cx({*/}
            {/*                            selectable: true,*/}
            {/*                            selected: selectedDay === (6)*/}
            {/*                        })}*/}
            {/*                        onClick={() => {*/}
            {/*                            handleSelectDay(6)*/}
            {/*                        }}*/}
            {/*                        style={{color: '#fb4462'}}>*/}
            {/*                        일*/}
            {/*                    </button>*/}
            {/*                </td>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*        </table>*/}

            {/*        <GridButtons*/}
            {/*            items={timeList}*/}
            {/*            onChange={() => {*/}
            {/*            }}*/}
            {/*            selectedIndex={selectedTimes[selectedDay]}/>*/}
            {/*    </div>*/}
            {/*</div>*/}

            {/*{*/}
            {/*    elements.elements*/}
            {/*    ? <div className={styles.EndCard}><span>- ● -</span></div> : ''*/}
            {/*}*/}

            {/*{*/}
            {/*    remElements.elements ? remElements.elements.length > 0 ? '' : */}
            {/*    elements.elements ? */}
            {/*    elements.elements.length > 5 ?*/}
            {/*    <div className={styles.HistoryContainer}>*/}
            {/*    {*/}
            {/*        elements.elements*/}
            {/*        ? <div className={styles.ContainerTitle}>*/}
            {/*            <span>*/}
            {/*                포인트 누적 및 사용 이력*/}
            {/*            </span>*/}
            {/*        </div> : ''*/}
            {/*    }*/}
            {/*    {*/}
            {/*        elements.elements */}
            {/*        ? elements.elements.length > 0 ? elements.elements.map((log, index) => */}
            {/*            <div key={index} className={styles.Card}>*/}
            {/*                <div className={styles.LMa}>*/}
            {/*                    <div className={styles.m}></div>*/}

            {/*                    <div className={styles.Item}>*/}
            {/*                        <div className={styles.r0}>*/}
            {/*                            <span className={styles.c0}>{log.일시}</span>*/}
            {/*                            <span className={styles.c1}>{log.구분}</span>*/}
            {/*                            <span className={styles.c2}>{numberWithCommas(log.포인트)} P</span>*/}
            {/*                        </div>*/}
            {/*                        <div className={styles.r1}>*/}
            {/*                            <span className={styles.c0}>※ {log.사유}</span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        ) */}
            {/*        : '' :''*/}
            {/*    }*/}
            {/*    </div>*/}
            {/*    */}
            {/*: '' : '' : ''}*/}

            {/*<div className={styles.HistoryContainer}>*/}
            {/*{*/}
            {/*    remElements.totalPoint > 0 ? remElements.elements.length > 5 */}
            {/*    ? <div className={styles.ContainerTitle}>*/}
            {/*        <span>*/}
            {/*            공유 주차 수익금*/}
            {/*        </span>*/}
            {/*    </div> : ''*/}
            {/*:''}*/}
            {/*{*/}
            {/*    remElements.totalPoint > 0*/}
            {/*    ? remElements.elements.length > 5 ? remElements.elements.map((log, index) => */}
            {/*        <div key={index} className={styles.Card}>*/}
            {/*            <div className={styles.LMa}>*/}
            {/*                <div className={styles.m}></div>*/}

            {/*                <div className={styles.Item}>*/}
            {/*                    <div className={styles.r0}>*/}
            {/*                        <span className={styles.c0}>{log.check_date}</span>*/}
            {/*                        /!* <span className={styles.c1}>{log.구분}</span> *!/*/}
            {/*                        <span className={styles.c2}>{numberWithCommas(log.remittance)} 원</span>*/}
            {/*                    </div>*/}
            {/*                    /!* <div className={styles.r1}>*/}
            {/*                        <span className={styles.c0}>※ {log.사유}</span>*/}
            {/*                    </div> *!/*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    ) */}
            {/*    : ''*/}
            {/*: ''}*/}


            {/*</div>*/}

            {/*광고 배너 미노출 2020/03/11*/}
            {/*<Carousel>*/}
            {/*    {banner.map(({bannerUrl, link}) =>*/}
            {/*        <a key={bannerUrl} href={link || 'https://apx.hmobility.co.kr/join/'} target={'blink'}>*/}
            {/*            <img src={'https://kr.object.ncloudstorage.com/hmobility/' + bannerUrl} width="auto"/>*/}
            {/*        </a>)}*/}
            {/*</Carousel>*/}
            {/* <div className={styles.EndCard}><span>- ● -</span></div> */}

        <ShareModal isOpenModal={showShareModal} toggleModal={() => {
            setShowShareModal(!showShareModal)
            getShareTime();
        }} store={store}/>
    </div> :
    <div className={styles.noLot}> 
        {/* <Router> 
            <Switching>
                <Route path="/PushLog" exact>
                    <Redirect to={'/PushLog'}/>
                </Route>
            </Switching>
        </Router> */}

        {history.push('/Register/NoLot')}
    </div>
: ''}
    </>

};

export default Home;
